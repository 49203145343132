import React, {useState,useEffect} from "react";
import styled from "styled-components";
import SiteData from '../config'

const EndofEventPage = ({ className }) => {

  useEffect(() => {
  });


  return (
    <div className={className}>
      <div className="topleft">
        <div>{SiteData.endOfEventPageTitle}</div>
      </div>
      <div className="middle">
        <img src={SiteData.loginImagesrc} alt={SiteData.loginImagealt} title={SiteData.attendeeTitle}/>
        <h1>Thank you for joining us at the Trinity Hall Open House</h1>
        <br/>
        <div> Hope to see you soon!</div>
      </div>
      <div className="bottomleft">
        <div>Brought to you by</div>
        <img src={SiteData.matchboxIcon} alt={SiteData.matchboxLogoAlt} title={SiteData.matchboxLogoTitle}/>
      </div>

    </div>
  );
};

export default styled(EndofEventPage)`
	background-color: black;
	color: white;
    font-family: "Courier New", Courier, monospace;
    font-size: 3rem;

	.topleft {
    position: absolute;
    top: 2%;
    left: 2%;
    padding: 1rem 1.5rem;
    font-size: 3rem;
  }

  .bottomleft {
    position: absolute;
    bottom: 2%;
    left: 2%;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 3rem;
  }

  .bottomleft img {
    margin-left: 1rem;
  }

  .middle {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    p{
        font-size:30px;
    }
  }
`;
