import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import styled from 'styled-components'
// import "path/to/node_modules/react-datetime/css/react-datetime.css";


const ReactDateTimePicker = ({ className,onChange,value }) => {

  return (
      <DateTimePicker
        className={className}
        onChange={onChange}
        value={value}
        calendarIcon={null}
      />
  );
};
export default styled(ReactDateTimePicker)`
  .react-datetime-picker__inputGroup__input{
    font-size: 16px;
  }
  .react-datetime-picker__clock{
    display: none;
  }


`;
