import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';

import expoHallAPI from '../../api/expoHallAPI';

import ReactPlayer from 'react-player';

import { Context as UserContext } from '../../context/UserContext';

import IconsBar from './iconsBar';

import styled from 'styled-components';

import { setColor } from '../../styles/styles';

import SliderItemModal from './SliderItemModal';

import VideoTransition from '../globals/VideoTransition';

import getTransformOrigin from '../../utils/getTransformOrigin';

import { useWindowScroll, useDebouncedFn } from 'beautiful-react-hooks';
import DirectionsWalkSharpIcon from '@material-ui/icons/DirectionsWalkSharp';
import CompanyInfoModal from './CompanyInfoModal';
import {
  Socket,
  disconnectChat,
  connectChatViews,
} from '../../api/expoHallSocket';
import { Context as SocketContext } from '../../context/SocketContext';
import SiteDate from '../../config';

const setZoomSpeed = (zoomSpeed) => {
  const cssZoomSpeed = zoomSpeed * 0.0034;
  return `
		transition: all ${cssZoomSpeed}s;
	`;
};
const setZoomLevel = (zoomLevel, counter) => {
  const cssZoomLevel = zoomLevel / 100 + 1;
  if (counter === 4) {
    return `
		transform: scale(${cssZoomLevel}) translateX(-70px);
	`;
  } else
    return `
  transform: scale(${cssZoomLevel});
`;
};

const SliderItem = ({ className, data, counter }) => {
  const tileRef = useRef();

  const user = useContext(UserContext);
  const {
    state: { connection },
    setTileChatMessages,
    setTileChatParticipants,
    setTileStatus,
  } = useContext(SocketContext);
  //console.log(data);
  let {
    name,
    zoomSpeed,
    zoomLevel,
    logo,
    image,
    video,
    color,
    booth,
    videocall_url,
    tabs,
    id,
    group_id,
    video_call_toggle,
    chat_toggle,
    meeting_toggle,
    business_card_toggle,
    image_brightness,
    closed,
  } = data;

  const [isHovering, setIsHovering] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const [videoIsMuted, setVideoIsMuted] = useState(true);
  const [transformOrigin, setTransformOrigin] = useState(
    `transform-orign: center center;`
  );
  const [chatIsOpen, setChatIsOpen] = useState(false);
  const [toggleVideo, setToggleVideo] = useState(false);
  const [auditoriumIsOpen, setAuditoriumOpen] = useState(false);
  const [requestMeetingIsOpen, setRequestMeetingIsOpen] = useState(false);

  const [anyModalOpen, setAnyModalOpen] = useState(false);

  useEffect(() => {
    closed = data.closed;
  }, [data]);
  useEffect(() => {
    document.querySelector('body').style.overflowY = anyModalOpen
      ? 'hidden'
      : 'scroll';
  }, [anyModalOpen]);

  // useEffect(() => {
  //   //console.log(chatIsOpen);
  // }, [chatIsOpen]);

  useEffect(() => {
    //console.log('Hovering State: ', isHovering);
  }, [isHovering]);

  useEffect(() => {
    if (modalIsOpen || infoModalIsOpen) {
      setAnyModalOpen(true);
    } else if (!modalIsOpen && !infoModalIsOpen) {
      setAnyModalOpen(false);
    }
  }, [modalIsOpen, infoModalIsOpen]);

  const debouncedTransformOrigin = useDebouncedFn(() => {
    setTransformOrigin(getTransformOrigin(tileRef));
  }, 200);

  useWindowScroll(debouncedTransformOrigin);

  useEffect(() => {
    setTransformOrigin(getTransformOrigin(tileRef));

    return () => {
      debouncedTransformOrigin.cancel();
      iconHandlers.handleDropCard.cancel();
    };
  }, []);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const iconHandlers = {
    handleZoomCall: async () => {
      try {
        if (user) {
          const Zoomdata = await expoHallAPI.post('/api/video_call_action/', {
            tile: id,
            user: user.state.user.id,
          });
          if (Zoomdata) {
            console.log(Zoomdata);
            window.open(`${videocall_url}`, '_blank');
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    // handleNavigateToSite: () => {
    //   window.open(`${company_url}`, '_blank');
    // },
    handleChatClick: () => {
      // window.open(`${chat_url}`, '_blank');
      setModalIsOpen(true);
      setRequestMeetingIsOpen(false);
      setAuditoriumOpen(false);
      setToggleVideo(false);
      setChatIsOpen((prevChat) => !prevChat);
    },
    handleAuditoriumClick: () => {
      // window.open(`${chat_url}`, '_blank');
      setAuditoriumOpen((prevChat) => !prevChat);
      setModalIsOpen(true);
      setRequestMeetingIsOpen(false);
      setChatIsOpen(false);
      setToggleVideo(false);
    },
    handleToggleVideoSound: () => {
      setVideoIsMuted((previousVideoSound) => !previousVideoSound);
    },
    handleOpenCompanyInfoModal: () => {
      setInfoModalIsOpen(true);
    },
    handleRequestMeeting: () => {
      setModalIsOpen(true);
      setChatIsOpen(false);
      setAuditoriumOpen(false);
      setToggleVideo(false);
      setRequestMeetingIsOpen((prevRequestMeeting) => !prevRequestMeeting);
    },
    handleVideoPlayer: () => {
      setModalIsOpen(true);
      setChatIsOpen(false);
      setAuditoriumOpen(false);
      setRequestMeetingIsOpen(false);
      setToggleVideo((prevToggleVideo) => !prevToggleVideo);
    },
    handleDropCard: useDebouncedFn(async () => {
      try {
        if (user?.state?.user?.id && id) {
          const data = await expoHallAPI.post('/api/business_cards/', {
            attendee: user.state.user.id,
            tile: id,
            first_name: user?.state?.user?.first_name || null,
            last_name: user?.state?.user?.last_name || null,
            email: user?.state?.user?.email || null,
            phone: user?.state?.user?.phone || null,
            company: user?.state?.user?.company || null,
          });
        } else {
          throw new Error('User not logged in');
        }

        //('CARD SUCCESSFULLY DROPPED');
      } catch (error) {
        //console.log('ERROR DROPPING CARD', error);
      }
    }, 5000),
  };

  useEffect(() => {
    if (anyModalOpen && connection && user?.state?.user && group_id) {
      handleConnectChat();
    }
  }, [connection, anyModalOpen]);

  const handleConnectChat = () => {
    connectChatViews(user?.state?.user, group_id.toString(), id, 'update');
  };

  const handleDisconnectChat = () => {
    disconnectChat(user?.state?.user, group_id.toString());
    setTileChatMessages([]);
    setTileChatParticipants([]);
  };

  const handleAction = () => {
    if (counter === 4) {
      setTimeout(() => {
        window.open('https://www.google.com/');
      }, 10000);

      // setModalIsOpen(false);
      // setChatIsOpen(false);
      // setAuditoriumOpen(false);
      // setToggleVideo(false);
      // setRequestMeetingIsOpen(false);
      // handleDisconnectChat();
    }
  };

  return (
    <>
      {modalIsOpen && (
        <SliderItemModal
          modalIsOpen={modalIsOpen}
          closeModal={() => {
            setModalIsOpen(false);
            setChatIsOpen(false);
            setAuditoriumOpen(false);
            setToggleVideo(false);
            setRequestMeetingIsOpen(false);
            handleDisconnectChat();
          }}
          // onAfterOpen={() => {
          //   handleConnectChat();
          // }}
          companyData={{
            data,
          }}
          toggleVideo={toggleVideo}
          setToggleVideo={setToggleVideo}
          iconHandlers={iconHandlers}
          chatIsOpen={chatIsOpen}
          auditoriumIsOpen={auditoriumIsOpen}
          requestMeetingIsOpen={requestMeetingIsOpen}
          additionalResources={tabs?.length ? true : false}
        />
      )}
      {infoModalIsOpen && (
        <CompanyInfoModal
          companyData={{
            tabs,
            group_id,
          }}
          chat_toggle={chat_toggle}
          //modalIsOpen={infoModalIsOpen}
          modalIsOpen={infoModalIsOpen}
          closeModal={() => setInfoModalIsOpen(false)}
        />
      )}
      <TileDiv
        counter={counter}
        className={className}
        onClick={() => {
          setModalIsOpen(true);
          setTileStatus(false);
        }}
        zoomLevel={zoomLevel}
        zoomSpeed={zoomSpeed}
        ref={tileRef}
        transformOrigin={transformOrigin}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <p className="click-instruction">Click the tile for more info...</p>
        {closed && <DirectionsWalkSharpIcon />}
        {!!video && (
          <VideoTransition trigger={isHovering} duration={600} timeout={500}>
            {({ defaultStyle, transitionStyles, state }) => {
              return (
                <ReactPlayer
                  url={video}
                  playing={isHovering}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  height="100%"
                  width="100%"
                  loop
                  volume={1}
                  muted={videoIsMuted}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    overflow: 'hidden',
                    backfaceVisibility: 'hidden',
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                />
              );
            }}
          </VideoTransition>
        )}
        <div
          className="background"
          style={{
            backgroundImage: `url("${image}")`,
            filter: `brightness(${image_brightness}%)`,
            backgroundColor: `${color}`,
          }}
        >
          {/*{video && <h6 className="company">{name}</h6>}*/}
          {/* <div
            className="logo"
            style={{
              backgroundImage: `url("${logo}")`,
            }}
          /> */}
          <div className="icons-bar">
            <IconsBar
              //handleMouseEnter={handleMouseEnter}
              //handleMouseLeave={handleMouseLeave}
              iconColor="black"
              tooltipFontSize={14}
              tooltipPlacement="right"
              videoIsMuted={videoIsMuted}
              chatIsOpen={chatIsOpen}
              switchButton={!closed}
              auditoriumIsOpen={auditoriumIsOpen}
              video_call_toggle={video_call_toggle}
              chat_toggle={chat_toggle}
              meeting_toogle={meeting_toggle}
              businesscard_toggle={business_card_toggle}
              small
              style={{
                right: 0,
                top: 0,
                bottom: 0,
                marginRight: 5,
                zIndex: 2000,
                position: 'absolute',
                flexDirection: 'column',
              }}
              id={id}
              requestMeetingIsOpen={requestMeetingIsOpen}
              iconHandlers={iconHandlers}
              isVideo={!!video}
              toggleVideo={isHovering}
              additionalResources={tabs?.length ? true : false}
            />
          </div>
        </div>
      </TileDiv>
    </>
  );
};

const TileDiv = styled.div`
  position: relative;
  height: ${(props) => props.height}px;
  ${(props) => props.transformOrigin}
  :hover {
    ${(props) => setZoomLevel(props.zoomLevel, props.counter)}
    ${(props) => setZoomSpeed(props.zoomSpeed)}
  }
  video {
    object-fit: contain;
    background-color: black;
    height: 100%;
    width: 100%;
  }
  .MuiSvgIcon-root {
    font-size: 4rem;
    position: absolute;
    background: #000000a6;
    border-radius: 50%;
    padding: 10px;
    right: 8%;
    top: 1%;
    z-index: 1000;
  }
`;

export default styled(SliderItem)`
  height: 100%;
  width: 100%;
  transition: 0.2s transform;
  position: relative;
  padding: 0;
  /* border: 2px white solid; */
  z-index: 0;
  background-color: white;
  backface-visibility: hidden;
  .background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    z-index: 0;
    transition: z-index 0 0.2s;
    :hover {
      z-index: -1;
    }
  }
  .modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: blue;
  }
  .icons-bar {
    opacity: 0;
    transition: 0.2s;
    z-index: 2000;
  }
  .click-instruction {
    margin-top: 0.5rem;
    margin-left: 1rem;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 40;
    font-weight: 500;
    pointer-events: none;
    font-size: 1rem;
    color: ${setColor.mainGrey};
    text-align: left;
  }
  .company {
    margin: 1rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: ${setColor.mainGrey};
    /* pointer-events:none; excludes hover */
    pointer-events: none;
    font-size: 1.8rem;
    opacity: 0;
    transition: all 0.6s;
  }
  p {
    width: 70%;
    transition: 0.4s;
    cursor: context-menu;
    ::selection {
      background-color: transparent;
    }
  }
  :not(:hover) {
    z-index: -1;
  }
  :hover {
    /* z-index: 20; */
    /* border: 2px solid ${setColor.primaryBlue}; */
    backface-visibility: hidden;
    transition: all 0.4s;
    transition-delay: 0.5s;
    .click-instruction {
      color: white;
      opacity: 1;
    }
    .company {
      opacity: 1;
      z-index: 100;
      transition: all 0.4s 0.6s;
    }
    .icons-bar {
      opacity: 1;
      transition: 0.2s;
      z-index: 2000;
    }
  }
`;
