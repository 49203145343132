import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { Link, useLocation, useHistory } from 'react-router-dom';

import queryString from 'query-string';

import styled from 'styled-components';
import { setColor, setBoxShadow } from '../../styles/styles';

import { Context as UserContext } from '../../context/UserContext';

import expoHallAPI from '../../api/expoHallAPI';
import { Context as AttendantContext } from '../../context/AttendantContext';
import { InformationalIcon } from '../../styles/icons';
import SiteData from '../../config';

const Register = ({ className }) => {
  const location = useLocation();
  const history = useHistory();

  const {
    state: { user },
    setUser,
  } = useContext(UserContext);
  const {
    state: { attendant },
    setAttendant,
  } = useContext(AttendantContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [screenName, setScreenName] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [allowSave, setAllowSave] = useState(true);
  const [allowPass, setAllowPass] = useState(true);
  const [alreadyExist, setalreadyExist] = useState(false);
  const [notInList, setNotInList] = useState(false);

  useEffect(() => {
    // setEmail(queryString.parse(location.search).email);
    if (location.state) {
      if (location.state.email) {
        setEmail(location.state.email);
      }
      if (location.state.password) {
        setPassword(location.state.password);
      }
    }
  }, []);

  useEffect(() => {
    authenticateUser();
  }, []);

  const authenticateUser = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-user');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendee/profile/');
      if (data) {
        history.push('/');
      }
    } catch (error) {
      expoHallAPI.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('shrm-li-expo-user');
      setUser(undefined);
      authenticateAttendant();
    }
  };

  const authenticateAttendant = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-attendant');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendant/profile/');
      // console.log(data);
      if (data) {
        history.push('/dashboard');
      }
    } catch (error) {
      expoHallAPI.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('shrm-li-expo-attendant');
      setAttendant(undefined);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let APIroute = '/attendee/create/';
      if(SiteData.checkRegisterList){
        APIroute = '/attendee/register/';
      }
      await expoHallAPI.post(APIroute, {
        first_name: firstName,
        last_name: lastName,
        email: email.toLowerCase(),
        company: company,
        phone: phone,
        screen_name: screenName,
        allow_save: allowSave,
        allow_auto_pass: allowPass,
        password: password,
      });
      const { data } = await expoHallAPI.post('/login/', {
        email: email.toLowerCase(),
        password,
        schema: SiteData.schemaName,
      });
      localStorage.setItem('shrm-li-expo-user', data.token);
      history.push('/login');
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 403) {
          setNotInList(true)
          setalreadyExist(false);
        }else{
          setalreadyExist(true);
          setNotInList(false)
        }
      }else{
        setalreadyExist(true);
        setNotInList(false)
      }
    }
  };

  return (
    <div className={className}>
      <div className="Register">
        <h3>{SiteData.loginTitle}</h3>
        <div className="LoginForm_image">
          <img src={SiteData.loginImagesrc} alt={SiteData.loginImagealt} />
        </div>
        <div className="Register_body">
          {alreadyExist && (
            <section>
              <div className="instruction-box">
                <div className="item">
                  <div className="item-icon">
                    <InformationalIcon />
                  </div>
                  <div className="item-text">
                    There was an error registering your account!
                    <br />
                    please contact us at spacessupport@matchboxvirtual.com
                    {/* <br />
                    or
                    <br />
                    join our{' '}
                    <a
                      className="zoomLink"
                      href={
                        'https://zoom.us/j/99858503399?pwd=OEZzb1pPbG9FdDNvRnJXSlJuaTVTQT09'
                      }
                    >
                      zoom
                    </a>{' '}
                    room to speak directly with technical support. */}
                  </div>
                </div>
              </div>
            </section>
          )}
          {notInList && SiteData.checkRegisterList &&
          <section>
            <div className="instruction-box">
              <div className="item">
                <div className="item-icon">
                  <InformationalIcon />
                </div>
                <div className="item-text">
                  This email address is not on the list of registrants for this event.
                  <br />
                  If you would like to register
                  or
                  you believe this is an error,
                  <br/>
                  please contact supportspaces@matchboxvirtual.com
                </div>
              </div>
            </div>
          </section>

          }
          <form onSubmit={handleSubmit} action="" className="Register_Form">
            <TextField
              name="email"
              value={email}
              id="Filled-basic"
              required
              type="Email"
              label="Email"
              variant="filled"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              name="password"
              id="Filled-password-input"
              label="Password"
              type="password"
              required
              value={password}
              autoComplete="current-password"
              variant="filled"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              passwordField
            />
            {/* <div className="disclaimerText">
              You are entering an event public space for this first time. These
              spaces are designed to exchange information in order to
              effectively use these spaces and protect the information that you
              may have registered with you are required to have a NAME TAG
              containing the information that you are comfortable sharing. You
              can keep as much or as little information on the this tag (if we
              cannot get needed information from your name tag we will ask you
              to provide additional information). The registration system has
              told us the following information about you. You may fill in any
              missing information or change it to anything you are comfortable
              with.
            </div> */}
            <div className="Register_names">
              <TextField
                name="firstName"
                value={firstName}
                id="Filled-basic"
                type="Text"
                required
                label="First Name"
                variant="filled"
                className="register_Form_first"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />

              <TextField
                name="lastName"
                value={lastName}
                id="Filled-basic"
                required
                type="Text"
                label="Last Name"
                variant="filled"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
            <TextField
              name="screenName"
              value={screenName}
              id="Filled-basic"
              type="Text"
              label="Screen Name"
              variant="filled"
              onChange={(e) => {
                setScreenName(e.target.value);
              }}
            />
            <div className="Register_names">
              <TextField
                name="company"
                value={company}
                id="Filled-basic"
                type="Text"
                label="Company"
                variant="filled"
                className="register_Form_first"
                onChange={(event) => {
                  setCompany(event.target.value);
                }}
              />
              <TextField
                name="phone"
                id="Filled-basic"
                type="Text"
                label="Phone Number"
                placeholder="###-###-####"
                variant="filled"
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
              />
            </div>
            <div className="Register_checkbox">
              <div className="checkbox">
                <input
                  checked={allowSave}
                  type="checkbox"
                  id="allowSave"
                  name="allowSave"
                  /* value="yes" */
                  className="checkmark"
                  onChange={() => setAllowSave((prevState) => !prevState)}
                />
                <label for="allowSave" className="checkbox-label">
                  Allow us to save your information for the duration of the
                  event
                </label>
              </div>
              <div className="checkbox">
                <input
                  checked={allowPass}
                  type="checkbox"
                  id="allowPass"
                  name="allowPass"
                  /* value="yes" */
                  className="checkmark"
                  onChange={() => setAllowPass((prevState) => !prevState)}
                />
                <label for="allowPass" className="checkbox-label">
                  Authorize us to automatically pass your info to exhibitors
                </label>
              </div>
            </div>
            <button
              // disabled={!email && !password}
              type="submit"
              disabled={!email && !password && !firstName && lastName}
            >
              Proceed
            </button>
          </form>

          <h6 className="Register_questions">
            Have an account ?{' '}
            <span>
              <Link to="/login"> Login</Link>
            </span>{' '}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default styled(Register)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  text-align: center;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif, arial;
    text-align: center;
  }
  .zoomLink {
    color: ${setColor.secondaryBlue};
  }

  .disclaimerText {
    text-align: left;
    margin: 10px 0;
    font-size: 16px;
  }

  .Register_questions {
    margin-top: 1%;
    font-size: 15px;
    span {
      font-size: 20px;
      a {
        text-decoration: none;
        color: ${setColor.secondaryBlue};
      }
    }
  }
  .Register_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Register_Form {
    display: flex;
    flex-direction: column;
    /* margin-right: 35%;
    margin-left: 35%; */
    .register_Form_first {
      .MuiInputBase-root {
        margin-right: 10px;
      }
    }
    small {
      text-align: justify;
      font-weight: bold;
    }
    .Register_names {
      .MuiFormControl-root {
        width: 50%;
      }
    }
    button {
      width: fit-content;
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
      background: ${setColor.secondaryBlue};
      border: none;
      color: white;
      padding: 0.5rem 1.5rem;
      border-radius: 5px;
      font-size: 20px;
      cursor: pointer;
      transition: background-color ease 0.3s;
      :hover {
        background-color: ${setColor.tertiaryBlue};
        box-shadow: ${setBoxShadow.lightPressed};
      }
      :focus {
        outline: none;
        box-shadow: none;
      }
    }
    .MuiInputBase-root {
      margin-top: 12px;
      font-size: 20px;
      input {
        color: white;
      }
    }
    .MuiFormLabel-root {
      font-size: 20px;
      color: white;
    }
    .MuiInput-underline:before {
      border-bottom: 1px solid ${setColor.secondaryBlue};
    }
    .MuiInput-underline:after {
      border-bottom: 1px solid ${setColor.secondaryBlue};
    }
    .Register_checkbox {
      text-align: start;
      margin-top: 5%;
      .checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label {
          margin-left: 10px;
        }
      }
    }
  }
  .instruction-box {
    display: flex;
    flex-direction: column;
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${setColor.primaryBlue};
    padding: 10px;
    border-radius: 10px;
    :not(:last-child) {
      margin-bottom: 2rem;
    }
    .item-icon {
      height: 6rem;
      width: 6rem;
      fill: ${setColor.primaryBlue};
      padding: 0.5rem;
    }
    .item-text {
      font-size: 1.6rem;
      margin-left: 1rem;
      letter-spacing: 1.2px;
    }
  }
`;
