import React, { useState, useEffect, useContext } from 'react';

import ReactModal from 'react-modal';

import styled, { keyframes } from 'styled-components';
import { Context as SocketContext } from '../../context/SocketContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CloseIcon } from '../../styles/icons';
import Smallchatbox from '../Chatbox/Smallchatbox';
import { setColor, sizes } from '../../styles/styles';
import {
  sendChatMessage,
  retrieveMoreMessages,
  deleteChatMessage,
} from '../../api/expoHallSocket';
import { Context as UserContext } from '../../context/UserContext';
import SiteDate from '../../config';
const CompanyInfoModal = ({
  closeModal,
  companyData: { tabs, group_id },
  className,
  modalIsOpen,
  chat_toggle,
}) => {
  const {
    state: {
      tileChatMessages,
      tileChatParticipants,
      switchButton,
      loadingMoreMessages,
    },
    setTileChatMessages,
    setTileChatParticipants,
    setLoadingMoreMessages,
  } = useContext(SocketContext);
  const { state: { user } = {}, setUser } = useContext(UserContext);
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      zIndex: 900,
    },
    content: {
      height: '85%',
      width: '70%',
      transform: 'translate(-50%, -50%)',
      top: '53%',
      left: '41%',
      right: 'auto',
      bottom: 'auto',
      borderRadius: 0,
      borderWidth: 0,
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      backgroundColor: 'white',

      padding: 0,
      backfaceVisibility: 'hidden',
      boxShadow: '0px 0px 48px 0px rgba(0,0,0,0.4)',
      overflow: 'hidden',
      zIndex: 1000,
    },
  };

  const [tabId, setTabId] = useState(null);

  useEffect(() => {
    setTabId(tabs[0].id);
  }, []);

  useEffect(() => {
    console.log('TABID', tabId);
    console.log(
      'TABS',
      tabs.filter((tab) => tab.id === parseInt(tabId))
    );
  }, [tabId]);

  const sorttab = tabs.sort((a, b) => a.priority - b.priority);
  useEffect(() => {});

  const sendChatMessageTile = (message) => {
    sendChatMessage(user, group_id.toString(), message);
  };
  const deleteMessageTile = (message) => {
    deleteChatMessage(group_id.toString(), message);
  };

  const retrieveMoreMessagesTile = (currentPage) => {
    retrieveMoreMessages(group_id.toString(), currentPage);
    setLoadingMoreMessages(true);
  };
  const combineResources = (pdf, url) => {
    const pdfWithType = pdf.map((item) => ({ ...item, type: 'pdf' }));
    const urlWithType = url.map((item) => ({ ...item, type: 'url' }));
    const mergedResources = pdfWithType
      .concat(urlWithType)
      .sort((a, b) => a.priority - b.priority);
    //console.log('MERGED RESOURCES', mergedResources);
    return mergedResources;
  };

  return (
    <ReactModal
      isOpen={modalIsOpen}
      style={customStyles}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <div className={className}>
        <div
          onClick={() => {
            closeModal();
          }}
          className="close-icon"
        >
          <CloseIcon />
        </div>
        <div className="Tabs_container">
          <div className="dropdown">
            <label for="dropdown">
              Select a resource from the dropdown menu
            </label>
            <select
              id="dropdown"
              onChange={(event) => setTabId(event.target.value)}
            >
              {tabs &&
                sorttab.map((tab) => (
                  <option value={tab.id} key={tab.id}>
                    {tab.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="tab-content">
            {tabs &&
              tabs
                .filter((tab) => tab.id === parseInt(tabId))
                .map((tab) => {
                  if (tab.tab_type === 'PDF') {
                    return (
                      <div className="pdf">
                        {tab.sub_header && <h5>{tab.sub_header}</h5>}
                        <object
                          data={tab.pdf_file}
                          type="application/pdf"
                        ></object>
                      </div>
                    );
                  }

                  if (tab.tab_type === 'URL') {
                    return (
                      <div className="website">
                        {tab.sub_header && (
                          <h5 id="tab_subheader">{tab.sub_header}</h5>
                        )}
                        {/* <div className="image-overlay"> */}
                        {tab.image && (
                          <img src={tab.image} alt="Website Preview" />
                        )}
                        <div className="website-text">
                          <p
                          // style={{ color: tab.image ? 'white' : 'black' }}
                          >
                            This link will take you to an external website in a
                            new tab. Don't forget to come back... we hope to see
                            you soon!
                          </p>
                          <a
                            href={tab.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Go to site
                          </a>
                        </div>
                        {/* </div> */}
                      </div>
                    );
                  }
                })}
          </div>
        </div>
        {chat_toggle && (
          <div className="tab_chat">
            <h6>Booth Discussion</h6>
            <Smallchatbox
              messages={tileChatMessages}
              participants={tileChatParticipants}
              sendMessage={sendChatMessageTile}
              setLoadingMoreMessages={setLoadingMoreMessages}
              loadingMoreMessages={loadingMoreMessages}
              retrieveMoreMessages={retrieveMoreMessagesTile}

              // className="tab_chat_footer"
            />
          </div>
        )}
      </div>
    </ReactModal>
  );
};

const overlayAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export default styled(CompanyInfoModal)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  .Tabs_container {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .dropdown {
      background: #696868eb;
      padding: 1rem;
      color: white;
      label {
        color: white;
      }
    }
  }
  label {
    display: block;
    color: black;
    font-size: 2rem;
    font-family: inherit;
    margin: 0.5rem 1rem;
    text-transform: capitalize;
  }
  select {
    width: 100%;
    padding: 0.5rem 1rem;
    font-family: inherit;
    /* background-color: ${setColor.matchBoxRed}; */
    outline: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 1.6rem;
  }
  option {
    :checked {
      background-color: ${setColor.matchBoxRed};
      color: white;
    }
  }

  .tab-content {
    width: 100%;
    /* height: 100%; */
    flex: 1;
    position: relative;
  }

  .tab_chat {
    background-color: ghostwhite;
    width: 30%;
    color: black;
    border-left: 1px solid #d3d3d347;
    height: 100%;

    h6 {
      color: ${setColor.midnightBlue};
      text-align: center;
      margin-top: 10px;
    }
  }

  .close-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    /* right: ${({ switchButton }) => (switchButton ? `31% ` : `1%`)}; */
    z-index: 10;
  }

  h5 {
    color: black;
    background-color: white;
    margin-left: 10px;
    width: 100%;
    margin: 0.5rem 1rem;
    font-size: 1.6rem;
    text-transform: capitalize;
    //position absolute removed to solve header overlap bug
  }

  .pdf {
    width: 100%;
    height: 100%;
  }

  .website {
    position: relative;
    height: 100%;
    width: 100%;

    img {
      position: absolute;
      width: 100%;
      max-height: 100%;
      object-fit: cover;

      z-index: -1;
    }
    .website-text {
      position: absolute;
      height: max-content;
      width: 50%;
      background: white;
      margin: auto;
      top: 25%;
      transform: translate(50%, 50%);
      padding: 1rem;
      p {
        color: black;
      }
    }
  }

  object {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
  }

  .image-overlay {
    /* position: absolute;
    top: 0;
    left: 0; */
    z-index: 100;
    // top: 23%;
    display: flex;
    justify-content: center;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    font-family: inherit;
    color: ${setColor.mainGrey};

    animation: ${overlayAnimation} 0.5s;

    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    /* padding: 3rem; */
    width: 100%;
    height: 58vh;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      /* top: 25%; */
      object-fit: contain;
    }
  }
  .website-text {
    text-align: center;
    padding: 5px;
    align-self: center;
    width: 70%;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 1px;
    /* div {
        margin-top: 2rem;
        display: block;
        text-align: center;
        text-decoration: none;
        font-size: 2rem;
      } */

    p {
      color: ${setColor.mainGrey} !important;
      text-align: center;
    }

    a {
      /* font-size: 2rem; */
      font-size: 20px;
      text-decoration: none;
      display: flex;
      justify-content: center;
    }
  }
`;
