const getTransformOrigin = (node) => {
  const { top, left, bottom, right } = node?.current?.getBoundingClientRect();

  const topThreshold = 130

  const heightFromBottom = window.innerHeight - bottom;
  const widthFromRight = window.innerWidth - right;

  if (top < topThreshold && left < 100) {
    return `transform-origin: top left;`;
  }
  if (left < 100 && heightFromBottom < 100) {
    return `transform-origin: bottom left;`;
  }
  if (left < 100) {
    return `transform-origin: center left;`;
  }
  if (top < topThreshold && widthFromRight < 100) {
    return `transform-origin: top right;`;
  }
  if (heightFromBottom < 100 && widthFromRight < 100) {
    return `transform-origin: bottom right;`;
  }
  if (widthFromRight < 100) {
    return `transform-origin: center right;`;
  }
  if (top < topThreshold) {
    return `transform-origin: top center;`;
  }
  if (heightFromBottom < 100) {
    return `transform-origin: bottom center;`;
  }

  return `transform-origin: center center;`;
};

export default getTransformOrigin;
