import axios from 'axios';
import SiteData from '../config';

const expoHallAPI = axios.create({
  //baseURL: 'http://dev.mbvs-backend-env.eba-mhyeey22.us-west-2.elasticbeanstalk.com/',
  // baseURL: 'http://ilads.my-cloned-env2.us-west-2.elasticbeanstalk.com/',
  // baseURL: 'http://dev.localhost:8001/',
  baseURL: `https://${SiteData.schemaName}.mbvsbackend.com/`,
  headers: { 'Content-Type': 'application/json' },
});

export default expoHallAPI;
