import React from 'react';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import ReactNotification, { store } from 'react-notifications-component';

export const NotificationMessage = ({ className }) => {
  return <ReactNotification />;
};

export const NotificationFactory = ({
  title,
  message,
  type,
  insert,
  container,
  duration,
}) => {
  const notification = {
    title: title,
    message: message,
    type: type,
    insert: insert,
    container: container,
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration,
    },
  };
  store.addNotification({
    ...notification,
  });
};
