import React  from 'react';
import styled from 'styled-components';
import {setColor} from '../../styles/styles';


const RemoveBackgroundForm = ({ className, removeBackground }) => {
  return (
    <div className={className}>
      <h6>Are you sure you want to remove background image and color?</h6>
      <button
        onClick={removeBackground}
        className="request_register"
      >
        Remove
      </button>

    </div>
  );
};

export default styled(RemoveBackgroundForm)`
  h6{
    text-align: center;
    color: ${setColor.mainGrey};
    margin-top: 3rem;
  }

  .request_register {
    background: ${setColor.secondaryDanger};
    width: fit-content;
    margin-right: 5%;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 3px;
    transition: all ease 0.3s;
    color: white;
    float: right;
    cursor: pointer;
  }

`;
