import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Context as SocketContext } from '../../context/SocketContext';
import { ShowHideIcon, ClipboardIcon } from '../../styles/icons';
import Switch from '@material-ui/core/Switch';

import { setColor, sizes } from '../../styles/styles';
import {Socket} from '../../api/expoHallSocket';
import SiteDate from '../../config';
const AttendantDashboardInfo = ({
  className,
  boothInfo: {
    id = '',
    name = '',
    videocallHostUrl = '',
    chatUrl = '',
    firstName = '',
    password = '',
    closed,
  },
}) => {
  const [passcodeShown, setPasscodeShown] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [checked, setchecked] = useState(closed);

  const hidePasscode = (passcode) => {
    return Array(passcode?.length).fill(' • ').join('');
  };
  const copyToClipboard = (text) => async () => {
    await navigator.clipboard.writeText(text);
  };

  const handleChange = (checked) => {
    try {
      Socket.send(
        JSON.stringify({
          action: 'toggleBooth',
          schema: SiteDate.schemaName,
          tileid: id,
        })
      );
    } catch (error) {
      window.location.reload();
    }
    setchecked(checked.target.checked);
  };

  return (
    <div className={className}>
      <div className="data">
        Videocall Host URL:{' '}
        <a href={videocallHostUrl} target="_blank" rel="noopener noreferrer">
          Here
        </a>
        <div
          className="icon-container"
          onClick={copyToClipboard(videocallHostUrl)}
        >
          <ClipboardIcon />
        </div>
      </div>

      {/* <div className="switch">
      <div className="switch-inner">
        <p>On/off Switch</p>
        <Switch
        className="react-switch"
        checked={checked}
        onChange={handleChange}
        color="primary"
        />
        </div>
        (Chat, Video Call, Request Meeting)
      </div> */}
      {/* <div className='copied-notification'> Link Copied!</div> */}
    </div>
  );
};

export default styled(AttendantDashboardInfo)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  @media only screen and (max-width: ${sizes.desktop}px) {
    flex-direction: column;
  }

  /* display: grid; 
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  justify-content: space-evenly;
  background-color: purple; */

  .data {
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.6rem;

    a {
      text-decoration: none;
      color: ${setColor.primaryBlue};
      margin-left: 0.5rem;
    }

    #passcode {
      color: ${setColor.primaryBlue};
    }

    span {
      margin-left: 0.5rem;
    }
  }
  .switch {
    align-items: center;
  }

  .switch-inner {
    display: flex;
    align-items: center;
  }
  .icon-container {
    height: 3rem;
    width: 3rem;
    margin: 0 0.5rem;
    transition: all 0.2s;
    fill: grey;
    cursor: pointer;

    :hover {
      color: darkcyan;
      transform: scale(1.1);
      transition: all 0.2s;
    }

    :active {
      transform: scale(1.2);
    }
  }

  .user-info {
    color: ${setColor.primaryBlue};
  }

  .copied-notification {
    position: fixed;
    background-color: red;
    bottom: 2rem;
    padding: 1rem 1.5rem;
    font-size: 1.6rem;
    user-select: none;
  }

  a {
    margin-left: 0.5rem;
  }
`;
