import expoHallAPI from '../api/expoHallAPI'

const getTimeLogged = async (action) => {
    
    const nowUTCString = new Date(new Date().toUTCString()).toISOString();

    try {
        console.log(`%cTIMESTAMP FOR CASE ${action}: ${nowUTCString}`, 'color: darkcyan; font-family:monospace; font-size: 20px; font-weight: bold')
        await expoHallAPI.post('/api/logs/', {
            event: action,
            timestamp: nowUTCString
        })
    } catch (error) {
        console.log(`there was an error logging the timestamp: ${error}`)
    }
}

export default getTimeLogged;
