import React, { useContext, useState } from 'react';

import { useLocation, useHistory, Link } from 'react-router-dom';

import styled from 'styled-components';

import LogoutButton from '../globals/LogoutButton';

import { Context as AttendantContext } from '../../context/AttendantContext';

import { BackArrow } from '../../styles/icons';
import { sizes } from '../../styles/styles';

import { useWindowScroll } from 'beautiful-react-hooks';

const NavDiv = styled.div`
  height: ${(props) => (props.scrollY ? '5rem' : '7rem')};
  transition: height 0.2s;
`;

const NavbarClient = ({ className }) => {
  const {
    state: { attendant },
    setAttendant,
  } = useContext(AttendantContext);

  const location = useLocation();
  const history = useHistory();

  const [scrollY, setScrollY] = useState(0);

  useWindowScroll(() => setScrollY(window.scrollY));

  return (
    <NavDiv className={className} scrollY={scrollY}>
      <Link
        to={'/'}
        target="_blank"
        rel="noopener noreferrer"
        className="back-button"
      >
        <BackArrow />
        <div className="caption">Go To Expo Hall</div>
      </Link>
      <div className="title">ILADS Client Console</div>
      <div className="greeting">
        {/* Welcome{attendant?.first_name && `, ${attendant?.first_name}`}! */}
        <div className="greeting-box">
          <div className="greeting-name">Welcome, {attendant?.first_name}!</div>
          <div className="greeting-email">{attendant?.email}</div>
        </div>
        {location.pathname === '/dashboard' && (
          <LogoutButton
            onClick={() => {
              history.push('/login');
              localStorage.removeItem('shrm-li-expo-attendant');
              setAttendant({});
            }}
          >
            Logout
          </LogoutButton>
        )}
      </div>
    </NavDiv>
  );
};

export default styled(NavbarClient)`
  /* display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0; */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  padding: 2rem;
  /* align-items: center; */

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 80%;
  .greeting {
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .greeting-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .greeting-name {
  }

  .greeting-email {
    font-size: 1.2rem;
    font-weight: 300;
  }

  .title {
    font-size: 2rem;
    /* position: absolute; */
    /* display: flex;
    justify-content: center; */
    /* left: 0; */
    /* width: 70%; */
    /* right: 0; */

    @media screen and (max-width: ${sizes.desktop}px) {
      visibility: hidden;
    }
  }

  .caption {
    font-size: 2rem;
    opacity: 1;
    margin-left: 1rem;
  }

  svg {
    height: 4rem;
    width: 4rem;
    display: block;
    margin: 0 auto;
    opacity: 1;
    stroke-width: 3px;
    stroke: white;
  }

  .back-button {
    fill: white;
    transition: all 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 30;
    text-decoration: none;
    color: white;

    :hover {
      cursor: pointer;
      svg {
        transform: translateX(-5px);
        transition: all 0.2s;
      }
      transition: all 0.2s;
    }
  }
`;
