import React, { Fragment,useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { setColor } from '../../styles/styles';
import { Context as UserContext } from '../../context/UserContext';
import expoHallAPI from '../../api/expoHallAPI';
import { NotificationFactory } from '../globals/NotificationMessage';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import 'date-fns';
import { Context as AttendantContext } from '../../context/AttendantContext';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const TabEditForm = ({ className, tab,editTab, setHeightEditModal }) => {
  const classes = useStyles();

  const {state: {user}} = useContext(UserContext);

  const { state, state: { attendant,booth } = {}, setAttendant,setBooth } = useContext(
    AttendantContext
  );

  const [tabType, setTabType] = useState('PDF');
  const [tabName, setTabName] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('');
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileUploadChecker, setFileUploadChecker] = useState(false);
  const [url, setURL] = useState('');

  const inputRef = useRef();

  useEffect(() => {
    if(tab){
      setTabName(tab.name);
      setTabType(tab.tab_type);
      setDescription(tab.sub_header);
      setPriority(tab.priority);
      setURL(tab.url);
      if(tab.tab_type ==='PDF'){
        setFile(tab.pdf_file);
        if (tab.pdf_file){
          setFileName(tab.pdf_file.split('/').pop())
        }
      }
      if(tab.tab_type ==='URL'){
        setFile(tab.image);
        if (tab.image){
          setFileName(tab.image.split('/').pop())
        }
      }
      console.log(tab)
      console.log(inputRef.current)
    }
  }, [tab]);

  const handleSubmit = async (event,ref) => {
    console.log("hello")
    event.preventDefault();
    let notification = {
      title: "Success",
      message: "Tab Updated",
      type: 'success',
      insert: 'top',
      container: 'top-right',
      duration: 2000
    };
    try{
      const formData = new FormData();
        formData.append('tab_type', tabType);
        formData.append('name', tabName);
        formData.append('sub_header', description);
        formData.append('url', url);
        formData.append('priority', priority);
      if (ref.current.files[0]) {
        if(tabType === 'PDF'){
          formData.append('pdf_file', ref.current.files[0]);
        }
        if(tabType === 'URL'){
          formData.append('image', ref.current.files[0]);
        }
      }
      const responseTab = await expoHallAPI.put(`/api/tabs/${tab.id}/`, formData);
      console.log(responseTab)
      editTab(responseTab.data)
      NotificationFactory(notification);
    } catch(error){

      console.log('ERROR', error);

    }
  };
  const onFileChange = event =>{
    if(event.target.files[0]){
      setFileUploadChecker(true)
    }else{
      setFileUploadChecker(false)
    }
  };

  const openWindow=( url )=>
  {
    window.open(url, '_blank');
    window.focus();
  };

  const handleSetTabType = (event)=>{
    setTabType(event.target.value)
    if(event.target.value ==='PDF'){
      setHeightEditModal('380');
      setFile(tab.pdf_file);
      if (tab.pdf_file){
        setFileName(tab.pdf_file.split('/').pop())
      }else{
        setFileName('')
      }
    }
    if(event.target.value ==='URL'){
      setHeightEditModal('460');
      setFile(tab.image);
      if (tab.image) {
        setFileName(tab.image.split('/').pop())
      }else {
      setFileName('')
      }
    }

  };
  console.log('fileUploadChecker',fileUploadChecker);
  console.log('fileName',fileName)

  return (
    <div className={className}>
      <div className="request_container">
        <h6>Edit Tab</h6>
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <div className="request_name">
            <TextField
              id="tab-type"
              select
              label="Select Tab Type"
              name="tab-type"
              value={tabType}
              onChange={(event) => handleSetTabType(event)}
              error={tabType === ''}
              helperText={tabType === '' ? 'Required' : ' '}
              variant="filled"
              style={{
                marginRight: '10px',
              }}
            >
              <MenuItem key="PDF" value="PDF">
                PDF
              </MenuItem>
              <MenuItem key="URL" value="URL">
                Website
              </MenuItem>
            </TextField>
            <TextField
              error={tabName === ''}
              helperText={tabName === '' ? 'Required' : ' '}
              placeholder="Tab Name"
              label="Tab Name"
              variant="filled"
              id="tab-name"
              name="tab-name"
              value={tabName}
              onChange={(event) => {
                setTabName(event.target.value);
              }}
            />
          </div>

          <TextField
            id="description"
            label="Sub Header"
            variant="filled"
            type="text"
            placeholder="Sub Header"
            name="description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
          <TextField
            error={priority === ''}
            helperText={priority === '' ? 'Required' : ' '}
            id="priority"
            placeholder="pick a number"
            label="Tab priority (order of appearance)"
            variant="filled"
            type="number"
            name="priority"
            value={priority}
            onChange={(event) => {
              setPriority(event.target.value);
            }}
          />
          {tabType === 'URL'
            ?
            <Fragment>
            <TextField
              error={url === ''}
              helperText={url === '' ? 'Required' : ' '}
              type="url"
              pattern="http://.*"
              id="url"
              placeholder="url"
              value={url}
              onChange={(event) => setURL(event.target.value)}
              label="Website URL"
              variant="filled"
              name="company"
            />
            <div className='containerPdfUploadDownload'>
              <div className="form-input-group">
                <label htmlFor="data" className="form-label">
                  Upload {tabType === 'PDF' ? 'PDF' : 'Picture'}
                </label>
                <input
                  type="file"
                  ref={inputRef}
                  id="data"
                  accept={tabType === 'PDF' ? 'application/pdf' : 'image/*'}
                  onChange={onFileChange}
                />
              </div>
              {!fileUploadChecker && fileName &&
              <div className='containerPdfDownload'>
                <div className='pdfFileName' onClick={()=>openWindow(file)}>{fileName}</div>
              </div>}
            </div>
            </Fragment>
            :
            <div className='containerPdfUploadDownload'>
              <div className="form-input-group">
                <label htmlFor="data" className="form-label">
                  Upload {tabType === 'PDF' ? 'PDF' : 'Picture'}
                  <span className="required">&nbsp;required</span>
                </label>
                <input
                  type="file"
                  ref={inputRef}
                  id="data"
                  accept={tabType === 'PDF' ? 'application/pdf' : 'image/*'}
                  onChange={onFileChange}
                />
              </div>
              {!fileUploadChecker && fileName &&
              <div className='containerPdfDownload'>
                <div className='pdfFileName' onClick={()=>openWindow(file)}>{fileName}</div>
              </div>}
            </div>}
          <button
            className="request_register"
            type="submit"
            disabled={(tabType === 'URL' && !url)|| !tabType || !priority || !tabName || (tabType !== 'URL' && !fileUploadChecker)}
            onClick={(e)=>handleSubmit(e,inputRef)}
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default styled(TabEditForm)`
  height: 100%;
  width: 100%;
  color: #d1d1d1;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 2rem;
  font-weight: 300;
  overflow-y: auto;
  .request_container {
    margin-bottom: 3rem;
    background: white;
  }
  .request_container h6 {
    color: ${setColor.secondaryBlue};
    text-align: center;
  }
  .request_timeslot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .request_name {
    display: flex;
  }

  .request_name .MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 80%;
  }
  .meeting_available {
    margin-bottom: 2rem;
    color: ${setColor.secondaryBlue};
  }
  .request_textarea {
    background-color: inherit;
    color: black;
    font-family: inherit;
    padding: 1rem 2rem;
    /* border-radius: 2rem; */
    border: 1px solid;
    outline: none;
    border-color: grey;
    resize: none;
    border-radius: 0;
  }
  .request_datepicker label {
    font-size: 14px;
    top: -1px;
  }
  .request_register {
    background: ${setColor.secondaryBlue};
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 3px;
    transition: all ease 0.3s;
    color: white;
  }
  .request_register:hover {
    background: ${setColor.tertiaryBlue};
    color: white;
    font-size: bold;
  }
  label + .MuiInput-formControl {
    margin-top: 0 !important;
  }
  .request_date {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .request_date .MuiFormHelperText-root.Mui-error {
    color: #171616;
    font-size: 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    align-items: flex-start;
    width: 100%;
  }

  h4 {
    text-align: center;
    color: ${setColor.mainGrey};
  }
  
  .form-input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    input{
    max-width: 200px;
    }
  }

  .form-label {
    margin: 1rem 0;
    color: ${setColor.primaryBlue};
    font-size: 1.6rem;
  }

  .required {
    font-style: italic;
    font-size: 1.4rem;
    color: rgb(186, 57, 45);
  }
  
  .containerPdfUploadDownload{
    display:flex;
    margin: 0;
    margin-left: 1rem;
  }
  .containerPdfDownload{
    margin: 1rem 0;
    .pdfFileName{
      margin-bottom: 1rem;
      color: rgb(116,186,255);
      font-size: 1.6rem;
      cursor: pointer;
      text-decoration: underline;
    }
    
  }
`;
