import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import Ping from 'ping.js';
import styled from 'styled-components';
import GlobalStyles from './components/globals/GlobalStyles';
import ExhibitorContext from './context/exhibitorContext';
import Register from './components/registrationForm/Register';
import SliderPage from './pages/SliderPage';
import RegistrationForm from './components/registrationForm/RegistrationForm';
import { Provider as UserProvider } from './context/UserContext';
import { Provider as AttendantProvider } from './context/AttendantContext';
import { Provider as ClientProvider } from './context/ClientContext';
import { Context as SocketContext } from './context/SocketContext';
import ResetPassword from './components/registrationForm/ResetPassword';
import Navbar from './components/globals/Navbar';
import { NotificationFactory } from './components/globals/NotificationMessage';
import SiteData from './config';
import AttendantDashboard from './components/AttendantConsole/AttendantDashboard';
import AttendantTileEditor from './components/AttendantConsole/AttendantTileEditor';
import NavbarAttendant from './components/globals/NavbarAttendant';
import ForgotPassword from './components/registrationForm/ForgotPassword';
import ClientLogin from './components/ClientConsole/ClientLogin';
import ClientDashboard from './components/ClientConsole/ClientDashboard';
import NavbarClient from './components/ClientConsole/NavbarClient';
import AuditoriumPage from './pages/AuditoriumPage';
import { NotificationMessage } from './components/globals/NotificationMessage';
import {
  Socket,
  createSocket,
  heartbeat_msg,
  sendHeartbeat,
  sendUpdateLastAction,
  sendPruneChatParticipants,
  sendUpdateChatParticipants,
} from './api/expoHallSocket';
import EndofEventPage from './pages/EndofEventPage';
import LoadingPage from './pages/LoadingPage';
import Axios from 'axios';
import Countdown from 'react-countdown';

let heartbeat_interval,
  update_last_action_interval,
  prune_chat_participant_interval,
  update_general_chat_participant_interval,
  ping_interval = null;
let missedCounter = 0;
const App = ({ className }) => {
  const [exhibitorData, setExhibitorData] = useState([]);
  const History = useHistory();

  const [loadGeneralChat, setLoadGeneralChat] = useState(false);
  const [loadTileChat, setLoadTileChat] = useState(false);
  const [loadAuditoriumChat, setloadAuditoriumChat] = useState(false);
  const [missedHeartbeats, setMissedHeartbeats] = useState(0);
  const [attendantNavbarBackLink, setAttendantNavbarBackLink] = useState(
    '/auditorium'
  );
  const [pingData, setpingData] = useState();
  const [missedPings, setmissedPings] = useState(0);
  const {
    state: {
      generalChatMessages,
      generalChatParticipants,
      auditoriumChatMessages,
      auditoriumChatParticipants,
      tileChatMessages,
      tileChatParticipants,
    },
    setTileChatMessages,
    setTileChatParticipants,
    setGeneralChatMessages,
    setGeneralChatParticipants,
    setAuditoriumChatMessages,
    setAuditoriumChatParticipants,
    setViewsNumber,
    setCategories,
    setLoadTileChatContext,
    setConnection,
  } = useContext(SocketContext);

  const ping = new Ping({ timeout: 230 });
  const generalChatMessagesRef = useRef(generalChatMessages);
  const generalChatParticipantsRef = useRef(generalChatParticipants);
  const tileChatMessagesRef = useRef(tileChatMessages);
  const tileChatParticipantsRef = useRef(tileChatParticipants);
  const auditoriumChatMessagesRef = useRef(auditoriumChatMessages);
  const auditoriumChatParticipantsRef = useRef(auditoriumChatParticipants);

  useLayoutEffect(() => {
    document.title = SiteData.titleIndex;
  }, []);

  useEffect(() => {
    handleSocket();
  }, []);

  // useEffect(() => {
  //   handlePing();
  // }, []);

  // const handlePing = () => {
  //   ping_interval = setInterval(() => {
  //     ping.ping(
  //       'https://status.digitalvirtualspaces.com',
  //       (err, data) => {
  //         missedCounter++;
  //         if (err) {
  //           if (missedCounter >= 5) {
  //             clearInterval(ping_interval);
  //             handleDoomsday();
  //           }
  //         } else {
  //           missedCounter = 0;
  //           console.log('healthy');
  //         }
  //       }
  //     );
  //   }, 5500);
  // };

  // const handleDoomsday = async () => {
  //   const { data } = await Axios.get(
  //     'https://nabj.mbvsbackend.com/api/dooms_day/1/'
  //   );
  //   if (data) {
  //     switch (data.status) {
  //       case 'good':
  //         missedCounter = 0;
  //         handlePing();
  //         break;

  //       case 'bad':
  //         const notification = {
  //           title: 'Attention',
  //           message: `We are experiencing some technical difficulties, you will be moved to an alternative page. If you are not moved copy and paste the following link: redirect.digitalvirtualspaces.com`,
  //           type: 'danger',
  //           insert: 'top',
  //           container: 'top-center',
  //           duration: 60000,
  //         };
  //         NotificationFactory(notification);
  //         setTimeout(() => {
  //           window.open(
  //             'https://redirect.digitalvirtualspaces.com'
  //           );
  //           window.close();
  //         }, 60000);
  //         break;

  //       default:
  //     }
  //   }
  // };

  const handleHeartbeat = () => {
    setMissedHeartbeats(0);
    heartbeat_interval = setInterval(() => {
      try {
        setMissedHeartbeats((prevState) => prevState + 1);
        if (missedHeartbeats >= 3) {
          throw new Error('Too many missed heartbeats.');
        }
        sendHeartbeat();
      } catch (e) {
        clearInterval(heartbeat_interval);
        heartbeat_interval = null;
        console.warn('Closing connection. Reason: ' + e.message);
        Socket.close();
      }
    }, 15000);
  };

  const handleUpdateLastAction = () => {
    update_last_action_interval = setInterval(() => {
      sendUpdateLastAction();
    }, 30000);
  };

  const handlePruneChatParticpants = () => {
    prune_chat_participant_interval = setInterval(() => {
      sendPruneChatParticipants();
    }, 60000);
  };

  const handleUpdateGeneralChatParticipants = () => {
    update_general_chat_participant_interval = setInterval(() => {
      sendUpdateChatParticipants(SiteData.generalChatId);
    }, 60000);
  };

  const handleSocket = () => {
    createSocket();
    Socket.onmessage = (e) => {
      // console.log('message', e.data);
      socketActions(JSON.parse(e.data));
    };
    Socket.onopen = (e) => {
      console.log('open', e);
      setConnection(true);
      //handleHeartbeat();
      //handleUpdateLastAction();
      //handlePruneChatParticpants();
      //handleUpdateGeneralChatParticipants();
    };
    Socket.onerror = (e) => {
      console.log('error', e);
    };
    Socket.onclose = (e) => {
      setTimeout(() => handleSocket(), 1000);
      setConnection(false);
      console.log('close', e);
      // clearInterval(update_last_action_interval);
      // update_last_action_interval = null;
      // clearInterval(prune_chat_participant_interval);
      // prune_chat_participant_interval = null;
      // clearInterval(update_general_chat_participant_interval);
      // update_general_chat_participant_interval = null;
    };
  };

  const socketActions = (data) => {
    switch (data.type) {
      case 'loadAllChatMessages':
        if (data.chatid === 1) {
          setGeneralChatMessages(data.messages);
          setGeneralChatParticipants(data.participants);
          setLoadGeneralChat(true);
          generalChatMessagesRef.current = data.messages;
          generalChatParticipantsRef.current = data.participants;
        } else if (data.chatid == SiteData.auditoriumChatId) {
          setAuditoriumChatMessages(data.messages);
          setAuditoriumChatParticipants(data.participants);
          setloadAuditoriumChat(true);
          auditoriumChatMessagesRef.current = data.messages;
          auditoriumChatParticipantsRef.current = data.participants;
        } else {
          setTileChatMessages(data.messages);
          setTileChatParticipants(data.participants);
          setLoadTileChat(true);
          setLoadTileChatContext(true);
          tileChatMessagesRef.current = data.messages;
          tileChatParticipantsRef.current = data.participants;
        }
        break;

      case 'newParticipantChat':
        if (data.chatid === SiteData.generalChatId) {
          // generalChatParticipantsRef.current = generalChatParticipantsRef.current.concat(data.participants);
          // setGeneralChatParticipants(generalChatParticipantsRef.current);
          generalChatParticipantsRef.current = generalChatParticipantsRef.current.concat(
            data.participant
          );
          setGeneralChatParticipants(generalChatParticipantsRef.current);
        } else if (data.chatid === SiteData.auditoriumChatId) {
          auditoriumChatParticipantsRef.current = auditoriumChatParticipantsRef.current.concat(
            data.participant
          );
          setAuditoriumChatParticipants(auditoriumChatParticipantsRef.current);
        } else {
          tileChatParticipantsRef.current = tileChatParticipantsRef.current.concat(
            data.participant
          );
          setTileChatParticipants(tileChatParticipantsRef.current);
          //setTileChatParticipants(data.participants);
          if (data.views) {
            setViewsNumber(data.views);
          }
        }
        break;
      case 'removeParticipantChat':
        if (data.chatid === SiteData.generalChatId) {
          generalChatParticipantsRef.current = generalChatParticipantsRef.current.filter(
            (element) => data.participant.id !== element.id
          );
          setGeneralChatParticipants(generalChatParticipantsRef.current);
          // console.log(generalChatParticipantsRef.current)
          ///generalChatParticipantsRef.current = generalChatParticipantsRef.current.concat(data.participants);
          // setGeneralChatParticipants(generalChatParticipantsRef.current);
        } else if (data.chatid === SiteData.auditoriumChatId) {
          auditoriumChatParticipantsRef.current = auditoriumChatParticipantsRef.current.filter(
            (element) => data.participant.id !== element.id
          );
          setAuditoriumChatParticipants(auditoriumChatParticipantsRef.current);
        } else {
          tileChatParticipantsRef.current = tileChatParticipantsRef.current.filter(
            (element) => data.participant.id !== element.id
          );
          setTileChatParticipants(tileChatParticipantsRef.current);
        }
        break;
      case 'loadMoreChatMessages':
        if (data.chatid === 1) {
          generalChatMessagesRef.current = data.messages.concat(
            generalChatMessagesRef.current
          );
          setGeneralChatMessages(generalChatMessagesRef.current);
        } else if (data.chatid === SiteData.auditoriumChatId) {
          auditoriumChatMessagesRef.current = data.messages.concat(
            auditoriumChatMessagesRef.current
          );
          setAuditoriumChatMessages(auditoriumChatMessagesRef.current);
        } else {
          tileChatMessagesRef.current = data.messages.concat(
            tileChatMessagesRef.current
          );
          setTileChatMessages(tileChatMessagesRef.current);
        }
        break;

      case 'newChatMessage':
        if (data.chatid === 1) {
          generalChatMessagesRef.current = generalChatMessagesRef.current.concat(
            data.message
          );
          setGeneralChatMessages(generalChatMessagesRef.current);
        } else if (data.chatid === SiteData.auditoriumChatId) {
          auditoriumChatMessagesRef.current = auditoriumChatMessagesRef.current.concat(
            data.message
          );
          setAuditoriumChatMessages(auditoriumChatMessagesRef.current);
        } else {
          tileChatMessagesRef.current = tileChatMessagesRef.current.concat(
            data.message
          );
          setTileChatMessages(tileChatMessagesRef.current);
        }
        break;
      case 'toggleBooth':
        setCategories(data.categories);
        break;
      case 'heartbeat':
        if (heartbeat_msg === data.message) {
          setMissedHeartbeats(0);
        }
        break;
      case 'deleteMessage':
        if (data.chatid === 1) {
          const index = generalChatMessagesRef.current.findIndex(
            (element) => data.messageid === element.id
          );
          generalChatMessagesRef.current[index].delete = true;
          setGeneralChatMessages(generalChatMessagesRef.current);
        } else if (data.chatid === SiteData.auditoriumChatId) {
          const index = auditoriumChatMessagesRef.current.findIndex(
            (element) => data.messageid === element.id
          );
          auditoriumChatMessagesRef.current[index].delete = true;
          setAuditoriumChatMessages(auditoriumChatMessagesRef.current);
        } else {
          const index = tileChatMessagesRef.current.findIndex(
            (element) => data.messageid === element.id
          );
          tileChatMessagesRef.current[index].delete = true;
          setTileChatMessages(tileChatMessagesRef.current);
        }
        break;
      default:
      // code block
    }
  };

  return (
    <div className={className}>
      <GlobalStyles />
      <NotificationMessage />
      <UserProvider>
        <ExhibitorContext.Provider value={exhibitorData}>
          <AttendantProvider>
            <ClientProvider>
              <Router>
                <Switch>
                  <Route exact path="/">
                    {/* <Navbar /> */}
                    <SliderPage loadChat={loadGeneralChat} />
                  </Route>
                  {SiteData.auditorium && (
                    <Route exact path="/auditorium">
                      <AuditoriumPage />
                    </Route>
                  )}
                  {SiteData.auditorium && (
                    <Route exact path="/closing">
                      <EndofEventPage />
                    </Route>
                  )}
                  <Route path="/login">
                    {/* <LoginForm /> */}
                    <RegistrationForm />
                  </Route>
                  <Route path="/register">
                    {' '}
                    <Register />{' '}
                  </Route>
                  <Route path="/forgotpassword">
                    {' '}
                    <ForgotPassword />{' '}
                  </Route>
                  <Route path="/reset/:uuid/:token">
                    {' '}
                    <ResetPassword />{' '}
                  </Route>
                  <Route exact path="/dashboard">
                    <NavbarAttendant
                      attendantNavbarBackLink={attendantNavbarBackLink}
                    />
                    <AttendantDashboard
                      setAttendantNavbarBackLink={setAttendantNavbarBackLink}
                      loadGeneralChat={loadGeneralChat}
                      loadTileChat={loadTileChat}
                    />
                  </Route>
                  <Route exact path="/editor">
                    <NavbarAttendant
                      attendantNavbarBackLink={attendantNavbarBackLink}
                    />
                    <AttendantTileEditor />
                  </Route>
                  {/*<Route exact path="/tabs">*/}
                  {/*<NavbarAttendant*/}
                  {/*attendantNavbarBackLink={attendantNavbarBackLink}*/}
                  {/*/>*/}
                  {/*<TabConsole />*/}
                  {/*</Route>*/}
                  {/*<Route exact path="/attendantlogin">*/}
                  {/*<AttendantLogin />*/}
                  {/*</Route>*/}
                  <Route exact path="/client">
                    <NavbarClient />
                    <ClientDashboard />
                  </Route>
                  <Route path="/clientlogin">
                    <ClientLogin />
                  </Route>
                  <Route
                    exact
                    path="/loaderio-faafa801558db05beec16c6856f2516e"
                  />
                  <Route path="*" render={(props) => <div>Not found!</div>} />
                </Switch>
              </Router>
            </ClientProvider>
          </AttendantProvider>
        </ExhibitorContext.Provider>
      </UserProvider>
    </div>
  );
};

export default styled(App)`
  height: 100%;
  width: 100%;
`;
