import contextFactory from './contextFactory'

const clientReducer = (state, action) => {
	switch (action.type) {
		case 'set_client':
			return { ...state, client: action.payload }
		default:
			return state
	}
}

const setClient = {
	setClient: dispatch => client => {
		dispatch({ type: 'set_client', payload: client })
	},
}

export const { Context, Provider } = contextFactory(
	clientReducer,
	{ ...setClient },
	{}
)

