import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import expoHallAPI from '../../api/expoHallAPI';
import { Context as AttendantContext } from '../../context/AttendantContext';

import TileEditorForm from './TileEditorForm';
import TileEditorPreview from './TileEditorPreview';
import TabConsole from './TabConsole'

import styled from 'styled-components';
import LoadingPage from '../../pages/LoadingPage';
import { Context as UserContext } from '../../context/UserContext';

const AttendantTileEditor = ({ className }) => {
  const { state: { attendant,booth } = {}, setAttendant,setBooth } = useContext(
    AttendantContext
  );
  const {
    state: { user },
    setUser,
  } = useContext(UserContext);
  const [tabs, setTabs] = useState([]);
  const history = useHistory();
  const [loadBooth, setLoadBooth] = useState(false);
  const [boothData, setBoothData] = useState({});

  useEffect(() => {
    document.title = 'Edit your booth';
    fetchUser();
  }, []);

  useEffect(()=> {
    if(attendant){
      fetchBoothData();
    }
  }, [attendant])

  const fetchUser = async () => {

    try {
      const token = localStorage.getItem('shrm-li-expo-attendant');
      expoHallAPI.defaults.headers.common['Authorization'] = "Bearer "+token;
      const { data } = await expoHallAPI.get('/attendant/profile/');
      // console.log(data);
      if (data) {
        setAttendant(data);

        setUser(data);
      }
    } catch (error) {
      expoHallAPI.defaults.headers.common['Authorization'] = "";
      localStorage.removeItem("shrm-li-expo-attendant");
      history.push('/login');
      setAttendant(undefined)
    }
  };

  const fetchBoothData = async () => {
    try {
      let { data } = await expoHallAPI.get(`/api/tiles/${attendant.id}/`);
      console.log(data);
      data.group_id = data.group_id.id;
      // console.log('DATA IN TILE EDITOR', data);
      setLoadBooth(true);
      setTabs(data.tabs);
      setBooth(data);
      setBoothData(data);
    } catch (error) {

    }
  };

  if (!loadBooth) {
    return <LoadingPage />;
  }else {
    return (
      <div className={className}>
        <h4>Tile Editor</h4>
        <div className="editor-container">
          <TileEditorPreview data={boothData}/>
          <TileEditorForm data={boothData}/>
        </div>
        <TabConsole tabs={tabs} setTabs={setTabs}/>
      </div>
    );
  }
};

export default styled(AttendantTileEditor)`

  h4 {
    text-align: center;
    margin-top: 10rem;
  }
  h5{
    text-align: center;
    margin-top: 5rem;
  }

  .editor-container {
    padding: 0 10%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
    justify-content: space-evenly;
    grid-gap: 2rem;
  }
`;
