import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { setBoxShadow, setColor } from '../../styles/styles';
import SendIcon from '@material-ui/icons/Send';
import ChatMessage from './ChatMessage';
import Chatuser from './Chatuser';
import LoadingIcon from '../sliderItem/loadingIcon';

const Smallchatbox = ({
  className,
  messages,
  participants,
  sendMessage,
  retrieveMoreMessages,
  loadingMoreMessages,
  setLoadingMoreMessages,
  AttendantDashboard,
  deleteMessage,
}) => {
  const [Chat, setChat] = useState(true);
  const [Members, setMembers] = useState(false);
  const [Room, setRoom] = useState(false);
  const [inputMessage, setinputMessage] = useState('');
  const [currentPage, setcurrentPage] = useState(1);
  const [scrollHeightMessages, setScrollHeightMessages] = useState(1);
  const messagesRef = useRef();

  useEffect(() => {
    // Scrolls down on chat when message loads
    if (Chat && !loadingMoreMessages) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
    if (
      Chat &&
      loadingMoreMessages &&
      messagesRef.current.scrollHeight !== scrollHeightMessages
    ) {
      messagesRef.current.scrollTop =
        messagesRef.current.scrollHeight - scrollHeightMessages;
    }
    setLoadingMoreMessages(false);
  }, [messages]);

  useEffect(() => {
    // Scrolls down on chat when message loads
    if (Chat) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [Chat]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputMessage) {
      sendMessage(inputMessage);
      setinputMessage('');
    }
  };
  const handleMessageSend = () => {
    if (inputMessage) {
      sendMessage(inputMessage);
      setinputMessage('');
    }
  };
  const handlescroll = (event) => {
    // console.log(event);
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    // console.log('scrollTop', scrollTop);
    // console.log('clientHeight', clientHeight);
    // console.log('scrollHeight', scrollHeight);

    if (scrollTop === 0 && scrollHeightMessages !== scrollHeight) {
      retrieveMoreMessages(currentPage + 1);
      setcurrentPage(currentPage + 1);
      setScrollHeightMessages(scrollHeight);
    }
  };
  return (
    <div className={className}>
      {/* {console.log(messages)} */}
      <div style={{ height: '100%' }} className="Smallchatbox">
        <div className="Smallchatbox_header">
          <button
            style={{
              backgroundColor: Chat ? setColor.midnightBlue : 'white',
              color: Chat ? 'white' : setColor.midnightBlue,
            }}
            onClick={() => {
              setChat(true);
              setMembers(false);
              setRoom(false);
            }}
          >
            Chat
          </button>
          <button
            style={{
              backgroundColor: Members ? setColor.midnightBlue : 'white',
              color: Members ? 'white' : setColor.midnightBlue,
            }}
            onClick={() => {
              setChat(false);
              setMembers(true);
              setRoom(false);
            }}
          >
            Participants ({participants?.length})<div></div>
          </button>
          {/* <button
            onClick={() => {
              setChat(false);
              setMembers(false);
              setRoom(true);
            }}
          >
            Rooms
          </button> */}
        </div>
        <br />
        {Members && (
          <div className="Smallchatbox_main">
            <div className="Smallchatbox_member">
              {/* {participants.map((participant) => ( */}
              <Chatuser title={false} participants={participants} />
              {/* <p>{participant.first_name + ' ' + participant.last_name}</p> */}
            </div>
          </div>
        )}
        {Chat && (
          <div
            onScroll={handlescroll}
            className="Smallchatbox_main"
            ref={messagesRef}
          >
            <div className="Smallchatbox_chat">
              {loadingMoreMessages && <LoadingIcon />}
              {messages.map((message) => (
                <ChatMessage
                  key={message.id}
                  message={message}
                  deleteMessage={deleteMessage}
                />
              ))}
            </div>
          </div>
        )}

        {!Members && (
          <div className="Smallchatbox_footer">
            <input
              value={inputMessage}
              type="text"
              placeholder="Type a message....."
              onChange={(e) => {
                setinputMessage(e.target.value);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <SendIcon
              onClick={handleMessageSend}
              className="Smallchatbox_sendbutton"
              //   onClick={handleMessageSend}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default styled(Smallchatbox)`
  height: 100%;
  position: relative;

  border-radius: 10px;
  .Smallchatbox_header {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    position: absolute;
    /* margin-bottom: 3rem; */
    top: -1px;
    z-index: 3;
    padding-top: 10px;

    button {
      padding: 0.5rem 2rem;
      border-radius: 3px;
      border: none;
      outline: none;
      margin-right: 15px;
      cursor: pointer;

      div {
        height: 3px;

        background-color: ${setColor.midnightBlue};
        display: none;
        justify-content: center;
      }
      :hover {
        div {
          display: flex;
          animation-name: example;
          animation-duration: 1s;
          @keyframes example {
            from {
              width: 0%;
            }
            to {
              width: 100%;
            }
          }
        }
      }
      @media only screen and (max-width: 1200px) {
        font-size: 9px;
      }
      @media only screen and (max-width: 1100px) {
        font-size: 10px;
      }
    }
    #member_count {
      background: red;
      border-radius: 3px;
      padding: 3px;
      color: white;
      height: 20px;
      width: 20px;
    }
  }
  .Smallchatbox_main {
    height: 90%;
    background: white;
    overflow-y: auto;
    padding-top: 3rem;
    .Smallchatbox_chat {
      padding-top: 1rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      margin-bottom: 3rem;
    }
    .Smallchatbox_member {
      padding-bottom: 2rem;
    }
  }
  .Smallchatbox_footer {
    position: absolute;
    bottom: ${({ AttendantDashboard }) =>
      AttendantDashboard ? '0px' : '50px'};
    background: ghostwhite;
    width: 100%;
    padding: 5px 0px;
    input {
      width: 100%;
      border: none;
      border-radius: 5px;
      margin-right: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 5px;
      padding-right: 30px;
      :focus {
        border: none;
        outline: none;
      }
    }
    .Smallchatbox_sendbutton {
      color: ${setColor.midnightBlue};
      position: absolute;
      cursor: pointer;
      bottom: 13px;
      right: 7px;
      font-size: 20px;
      border-left: 2px solid ${setColor.lightGrey};
    }
  }
`;
