import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import styled from 'styled-components';
import { setColor } from '../../styles/styles';
import { Context as UserContext } from '../../context/UserContext';
import expoHallAPI from '../../api/expoHallAPI';
import { NotificationFactory } from '../globals/NotificationMessage';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import 'date-fns';
import { Context as AttendantContext } from '../../context/AttendantContext';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const TabCreatorForm = ({ className, addTab,tab }) => {

  const { state, state: { attendant,booth } = {}, setAttendant,setBooth } = useContext(
    AttendantContext
  );


  const classes = useStyles();

  const {state: {user}} = useContext(UserContext);

  const [tabType, setTabType] = useState('PDF');
  const [tabName, setTabName] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('');
  const [fileUploadChecker, setFileUploadChecker] = useState(false);
  const [url, setURL] = useState('');

  const inputRef = useRef();


  const handleSubmit = async (event,ref) => {
    event.preventDefault();
    let notification = {
      title: "Success",
      message: "Tab Created",
      type: 'success',
      insert: 'top',
      container: 'top-right',
      duration: 2000
    };
    try{
      const {data} = await expoHallAPI.post(`/api/tabs/`, {
        tab_type: tabType,
        name: tabName,
        tile: booth.id,
        sub_header: description,
        url: url,
        priority,
      });
      const formData = new FormData();
      if (ref.current.files[0]) {
        if(tabType === 'PDF'){
          formData.append('pdf_file', ref.current.files[0]);
        }
        if(tabType === 'URL'){
          formData.append('image', ref.current.files[0]);
        }
        const responseTab = await expoHallAPI.put(`/api/tabs/${data.id}/`, formData)
        addTab(responseTab.data)
      }
      NotificationFactory(notification);
      console.log(data)
    } catch(error){

      console.log('ERROR', error);

    }
  };
  const onFileChange = event =>{
    if(event.target.files[0]){
      setFileUploadChecker(true)
    }else{
      setFileUploadChecker(false)
    }
  };
  console.log(tab)

  return (
    <div className={className}>
      <div className="request_container">
        <h6>Create Tab</h6>
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <div className="request_name">
            <TextField
              id="tab-type"
              select
              label="Select Tab Type"
              name="tab-type"
              value={tabType}
              onChange={(event) => setTabType(event.target.value)}
              error={tabType === ''}
              helperText={tabType === '' ? 'Required' : ' '}
              variant="filled"
              style={{
                marginRight: '10px',
              }}
            >
              <MenuItem key="PDF" value="PDF">
                PDF
              </MenuItem>
              <MenuItem key="URL" value="URL">
                Website
              </MenuItem>
            </TextField>
            <TextField
              error={tabName === ''}
              helperText={tabName === '' ? 'Required' : ' '}
              placeholder="Tab Name"
              label="Tab Name"
              variant="filled"
              id="tab-name"
              name="tab-name"
              value={tabName}
              onChange={(event) => {
                setTabName(event.target.value);
              }}
            />
          </div>

          <TextField
            id="description"
            label="Sub Header"
            variant="filled"
            type="text"
            placeholder="Sub Header"
            name="description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
          <TextField
            error={priority === ''}
            helperText={priority === '' ? 'Required' : ' '}
            id="priority"
            placeholder="pick a number"
            label="Tab priority (order of appearance)"
            variant="filled"
            type="number"
            name="priority"
            value={priority}
            onChange={(event) => {
              setPriority(event.target.value);
            }}
          />
          {tabType === 'URL'
            ?
            <div className="request_name">
              <TextField
                error={url === ''}
                helperText={url === '' ? 'Required' : ' '}
                type="url"
                pattern="http://.*"
                id="url"
                placeholder="url"
                value={url}
                onChange={(event) => setURL(event.target.value)}
                label="Website URL"
                variant="filled"
                name="company"
                style={{
                  marginRight: '10px',
                }}
              />
              <div className="form-input-group">
                <label htmlFor="data" className="form-label-side">
              Upload {tabType === 'PDF' ? 'PDF' : 'Picture'}
              </label>
              <input
              type="file"
              ref={inputRef}
              id="data"
              accept={tabType === 'PDF' ? 'application/pdf' : 'image/*'}
              onChange={onFileChange}
              />
              </div>
            </div>
            :
            <div className="form-input-group">
              <label htmlFor="data" className="form-label">
                Upload {tabType === 'PDF' ? 'PDF' : 'Picture'}
                <span className="required">&nbsp;required</span>
              </label>
              <input
                type="file"
                ref={inputRef}
                id="data"
                accept={tabType === 'PDF' ? 'application/pdf' : 'image/*'}
                onChange={onFileChange}
              />
            </div>}
          <button
            className="request_register"
            type="submit"
            disabled={(tabType === 'URL' && !url)|| !tabType || !priority || !tabName || (tabType !== 'URL' && !fileUploadChecker)}
            onClick={(e)=>handleSubmit(e,inputRef)}
          >
            Create
          </button>
        </form>
      </div>
    </div>
  );
};

export default styled(TabCreatorForm)`
  height: 100%;
  width: 100%;
  color: #d1d1d1;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 2rem;
  font-weight: 300;
  overflow-y: auto;
  .request_container {
    margin-bottom: 3rem;
    background: white;
  }
  .request_container h6 {
    color: ${setColor.secondaryBlue};
    text-align: center;
  }
  .request_timeslot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .request_name {
    display: flex;
  }

  .request_name .MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 80%;
  }
  .meeting_available {
    margin-bottom: 2rem;
    color: ${setColor.secondaryBlue};
  }
  .request_textarea {
    background-color: inherit;
    color: black;
    font-family: inherit;
    padding: 1rem 2rem;
    /* border-radius: 2rem; */
    border: 1px solid;
    outline: none;
    border-color: grey;
    resize: none;
    border-radius: 0;
  }
  .request_datepicker label {
    font-size: 14px;
    top: -1px;
  }
  .request_register {
    background: ${setColor.secondaryBlue};
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 3px;
    transition: all ease 0.3s;
    color: white;
  }
  .request_register:hover {
    background: ${setColor.tertiaryBlue};
    color: white;
    font-size: bold;
  }
  label + .MuiInput-formControl {
    margin-top: 0 !important;
  }
  .request_date {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .request_date .MuiFormHelperText-root.Mui-error {
    color: #171616;
    font-size: 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    align-items: flex-start;
    width: 100%;
  }

  h4 {
    text-align: center;
    color: ${setColor.mainGrey};
  }
  
  .form-input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .form-label {
    margin: 1rem 0;
    color: ${setColor.primaryBlue};
    font-size: 1.6rem;
  }
  
  .form-label-side {
    color: ${setColor.primaryBlue};
    font-size: 1.6rem;
  }

  .required {
    font-style: italic;
    font-size: 1.4rem;
    color: rgb(186, 57, 45);
  }
`;
