import styled, {css} from 'styled-components'
import { setColor } from '../../styles/styles'

export const UploaderButton = styled.button`
	border-radius: 100rem;
	padding: .5rem 1rem;
	font-family: inherit;
	font-size: 1rem;
	color: ${setColor.primaryBlue};
	border: solid 2px ${setColor.primaryBlue};
    transition: all .2s;
    outline: none;
    backface-visibility: hidden;
	margin: 1rem 0;
	/* disable highlight */
	user-select: none; 
	position: relative;
    background-color: transparent;

	:hover {
		box-shadow: none;
		color: white; 
        transition: all .4s;
		transform: scale(1.05);
        
        backface-visibility: hidden;
	}
`

export default UploaderButton