import React, { useState, useEffect, useContext } from 'react';

import ReactModal from 'react-modal';

import styled, { keyframes } from 'styled-components';
import { CloseIcon } from '../../styles/icons';

const Modal =
  ({ closeModal,
     className,
     modalIsOpen,
     component,
     modalStyle
   }) => {
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      zIndex: 900,
    },
    content: {
      height: modalStyle.height,
      width: modalStyle.width,
      transform: 'translate(-50%, -50%)',
      top: modalStyle.top,
      left: modalStyle.left,
      right: 'auto',
      bottom: 'auto',
      borderRadius: 0,
      borderWidth: 0,
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      backgroundColor: 'white',

      padding: 0,
      backfaceVisibility: 'hidden',
      boxShadow: '0px 0px 48px 0px rgba(0,0,0,0.4)',
      overflow: 'hidden',
      zIndex: 1000,
    },
  };


  return (
    <ReactModal
      isOpen={modalIsOpen}
      style={customStyles}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <div className={className}>
        <div
          onClick={() => {
            closeModal();
          }}
          className="close-icon"
        >
          <CloseIcon />
        </div>
      <div>
        {component}
      </div>
      </div>
    </ReactModal>
  );
};

export default styled(Modal)`

  height: 100%;
  width: 100%;
  
.close-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 15px;
    right: 1%;
    z-index: 10;
  }
 
`;
