import React, { useContext, useState, useEffect } from 'react';

import { useLocation, useHistory, NavLink } from 'react-router-dom';

import styled from 'styled-components';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutButton from './LogoutButton';

import { Context as UserContext } from '../../context/UserContext';

import { BackArrow, CloseIcon } from '../../styles/icons';

import SiteData from '../../config';

import { setColor, sizes } from '../../styles/styles';
import { useWindowScroll } from 'beautiful-react-hooks';
import expoHallAPI from '../../api/expoHallAPI';
import ReactModal from 'react-modal';
import Button from './Button';
import GeneralChat from './GeneralChat';
import { Context as AttendantContext } from '../../context/AttendantContext';
import { Socket } from '../../api/expoHallSocket';

const NavDiv = styled.div`
  height: ${(props) => (props.scrollY ? '5rem' : '7rem')};
  transition: height 0.2s;
`;

const Navbar = ({ className }) => {
  const {
    state: { user },
    setUser,
  } = useContext(UserContext);

  const { state: { attendant = undefined } = {}, setAttendant } = useContext(
    AttendantContext
  );

  useEffect(() => {
    // console.log('USER', user, 'ATTENDANT', attendant);
  }, [user, attendant]);

  const location = useLocation();
  const history = useHistory();
  const [scrollY, setScrollY] = useState(0);

  useWindowScroll(() => setScrollY(window.scrollY));

  return (
    <NavDiv className={className} scrollY={scrollY}>
      <div
        className="back-button"
        onClick={() => {
          if (attendant?.tile) {
            history.push('/dashboard');
          }
        }}
      >
        {attendant?.tile && <BackArrow />}
        <div className="caption">
          {attendant?.tile ? 'Back To Event Console' : ''}
        </div>
      </div>
      <div className="title">{SiteData.attendeeTitle}</div>
      <div className="greeting">
        {/*{user &&*/}
        {/*<nav>*/}
        {/*/!*<NavLink to="/tabs">Tab Manager</NavLink>*!/*/}
        {/*</nav>*/}
        {/*}*/}
        {/*<div className="settings">*/}
        {/*<SettingsIcon />*/}
        {/*</div>*/}
        <div className="greeting-box">
          <div className="greeting-name">
            Welcome{user?.first_name ? `, ${user?.first_name}` : ''}!
          </div>
          <div className="greeting-email">
            {user?.email}{' '}
            {user?.screen_name !== 'None' &&
              user?.screen_name &&
              '(Screen Name: ' + user?.screen_name + ')'}
          </div>
        </div>
        {location.pathname === '/' && (
          <LogoutButton
            onClick={() => {
              expoHallAPI.defaults.headers.common['Authorization'] = '';
              history.push('/login');
              localStorage.removeItem('shrm-li-expo-user');
              setUser(undefined);
              setAttendant(undefined);
              localStorage.removeItem('shrm-li-expo-attendant');
            }}
          >
            Logout
          </LogoutButton>
        )}
      </div>
    </NavDiv>
  );
};

export default styled(Navbar)`
  display: flex;
  justify-content: space-between;
  position: static;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  padding: 2rem;
  align-items: center;
  position: fixed;
  width: 80%;

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  nav {
    div {
      text-decoration: none;
      font-family: inherit;
      font-size: 2rem;
      color: white;
      transition: all 0.2s;

      border-right: 2px solid rgba(100, 100, 100, 0.3);
      padding: 0.5rem 1rem;

      :last-child {
        margin-right: 1rem;
      }

      &:hover {
        color: ${setColor.primaryBlue};
        filter: brightness(110%);
        transition: all 0.2s;
        cursor: pointer;
      }
    }

    .active {
      color: ${setColor.primaryBlue};
    }
  }
  .settings {
    cursor: pointer;
  }
  .greeting {
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    .MuiSvgIcon-root {
      cursor: pointer;
    }
  }

  .greeting-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .svg {
      cursor: pointer;
    }
  }

  .greeting-name {
  }

  .greeting-email {
    font-size: 1.2rem;
    font-weight: 300;
  }

  .title {
    font-size: 2rem;
    /* position: absolute; */
    /* text-align: center; */
    /* left: 0; */
    /* right: 0; */
    /* display: flex;
    justify-content: center;
    width: 70%; */

    @media screen and (max-width: ${sizes.desktop}px) {
      visibility: hidden;
    }
  }

  .caption {
    font-size: 2rem;
    opacity: 1;
    margin-left: 1rem;
  }

  svg {
    height: 3rem;
    width: 3rem;
    display: block;
    margin: 0 10px;
    opacity: 1;
    stroke-width: 3px;
    stroke: none;
    cursor: pointer;
  }

  .back-button {
    fill: white;
    transition: all 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 30;

    :hover {
      cursor: pointer;
      svg {
        transform: translateX(-5px);
        transition: all 0.2s;
      }
      transition: all 0.2s;
    }
  }
`;
