import React from 'react';
import styled from 'styled-components';
import { setBoxShadow, setColor } from '../../styles/styles';
import { CloseIcon } from '../../styles/icons';

const ContainerNotification = styled.div`
  position: fixed;
  top: ${(props) => (props.open ? '10px' : '0')};
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  min-height: 100px;
  max-width: 700px;
  /* width: 100%; */
  min-width: 45rem;
  background-color: #fff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  z-index: 2001;
  border-radius: 3px;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: all 1s cubic-bezier(1, -0.01, 0.06, 0.7), visibility 1s linear;
`;

const CloseIconContainer = styled.div`
  height: 15px;
  width: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
`;

const TitleContainer = styled.div`
  color: #000;
  font-size: 24px;
  padding: 5px 30px;
  font-weight: bold;
  font-family: inherit;
  margin-top: 20px;
`;

const DescriptionContainer = styled.div`
  color: #000;
  font-size: 18px;
  padding: 5px 20px;
  white-space: pre-line;
  font-family: inherit;
  margin-bottom: 20px;
  font-family: inherit;
`;

const ButtonContainer = styled.div`
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end;

  padding: 10px 10px;
  background-color: #d3d3d347;
`;

const ButtonConfirm = styled.button`
  background-color: ${setColor.matchBoxRed};
  width: fit-content;
  /* border: 1px solid #848383; */
  border: none;
  padding: 0.7rem 1.5rem;
  border-radius: 3px;
  transition: all ease 0.3s;
  color: white;
  :hover {
    box-shadow: 2px 1000px 1px #8c2222 inset;
    cursor: pointer;
  }
`;
export const CustomNotification = ({
  open,
  title,
  description,
  buttonText,
  onButtonClick,
  onCloseIconClick,
  time,
}) => {
  return (
    <ContainerNotification open={open}>
      {!buttonText && (
        <CloseIconContainer onClick={onCloseIconClick}>
          <CloseIcon />
        </CloseIconContainer>
      )}
      <TitleContainer>{title}</TitleContainer>
      <DescriptionContainer>
        {description} {time && time}
      </DescriptionContainer>
      <ButtonContainer>
        {buttonText && (
          <ButtonConfirm onClick={(e) => onButtonClick()}>
            {buttonText}
          </ButtonConfirm>
        )}
      </ButtonContainer>
    </ContainerNotification>
  );
};
