import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';


const LoadingPage = ({ className }) => {




  return (
    <div className={className}>
      <div className="loader three"></div>
      {/*<div className={"circle"}></div>*/}
    </div>
  );
}

export default styled(LoadingPage)`

  display: flex;
  justify-content: center;
  height: 100vh;
  
  .loader {
  align-self: center;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 10px;
}

.three {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: solid transparent 10px;
  border-top: solid #ff0000 10px;
  border-left: solid #ff0000 10px;
  animation: loader3Animation 2s linear infinite;
}
.three:before {
  position: absolute;
  top: 20px;
  left: 20px;
  content: '';
  width: 40px;
  height: 40px;
  border: solid transparent 10px;
  border-top: solid #990000 10px;
  border-left: solid #990000 10px;
  border-radius: 50%;
  animation: loader3AnimationBefore 1.5s linear infinite;
}
.three:after {
  position: absolute;
  top: 5px;
  left: 5px;
  content: '';
  width: 70px;
  height: 70px;
  border: solid transparent 10px;
  border-top: solid #cc0000 10px;
  border-left: solid #cc0000 10px;
  border-radius: 50%;
  animation: loader3AnimationAfter 1.5s linear infinite;
}
@keyframes loader3Animation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes loader3AnimationBefore {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes loader3AnimationAfter {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(-360deg); }
}


.circle {
  align-self: center;
  border-radius: 50px;
  border-right-color: rgb(153, 0, 0);
  border-top-color: rgb(204, 0, 0);
  border-bottom-color: rgb(153, 0, 0);
  border-left-color: rgb(255, 0, 0);
  border-width: 50px;
  border-style: solid;
  height: 0px;
  width: 0px;
  position: relative;
  transform: rotate(0deg);
}

.circle::before {
  display: block;
  content: '';
  position: absolute;
  left: -50px;
  top: -50px;
  border-radius: 50px;
  border-right-color: transparent;
  border-top-color: rgb(204, 0, 0);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-width: 50px;
  border-style: solid;
  height: 0px;
  width: 0px;
  transform: rotate(15deg);
}

.circle::after {
  display: block;
  content: '';
  position: absolute;
  left: -50px;
  top: -50px;
  border-radius: 50px;
  border-right-color: transparent;
  border-top-color: rgb(255, 0, 0);
  border-bottom-color: transparent;
  border-left-color: rgb(255, 0, 0);
  border-width: 50px;
  border-style: solid;
  height: 0px;
  width: 0px;
  transform: rotate(-30deg);
}
  
`;
