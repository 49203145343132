import LoginImage from './assets/NABJ-logo.png';
import MatchboxIcon from './assets/favicon.ico';
import MatchboxLogo from './assets/matchbox-logo-white-small.png';
import dummyVideos from './data/dummyVideos';
import bannerImage from './assets/NABJ_BG_V2.jpg';

const SiteData = {
  loginTitle: 'Welcome to NABJ 2020 Virtual Awards!',
  loginImagesrc: LoginImage,
  loginImagealt: 'NABJ Logo',
  attendeeTitle: 'NABJ 2020 Virtual Awards',
  matchboxLogo: MatchboxIcon,
  matchboxIcon: MatchboxLogo,
  endOfEventPageTitle: 'NABJ 2020 Virtual Awards',
  matchboxLogoAlt: 'matchbox logo',
  matchboxLogoTitle: 'hello',
  attendantTitle: 'NABJ Event Console',
  titleIndex: 'NABJ 2020',
  auditorium: true,
  checkRegisterList: false,
  generalChatId: 1,
  auditoriumChatId: 8,
  schemaName: 'nabj',
  Isbanner: true,
  bannerData: {
    backgroundURL: bannerImage,
    videoURL: 'https://expohallimages.s3-us-west-2.amazonaws.com/STE+Pre-Show.mp4',
  },
};
export default SiteData;
