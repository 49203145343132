import React from 'react';

import styled from 'styled-components';

const AttendantDashboardTile = ({ className, children, title, subtitle }) => {
  return (
    <div className={className}>
      <h5>{title}</h5>
      <p className="subTitle">{subtitle}</p>
      <div className="container">{children}</div>
    </div>
  );
};

export default styled(AttendantDashboardTile)`
  padding: 1rem 2rem;
  height: 50rem;

  display: flex;
  flex-direction: column;

  .container {
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
  }

  .subTitle {
    font-size: 1rem;
  }
`;
