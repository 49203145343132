import styled from 'styled-components';
import { setColor } from '../../styles/styles';

export const ChatInput = styled.input`
  width: 100%;
  height: 100%;
  padding-left: 2%;
  :focus {
    border-color: ${setColor.secondaryGrey};
  }
  border-radius: 5px;
`;

export default ChatInput;
