import styled, {css} from 'styled-components'
import { setColor } from '../../styles/styles'

export const Button = styled.button`
  width: 16rem;
	border-radius: 100rem;
	padding: 1.5rem 2rem;
	font-family: inherit;
	font-size: 2rem;
	padding: 0.5rem 3rem;
	color: ${setColor.primaryBlue};
	/* box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5); */
	border: solid 2px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(101deg, ${setColor.primaryBlue}, ${setColor.secondaryBlue});	
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px ${setColor.mainGrey} inset;
    transition: all .2s;
    outline: none;
    backface-visibility: hidden;
	margin: 1rem 0;
	/* disable highlight */
	user-select: none; 
	position: relative;
	cursor: ${props=> props.disabled ? 'not-allowed': "default"};

	:hover {
		box-shadow: none;
		color: white; 
        transition: all .4s;
		transform: scale(1.05);
        
        backface-visibility: hidden;
	}
`

export default Button