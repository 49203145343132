import React, { useContext, useEffect, useState } from 'react';
import TabForm from './TabForm';

import styled from 'styled-components';
import expoHallAPI from '../../api/expoHallAPI';
import { DetailsButton } from '../globals/DetailsButton';
import AttendantDashboardTile from './AttendantDashboardTile';
import { Context as AttendantContext } from '../../context/AttendantContext';
import LoadingPage from '../../pages/LoadingPage';
import { useHistory } from 'react-router-dom';
import {Button} from '../globals/Button';
import {DeleteButton} from '../globals/DeleteButton';
import Modal from '../globals/Modal'
import TabEditorForm from './TabEditorForm'
import TabCreatorForm from './TabCreatorForm'
import TabDeleteForm from './TabDeleteForm'
import {setColor} from '../../styles/styles';

const TabConsole = ({ className ,tabs, setTabs}) => {
  const [modalTabEditor, setModalTabEditor] = useState(false);
  const [modalTabCreator, setModalTabCreator] = useState(false);
  const [modalTabDelete, setModalTabDelete] = useState(false);
  const [tabData, setTabData] = useState({});
  const [heightEditModal, setHeightEditModal] = useState('380');
  const {
    state,
    state: { booth, attendant = {} } = {},
    setBooth,
    setAttendant,
  } = useContext(AttendantContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const modalStyleCreate={
    height: '400px',
    width: '500px',
    top: '40%',
    left: '50%',
  };

  const modalStyleEdit={
    height: `${heightEditModal}px`,
    width: '500px',
    top: '40%',
    left: '50%',
  };

  const modalStyleDelete={
    height: '120px',
    width: '500px',
    top: '45%',
    left: '50%',
  };

  const handleCreateTab=(tab)=>{
    const array = tabs;
    array.push(tab);
    setTabs(array);
    const dataBooth = booth;
    dataBooth.tabs=array;
    setBooth(dataBooth)
    setModalTabCreator(false)
  };

  const handleEditTab=(tab)=>{
    const array = tabs;
    const index = array.findIndex(x => x.id === tab.id);
    array[index] = tab;
    setTabs(array);
    const dataBooth = booth;
    dataBooth.tabs=array;
    setBooth(dataBooth)
    setModalTabEditor(false)
  };

  const handleDeleteTab=(tab)=>{
    const array = tabs.filter(x => x.id !== tab.id);
    setTabs(array);
    const dataBooth = booth;
    dataBooth.tabs=array;
    setBooth(dataBooth)
    setModalTabDelete(false)
  };
    return (
      <div className={className}>
        <Modal modalStyle={modalStyleDelete} component={<TabDeleteForm tab={tabData} deleteTab={handleDeleteTab} />} modalIsOpen={modalTabDelete} closeModal={()=>setModalTabDelete(false)}/>
        <Modal modalStyle={modalStyleCreate} component={<TabCreatorForm addTab={handleCreateTab}/>} modalIsOpen={modalTabCreator} closeModal={()=>setModalTabCreator(false)}/>
        <Modal modalStyle={modalStyleEdit} component={<TabEditorForm editTab={handleEditTab} setHeightEditModal={(value)=>setHeightEditModal(value)} tab={tabData}/>} modalIsOpen={modalTabEditor} closeModal={()=>setModalTabEditor(false)}/>
        <AttendantDashboardTile title="Tabs">
          <div className='create-tab-container'>
            <Button onClick={()=>setModalTabCreator(true)}>
              Create Tab
            </Button>
          </div>
          <div className="table">
            <div className="row">
              <div className="nameTabs row-data">Name</div>
              <div className="first-nameTabs row-data">Type</div>
              <div className="last-nameTabs row-data">Priority Number</div>
              <div className="editTabs row-data"></div>
              <div className="deleteTabs row-data"></div>
            </div>
            <div className="data-columns">
              {tabs.map((tab, index) => {
                return (
                  <div
                    className={'row'}
                    key={index}
                  >
                    <div className="nameTabs row-data">{tab.name}</div>
                    <div className="first-nameTabs row-data">
                      {tab.tab_type}
                    </div>
                    <div className="last-nameTabs row-data">
                      {tab.priority}
                    </div>
                    <div className="editTabs row-data details_button">
                      <DetailsButton
                        onClick={()=>{
                          setModalTabEditor(true)
                          setTabData(tab)
                          if(tab.tab_type === 'URL') setHeightEditModal('460');
                          if(tab.tab_type === 'PDF') setHeightEditModal('380');
                        }}
                      >
                        Edit
                      </DetailsButton>
                    </div>
                    <div className="deleteTabs row-data details_button">
                      <DeleteButton
                        onClick={()=>{
                          setModalTabDelete(true)
                          setTabData(tab)
                        }}
                      >
                        Delete
                      </DeleteButton>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </AttendantDashboardTile>
        {/*<TabForm />*/}
      </div>
    );
};

export default styled(TabConsole)`
  
  .table {
    height: 100%;
  }
  
  .data-columns {
    overflow-y: scroll;
    scrollbar-color: grey ${setColor.mainGrey};
    max-height: 200px;

    /* scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    } */
  }
  
  .row {
    display: flex;
    flex-direction: row;
    transition: 0.2s;
    position: relative;
    min-height: 50px;

    :not(:last-child) {
      border-bottom: 1px solid rgba(100, 100, 100, 0.2);
    }

   
  }
  .row-data {
    padding: 0.2rem 0.4rem;
  }
  
  .nameTabs {
    width: 35%;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
  }

  .first-nameTabs {
    width: 28%;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
  }

  .last-nameTabs {
    width: 28%;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
  }

  .editTabs {
    position: absolute;
    right: 100px;
    bottom: 8px;
  }
  
  .deleteTabs {
    position: absolute;
    right: 0px;
    bottom: 8px;
  }
  
  .details_button {
    display: flex;
    align-items: center;
  }
  
  .create-tab-container{
    margin-left: 2rem;
  }
`;
