import React, { useState, useEffect, useContext } from 'react';

import Button from '../globals/Button';

import Slider from '@material-ui/core/Slider';

import { SketchPicker } from 'react-color';

import styled from 'styled-components';

import expoHallAPI from '../../api/expoHallAPI';
import { Context as AttendantContext } from '../../context/AttendantContext';
import { NotificationFactory } from '../globals/NotificationMessage';
import SliderItem from '../sliderItem/SliderItem'
import RemoveBackgroundForm from './RemoveBackgroundForm';
import Modal from '../globals/Modal';

const TileEditorPreview = ({ className, data }) => {
  const [color, setColor] = useState('#fff');
  const { state, state: { attendant } = {}, setAttendant } = useContext(
    AttendantContext
  );
  const [sliderLogoValue, setSliderLogoValue] = useState(100);
  const [sliderImageValue, setSliderImageValue] = useState(100);
  const [tile, setTile] = useState(100);
  const [modalRemoveBackground, setModalRemoveBackground] = useState(false);

  const { state: { booth }, setBooth, } = useContext(AttendantContext);

  useEffect(() => {
    if(data){
      setSliderImageValue(data.image_brightness)
      setSliderLogoValue(data.logo_brightness)
      setColor(data.color ? data.color : '#fff')
      setBooth(data)
      setTile(data)
    }
  }, [data]);

  const handleChangeComplete = (c) => {
    let updatedData = booth;
    updatedData.color = c.hex;
    setBooth(updatedData);
    setColor(c.hex);
  };

  const handleLogoSliderChange = (event, newValue) => {
    setSliderLogoValue(newValue);
  };

  const handleImageSliderChange = (event, newValue) => {
    let updatedData = booth;
    updatedData.image_brightness = newValue
    setBooth(updatedData);
    setSliderImageValue(newValue);
  };

  const handleRemoveBackground = async () => {
    try {
      await expoHallAPI.put(`/api/tiles/${attendant.id}/`, {image: null, color:'#ffffff'});
      let updatedData = booth;
      updatedData.image = null;
      updatedData.color = '#ffffff';
      setColor('#ffffff');
      setBooth(updatedData);
      setModalRemoveBackground(false);
    } catch (error) {
      console.log(error)
    }
  };
  const handleSubmit = async () => {
    try {
      const {data} = await expoHallAPI.put(`/api/tiles/${attendant.id}/`, {logo_brightness: sliderLogoValue, image_brightness:sliderImageValue,color: color === '#fff' ? null : color });
      // console.log(data)
    } catch (error) {
      console.log(error)

    }
    finally{
      const notification = {
        title: "Success",
        message: "Customization Updated",
        type: 'success',
        insert: 'top',
        container: 'top-right',
        duration: 2000
      };
      NotificationFactory(notification)
    }
  };

  const modalStyleDelete={
    height: '150px',
    width: '500px',
    top: '45%',
    left: '50%',
  };

  return (
    <div className={className}>
      <Modal modalStyle={modalStyleDelete} component={<RemoveBackgroundForm removeBackground={handleRemoveBackground} />} modalIsOpen={modalRemoveBackground} closeModal={()=>setModalRemoveBackground(false)}/>
      <PreviewTile color={color} background={booth.image} brightnessImage={sliderImageValue} brightnessLogo={sliderLogoValue}>
        {/*<img className="logo" src={booth.logo} alt="logo" />*/}
        <SliderItem counter={0} data={booth} />
      </PreviewTile>
       {/*<div className="modal-preview">Modal Preview</div>*/}
      <div className="edit-tools">
        <div className="sketch-picker">
          <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
        </div>
        <div className="slider-group">
          {/*<div className="slider">*/}
            {/*<p>Logo Brightness</p>*/}
            {/*<Slider*/}
              {/*value={sliderLogoValue}*/}
              {/*onChange={handleLogoSliderChange}*/}
              {/*aria-labelledby="continuous-slider"*/}
              {/*valueLabelDisplay="auto"*/}
              {/*min={0}*/}
              {/*max={200}*/}
            {/*/>*/}
          {/*</div>*/}
          <div className="slider">
            <p>Image Brightness</p>
            <Slider
              value={sliderImageValue}
              onChange={handleImageSliderChange}
              aria-labelledby="continuous-slider"
              valueLabelDisplay="auto"
              min={0}
              max={200}
              classes={{ rail: 'rail', track: 'track' }}
            />
          </div>
          <Button type='submit' onClick={handleSubmit}>Submit</Button>
          <Button onClick={()=>setModalRemoveBackground(true)}>Remove Background</Button>
        </div>
      </div>
    </div>
  );
};

const PreviewTile = styled.div`
  height: 300px;
  max-width: 477px;
  width: auto;
  background-color: ${(props) => props.color};
  filter: ${props => `brightness(${props.brightnessImage}%)`};
  background-image: ${props => `url(${props.background})`};
  background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

  .logo {
    height: 5rem;
    margin: 1rem;
    filter: ${props => `brightness(${props.brightnessLogo}%)`};
  }
`;

export default styled(TileEditorPreview)`
  > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  .edit-tools {
    display: flex;
    flex-direction: row;
  }

  .slider-group {
    display: flex;
    flex-direction: column;

    > div {
      margin: 0 2rem;
    }
  }

  .tile-preview {
    height: 30rem;
    width: 50rem;
    background-color: darkcyan;
  }

  .modal-preview {
    height: 30rem;
    width: 50rem;
    background-color: darkcyan;
  }

  button {
    font-size: 1.2rem;
    width: 100%;
    margin-left: 2rem;
  }
`;
