import React, { useState, useEffect, useContext } from 'react';

import expoHallAPI from '../../api/expoHallAPI';

import styled from 'styled-components';

import SliderItem from '../sliderItem/SliderItem';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Context as SocketContext } from '../../context/SocketContext';

const responsive = {
  evenLargerDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 5000, min: 2500 },
    items: 6,
    partialVisibilityGutter: 40,
    slidesToSlide: 3,
  },
  largerDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2500, min: 2000 },
    items: 5,
    partialVisibilityGutter: 40,
    slidesToSlide: 3,
  },
  largeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1200 },
    items: 4,
    partialVisibilityGutter: 40,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 1200, min: 800 },
    items: 3,
    partialVisibilityGutter: 40,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 800, min: 400 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

const SliderCarousel = ({ category, className }) => {
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    // setTiles(shuffleArray(category.tile_set));
    setTiles(category.tile_set);
  }, [category]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // const handleGetTiles = async () => {
  //   try {
  //     const { data } = await expoHallAPI.get(`/api/tiles/${category}`);
  //     setTiles(shuffleArray(data));
  //     // console.log('tiles', data)
  //   } catch (error) {
  //     console.log('error setting tiles', error);
  //   }
  // };
  let counter = 0;
  return (
    <div className={className}>
      <h5>{category.name}</h5>
      <Carousel
        responsive={responsive}
        swipeable={false}
        draggable={false}
        showDots={false}
        ssr={true}
        //infinite={true}
        //removeArrowOnDeviceType={["tablet", "mobile"]}
        //partialVisible={true}
        sliderClass="slider"
        itemClass="item"
        containerClass="container"
        //customRightArrow={<CustomRightArrow />}
      >
        {!!tiles &&
          tiles.map((tile) => {
            counter++;
            // console.log(tile);
            return <SliderItem counter={counter} key={tile.id} data={tile} />;
          })}
      </Carousel>
    </div>
  );
};

export default styled(SliderCarousel)`
  display: flex;
  flex-direction: column;
  color: white;

  li {
    max-width: 410px;
  }

  h5 {
    margin-left: 1rem;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
  }

  .slider {
    overflow: visible;
    box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.6);
    will-change: unset;

    /* setting this value can interfere with modal */
    /* :not(:hover){
			z-index: -1;
		} */

    /* activating this makes the buttons disappear on tile hover*/
    /* :hover + * {
			z-index: -1;
		} */
  }

  .item {
    padding: 0 3px;
    overflow: visible;
    transition: z-index 0s 0.5s;
    height: 19.5rem;

    :hover {
      z-index: 40;
      transition: z-index 0s 0.2s;
    }

    :hover + * {
      z-index: -2;
      transition: z-index 0s 0s;
    }

    :not(:hover) {
      z-index: -1;
    }
  }

  .container {
    z-index: 0;
    overflow: visible;
    margin-left: 1.5rem;

    /* setting this value can interfere with modal */
    /* however, this is what allows for superposition of active tile over other sliders */
    :hover {
      z-index: 5;
    }

    :hover + * {
      z-index: -1;
    }

    :last-child {
      margin-bottom: 3rem;
    }
  }
`;
