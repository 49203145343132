import React, { useContext, useEffect, useRef, useState } from 'react';
import Input from '../globals/ChatInput';
import Button from '../globals/SendMessageButton';
import styled from 'styled-components';
import 'emoji-mart/css/emoji-mart.css';
import { Picker, Emoji } from 'emoji-mart';
import { EmojiIcon } from '../../styles/icons';

import { setColor, setFont, setBoxShadow } from '../../styles/styles';
import { Context as UserContext } from '../../context/UserContext';
import Chatuser from './Chatuser';
import Rooms from './Rooms';
import SendIcon from '@material-ui/icons/Send';
import ChatMessage from './ChatMessage';
import smile from '../../styles/images/smile.png';
import LoadingIcon from '../sliderItem/loadingIcon';

const ChatBox = ({
  className,
  messages,
  participants,
  sendMessage,
  retrieveMoreMessages,
  loadingMoreMessages,
  setLoadingMoreMessages,
  deleteMessage,
}) => {
  const [inputMessage, setInputMessage] = useState('');
  const [emoji, setEmoji] = useState('');
  const [emojiPickerBoolean, setEmojiPickerBoolean] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [scrollHeightMessages, setScrollHeightMessages] = useState(1);
  const messagesRef = useRef();

  useEffect(() => {
    // Scrolls down on chat when message loads
    if (!loadingMoreMessages) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
    if (
      loadingMoreMessages &&
      messagesRef.current.scrollHeight !== scrollHeightMessages
    ) {
      messagesRef.current.scrollTop =
        messagesRef.current.scrollHeight - scrollHeightMessages;
    }
    setLoadingMoreMessages(false);
  }, [messages]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputMessage) {
      sendMessage(inputMessage);
      setInputMessage('');
    }
  };
  const handleMessageSend = () => {
    if (inputMessage) {
      sendMessage(inputMessage);
      setInputMessage('');
    }
  };

  const handlescroll = (event) => {
    // console.log(event);
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    // console.log('scrollTop', scrollTop);
    // console.log('clientHeight', clientHeight);
    // console.log('scrollHeight', scrollHeight);

    if (scrollTop === 0 && scrollHeightMessages !== scrollHeight) {
      retrieveMoreMessages(currentPage + 1);
      setcurrentPage(currentPage + 1);
      setScrollHeightMessages(scrollHeight);
    }
  };
  return (
    <div className={className}>
      <div className="chatContainer">
        {/* <Rooms /> */}
        <div className="chatContainerConversation">
          <h6>Chat</h6>
          {/*<div className="chatContainerTitle">*/}
          {/*Conversation*/}
          {/*</div>*/}
          <div
            className="chatContainerConversationBody"
            ref={messagesRef}
            onScroll={handlescroll}
          >
            {loadingMoreMessages && (
              <div>
                <LoadingIcon />
              </div>
            )}
            {messages.map((message) => {
              return (
                <ChatMessage message={message} deleteMessage={deleteMessage} />
              );
            })}
            {emojiPickerBoolean && (
              <div onMouseLeave={() => setEmojiPickerBoolean(false)}>
                {' '}
                <Picker
                  onSelect={(event) => {
                    setInputMessage(inputMessage + event.native);
                  }}
                />{' '}
              </div>
            )}
          </div>

          <div className="chatContainerConversationFooter">
            <div className="Chatbox_footer">
              <input
                placeholder="Type a message...."
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <div className="chatbox_buttons">
                <img
                  src={smile}
                  onClick={() =>
                    setEmojiPickerBoolean((prevState) => !prevState)
                  }
                  className="Chatbox_imgbutton"
                />
                <SendIcon
                  className="Chatbox_sendbutton"
                  onClick={handleMessageSend}
                />
              </div>
            </div>
          </div>
        </div>
        <Chatuser title={true} participants={participants} />
      </div>
    </div>
  );
};

export default styled(ChatBox)`
  height: 100%;
  padding: 1%;
  background: #fff;
  /* display:flex;  */
  .emoji-mart-preview {
    display: none;
  }

  .emoji-mart {
    position: absolute;
    right: 41px;
    bottom: 35px;
    width: 250px !important;
    z-index: 100;
    transition: all 0.1s ease;
  }
  .emoji-mart-scroll {
    height: 150px;
  }

  .chatContainer {
    box-shadow: ${setBoxShadow.mediumPressed};
    height: 100%;
    display: flex;
    color: black;
  }

  .chatContainerConversation {
    height: 100%;
    width: 0%;
    box-shadow: ${setBoxShadow.mediumPressed};
    flex: 3;
    position: relative;
    h6 {
      background: ${setColor.midnightBlue};
      min-height: 3rem;
      padding: 5px 10px 5px 10px;
      font-size: 20px;
      font-family: ${setFont.lato};
      text-align: center;
      color: white;
    }
  }

  .chatContainerUsers {
    width: 20%;
  }

  .chatContainerTitle {
    font-size: 1.6rem;
    font-family: ${setFont.main};
    height: 25px;
    background-color: ${setColor.secondaryGrey};
    color: #fff;
    text-align: center;
    box-shadow: ${setBoxShadow.mediumPressed};
  }

  .chatContainerUsersBody {
    padding: 5px 2px;
    width: 100%;
  }

  .chatContainerUsersTag {
    width: 90%;
    margin: 5%;
    border-radius: 20px;
    text-align: center;
    min-height: 30px;
    padding: 2px;
    font-size: 1.6rem;
    font-family: ${setFont.main};
    color: ${setColor.secondaryGrey};
    box-shadow: ${setBoxShadow.mediumPressed};
  }
  .chatContainerConversationBody {
    padding: 2%;
    overflow-y: auto;
    height: calc(100% - 100px);
    background-color: white;
    border: none;
  }
  .chatContainerConversationFooter {
    /* margin-top: 10px; */
    position: absolute;
    width: 100%;
    background: white;
    bottom: 0;
    /* overflow: hidden; */
    /* padding-top: 10px;
    padding-bottom: 8px; */
    height: fit-content;
    input {
      width: 100%;
      border: none;
      background-color: none;
      padding: 1rem;
      :focus {
        border: none;
        outline: none;
        background-color: none;
      }
    }
    .Chatbox_footer {
      border: 0.1px solid ${setColor.secondaryGrey};
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 3px;
    }
    .chatbox_buttons {
      background-color: ${setColor.lightGrey};
      width: 100%;
      border-top: 0.5px solid #00000014;
      display: flex;
      justify-content: flex-end;
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      .Chatbox_sendbutton {
        color: ${setColor.midnightBlue};
        font-size: 20px;
        margin-left: 5px;
        cursor: pointer;
      }
      .Chatbox_imgbutton {
        height: 20px;
        width: 20px;
        cursor: pointer;
      }
    }
  }

  .chatContainerConversationBubble {
    margin-bottom: 2%;
    clear: both;
  }
  .chatContainerConversationLeftBubble,
  .chatContainerConversationRightBubble {
    font-size: 1.2rem;
    padding: 8px;
    border-radius: 10px;
    word-break: break-all;
    margin: 0;
    width: max-content;
    font-family: ${setFont.main};
  }
  .chatContainerConversationLeftBubble {
    float: left;
    position: relative;
    border-bottom-left-radius: 0;
    color: #111111;
    background-color: #f1f0f0;
  }
  .chatContainerConversationRightBubble {
    float: right;
    position: relative;
    border-bottom-right-radius: 0;
    color: #ffffff;
    background-color: ${setColor.secondaryGrey};
  }
  .chatContainerConversationLeftUserName,
  .chatContainerConversationRightUserName {
    margin: 0;
    font-size: 0.9rem;
    font-family: ${setFont.main};
  }
  .chatContainerConversationRightUserName {
    text-align: right;
  }
`;
