import contextFactory from './contextFactory'


const initialState = {
  attendant: '',
  booth: {},
};
const attendantReducer = (state, action) => {
	switch (action.type) {
		case 'set_attendant':
			return { ...state, attendant: action.payload }
    case 'set_booth':
      return { ...state, booth: action.payload }


		default:
			return state
	}
}

const attendantActions = {
	setAttendant: dispatch => attendant => {
		dispatch({ type: 'set_attendant', payload: attendant })
	},
  setBooth: dispatch => booth => {
    dispatch({ type: 'set_booth', payload: booth })
  },
};


export const { Context, Provider } = contextFactory(
	attendantReducer,
	{ ...attendantActions },
  initialState
)

