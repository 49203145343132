import React, { useContext, useEffect, useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import expoHallAPI from '../api/expoHallAPI';
import { setColor } from '../styles/styles';

import { connectChat, disconnectChat } from '../api/expoHallSocket';

import ExhibitorContext from '../context/exhibitorContext';
import Navbar from '../components/globals/Navbar';
import { Context as UserContext } from '../context/UserContext';

import styled from 'styled-components';
import SliderCarousel from '../components/sliderCarousel/SliderCarousel';
import ReactPlayer from 'react-player';
import { Context as AttendantContext } from '../context/AttendantContext';
import { Context as SocketContext } from '../context/SocketContext';
import Countdown from 'react-countdown';
import { CustomNotification } from '../components/globals/NotificationAgreement';
import SliderPageRightPane from './SliderPageRightPane';
import LoadingPage from './LoadingPage';
import { Tooltip } from '@material-ui/core';
import SiteData from '../config';

//timestamp logging
import getTimeLogged from '../utils/getTimeLogged';
import { set } from 'lodash';

const SliderPage = ({ className, loadChat }) => {
  const exhibitors = useContext(ExhibitorContext);
  const {
    state,
    state: { user },
    setUser,
  } = useContext(UserContext);
  const { setAttendant } = useContext(AttendantContext);
  const {
    state: { categories, connection, isTileClicked, EventisLive, isPaused },
    setCategories,
    setTileStatus,
    setEventisLive,
    setIsPaused,
  } = useContext(SocketContext);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [loadCategories, setLoadCategories] = useState(false);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    handleAuthCheck();
  }, []);

  useEffect(() => {
    return () => {
      if (user) {
        // disconnectChat(user, SiteData.auditoriumChatId);
        disconnectChat(user, SiteData.generalChatId);
      }
    };
  }, [user]);

  useEffect(() => {
    if (user) {
      handleGetCategories();
      getNotification();
    }
  }, [connection, user]);

  useEffect(() => {
    if (user && connection) {
      // connectChat(user, SiteData.auditoriumChatId);
      connectChat(user, SiteData.generalChatId);
      // comeback to change
    }
  }, [connection, user]);

  const handleAuthCheck = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-user');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendee/profile/');
      // console.log(data);
      if (data) {
        setUser(data);
      }
    } catch (error) {
      handleAuthCheckAttendant();
    }
  };

  const handleAuthCheckAttendant = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-attendant');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendant/profile/');
      // console.log(data);
      if (data) {
        setUser(data);
        setAttendant(data);
      }
    } catch (error) {
      expoHallAPI.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('shrm-li-expo-user');
      localStorage.removeItem('shrm-li-expo-attendant');
      history.push('/login');
      setUser(undefined);
      setAttendant(undefined);
    }
  };

  const handleGetCategories = async () => {
    try {
      const { data } = await expoHallAPI.get('/api/categories/');
      setCategories(data);
      setLoadCategories(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getNotification = async () => {
    try {
      const { data } = await expoHallAPI.get('/api/notifications/expo/');
      data.map((eachData) => {
        timeTriggerActions(eachData);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const timeTriggerNow = (data) => {
    const now = new Date();
    const TimeNow = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
    const EventTime = parseFloat(data.milliseconds);
    //time left for event
    let TimeLeftForEvent = EventTime - TimeNow;
    if (TimeLeftForEvent < 0) {
      TimeLeftForEvent += 86400000; // adds 1 day (runs everyday)
    }

    const eventDay = new Date(EventTime).getUTCDate();
    if (now.getUTCDate() === eventDay) {
      setTimeout(() => {
        auditoriumActions(data);
      }, TimeLeftForEvent);
    }
  };

  const timeTriggerPeriod = (data) => {
    const now = new Date();
    const EventTime = new Date(data.time);
    const EventEndTime = new Date(data.end_time);
    if (
      new Date(now.toUTCString()) > new Date(EventTime.toUTCString()) &&
      new Date(now.toUTCString()) < new Date(EventEndTime.toUTCString())
    ) {
      auditoriumActions(data);
    }
  };

  const timeTriggerActions = (data) => {
    switch (data.notif_type) {
      case 'NOW':
        timeTriggerNow(data);
        break;
      case 'PERIOD':
        timeTriggerPeriod(data);
        break;
      default:
      // code block
    }
  };

  const auditoriumActions = (data) => {
    const now = new Date(new Date().toUTCString());
    const EventTime = new Date(new Date(data.time).toUTCString());
    const EventEndTime = new Date(new Date(data.end_time).toUTCString());
    const differenceAfter = now.getTime() - EventTime.getTime();
    const differenceBefore = EventEndTime.getTime() - now.getTime();
    const minutes = Math.ceil(differenceBefore / 60000);

    getTimeLogged(data.id.toString());

    switch (data.id.toString()) {
      case '1':
        // first action to take take.
        /**
         * when usern logs in in this period. they are moved to the expoHall.
         * countDown Begins
         */
        data.time = <Countdown date={Date.now() + differenceBefore} />;
        data.buttonText = '';
        setNotificationData(data);
        setOpenNotification(true);
        break;
      case '2':
        /**
         * NOW event. Users are moved to expoHall
         */
        proceedToAuditorium();
        break;

      case '6':
        // when Live event is on going with the join to go back to the expohall
        // While live event is going on if you return to the expor hall.
        setNotificationData(data);
        setOpenNotification(true);
        data.buttonText = '';
        data.time = '';
        setTimeout(() => {
          setOpenNotification(false);
        }, 60000);

        setEventisLive(true);
        setTimeout(() => {
          setEventisLive(false);
        }, differenceBefore);

        break;

      // case '8':
      //   // Auditorium has ended. Set live pulse to false
      //   setEventisLive(false);
      //   break;
      case '8':
        /**
         *It displays the Countdown for the next webinar session
         * */
        data.time = '';
        data.buttonText = '';
        setNotificationData(data);
        setOpenNotification(true);
        break;
      case '10':
        /**
         *It displays the Countdown for the next webinar session
         * */
        // data.time = <Countdown date={Date.now() + differenceBefore} />;
        data.time = '';
        data.buttonText = '';
        setNotificationData(data);
        setOpenNotification(true);
        break;
      case '9':
        // pop up notification
        // When to display the next agenda. and the action to do

        setOpenNotification(true);
        data.buttonText = 'Join Here';
        data.time = '';
        setNotificationData(data);
        setTimeout(() => {
          setOpenNotification(false);
        }, 50000);
        break;

      default:
      // code block
    }
  };
  const proceedToZoomLink = (link) => {
    window.open(link);
  };
  const proceedToAuditorium = () => {
    history.push('/auditorium');
  };
  // if (!loadChat || !loadCategories) {
  //   return <LoadingPage />;
  // } else {
  return (
    <div className={className}>
      <CustomNotification
        onButtonClick={(e) =>
          notificationData.buttonText === 'Join Here'
            ? proceedToZoomLink(notificationData.external_link)
            : proceedToAuditorium()
        }
        title={notificationData.title}
        buttonText={notificationData.buttonText}
        description={notificationData.message}
        time={notificationData.time}
        open={openNotification}
        onCloseIconClick={(e) => {
          setOpenNotification(false);
        }}
      />
      <div className="leftPane">
        <Navbar />

        {SiteData.Isbanner && (
          <BannerDiv background={SiteData.bannerData.backgroundURL}>
            {SiteData.bannerData.videoURL && (
              <VideoContainer>
                <ReactPlayer
                  config={{
                    file: { attributes: { controlsList: 'nodownload' } },
                  }}
                  playing={isTileClicked}
                  url={SiteData.bannerData.videoURL}
                  loop
                  onPause={() => {
                    if (isTileClicked) {
                      setIsPaused(true);
                    } else setIsPaused(false);
                  }}
                  onPlay={() => {
                    console.log(isTileClicked);
                    if (!isTileClicked) {
                      setTileStatus(true);
                    }
                  }}
                  controls
                  volume={0.3}
                  muted
                  width="100%"
                  height="100%"
                />
              </VideoContainer>
            )}
            {/* <FadeEffect /> */}
          </BannerDiv>
        )}
        <ContainerDiv isbanner={SiteData.Isbanner}>
          {/* <p>NOTE: If tiles do not appear, please resize your browser window</p> */}
          {EventisLive && (
            <Tooltip
              title={
                <h6
                  style={{
                    color: 'white',
                    display: 'flex',
                    paddingTop: '0.5rem',
                  }}
                >
                  Auditorium is Live
                </h6>
              }
            >
              <LivePulse
                onClick={() => {
                  proceedToAuditorium();
                }}
              />
            </Tooltip>
          )}
          {!!categories &&
            categories
              .sort((a, b) => a.id - b.id)
              .map((category) => (
                <SliderCarousel key={category.id} category={category} />
              ))}
        </ContainerDiv>
      </div>
      <div className="rightPane">
        <h6>Event Discussion</h6>
        <SliderPageRightPane />
      </div>
    </div>
  );
  // }
};

export default styled(SliderPage)`
  display: flex;
  position: relative;
  .leftPane {
    flex: 0.8;
    overflow-x: hidden;
    width: 80%;
  }
  .rightPane {
    flex: 0.2;

    height: 100vh;
    position: fixed;
    width: 20%;
    right: 0;
    background-color: white;
    h6 {
      padding-top: 5px;
      text-align: center;
      background-color: ${setColor.mainGrey};
      padding-bottom: 1rem;
    }
  }
`;

const ContainerDiv = styled.div`
  margin-right: 2rem;
  p {
    text-align: center;
  }
  margin-top: ${(props) => (props.isbanner ? '1rem' : '7rem')};
`;

// const BannerDiv = styled.div.attrs({})`
//   background-image: url(${(props) => props.background});
//   height: 80vh;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   /* margin-bottom: -3rem; */
//   position: relative;
// `;
const BannerDiv = styled.div.attrs({})`
  background-image: url(${(props) => props.background});
  height: 80vh;
  background-repeat: no-repeat;
  background-position: 0 70px;
  background-size: cover;
  /* margin-bottom: -3rem; */
  position: relative;
`;
const FadeEffect = styled.div`
  height: 25vh;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    #00000000,
    #000000b8,
    #00000082,
    #00000012
  );
  /* background: red; */
  /* margin-top: -25rem; */
  position: absolute;
  bottom: 0;
`;

const VideoContainer = styled.div`
  width: 40vw;
  position: absolute;
  right: 10%;
  top: 30%;
  :focus {
    outline: none;
  }
`;
export const LivePulse = styled.div`
  background: red;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 30px;
  width: 30px;
  transform: scale(1);
  animation: pulse 2s infinite;
  right: 20%;
  position: fixed;
  cursor: pointer;
  z-index: 1;
  top: 10%;
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
`;
