import React from 'react';
import styled from 'styled-components';

const Avatar = ({ className, firstname, lastname, is_attendant }) => {
  return (
    <div
      // style={{ border: is_attendant ?  }}
      className={className}
    >
      <div className="letter">
        <span>{firstname && firstname.charAt(0)} </span>{' '}
        <span>{lastname && lastname.charAt(0)}</span>
        {/* nothing */}
      </div>
    </div>
  );
};

export default styled(Avatar)`
  background-color: firebrick !important;
  border-radius: 50%;
  min-width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  border: ${({ is_attendant }) =>
    is_attendant ? '4px solid #212161' : '4px solid firebrick'};
  .letter {
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    display: flex;
  }
`;
