import React from 'react';
import styled from 'styled-components';
import {
  PhoneIcon,
  IdIcon,
  GiftIcon,
  ArrowIcon,
  ChatIcon,
  SoundIcon,
  PlayVideoIcon,
  ModalSoundIcon,
  AuditoriumIcon,
  PrivateMessageIcon,
  CalendarIcon,
} from '../../styles/icons';

import IconContext from '../../context/IconContext';

const IconsBar = ({
  switchButton,
  className,
  handleMouseEnter,
  handleMouseLeave,
  style,
  small,
  iconColor,
  iconHoverColor,
  tooltipDelay = 200,
  tooltipPlacement = 'bottom',
  tooltipFontSize = 14,
  iconHandlers,
  inModal,
  isVideo,
  toggleVideo,
  videoIsMuted,
  chatIsOpen,
  requestMeetingIsOpen,
  modalVideoIsMuted,
  additionalResources,
  modalIsOpen,
  auditoriumIsOpen,
  video_call_toggle,
  chat_toggle,
  meeting_toogle,
  businesscard_toggle,
}) => {
  return (
    <div
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        right:
          switchButton && chat_toggle
            ? modalIsOpen
              ? chatIsOpen
                ? '25.5%'
                : '30%'
              : '0px'
            : '0.5%',
      }}
    >
      <IconContext.Provider
        value={{
          small,
          iconColor,
          iconHoverColor,
          tooltipDelay,
          tooltipPlacement,
          tooltipFontSize,
          iconHandlers,
          toggleVideo,
          videoIsMuted,
          chatIsOpen,
          modalVideoIsMuted,
          requestMeetingIsOpen,
        }}
      >
        {/* {switchButton && video_call_toggle && (
          <PhoneIcon tooltipTitle="Join Video Conference" />
        )}
        <IdIcon tooltipTitle="Drop a Business Card" />
        {additionalResources && <ArrowIcon tooltipTitle="Resources" />}
        {switchButton && (
          <ChatIcon
            tooltipTitle={chatIsOpen ? 'More Information' : 'Join Booth Chat'}
          />
        )} */}
        {video_call_toggle && (
          <PhoneIcon
            switchButton={switchButton}
            tooltipTitle={
              !switchButton
                ? ' Video Conference is Unavailable'
                : 'Join the Party'
            }
          />
        )}
        {businesscard_toggle && <IdIcon tooltipTitle="Drop a Business Card" />}
        {additionalResources && <ArrowIcon tooltipTitle="Resources" />}
        {chat_toggle && (
          <ChatIcon
            switchButton={switchButton}
            tooltipTitle={
              !switchButton
                ? 'Booth Chat is Unavailable'
                : chatIsOpen
                ? 'More Information'
                : 'Join Booth Chat'
            }
          />
        )}

        {/*<AuditoriumIcon*/}
        {/*tooltipTitle={auditoriumIsOpen ? 'more information' : 'Watch Auditorium Video'}*/}
        {/*/>*/}
        {isVideo && !inModal && <SoundIcon tooltipTitle="Toggle sound" />}
        {inModal && isVideo && (
          <PlayVideoIcon
            tooltipTitle={toggleVideo ? 'More Information' : 'Play our video'}
            toggleVideo={toggleVideo}
          />
        )}
        {meeting_toogle && (
          <CalendarIcon
            tooltipTitle={
              requestMeetingIsOpen ? 'More Information' : 'Request Meeting'
            }
          />
        )}
        {/* <PrivateMessageIcon
          tooltipTitle={
            requestMeetingIsOpen ? 'More Information' : 'Send A Private Message'
          }
        /> */}
        {/* {isVideo && inModal && <ModalSoundIcon tooltipTitle="Toggle sound" />} */}
      </IconContext.Provider>
    </div>
  );
};

export default styled(IconsBar)`
  display: flex;
  justify-content: space-evenly;
  z-index: ${(props) => (props.requestMeetingIsOpen ? `3000` : `2000`)};
  transition: right ease 0.3s;
  top: 50%;

  transform: translateY(-50%);
  margin-right: 10;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  display: visible;
`;
