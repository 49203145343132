import React, { useState } from 'react';
import styled from 'styled-components';
import { setColor, setFont, setBoxShadow } from '../../styles/styles';
import Avatar from './AvatarUser';

const Chatuser = ({ className, participants, title }) => {
  const [inputSearch, setinputSearch] = useState('');
  const [chatParticipants, setchatParticipants] = useState([]);
  const [search, setsearch] = useState(false);
  let searchCharacters = [];
  const handleChange = (e) => {
    setinputSearch(e.target.value);
    if (e.target.value.length > 0) {
      setchatParticipants(
        participants.filter((participant) => {
          return (
            participant.first_name
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) !== -1 ||
            participant.last_name
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) !== -1 ||
            (participant.screen_name &&
              participant.screen_name
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) !== -1)
          );
        })
      );
      setsearch(true);
    } else setsearch(false);
  };
  return (
    <div className={className}>
      <div className="Chatuser">
        {title && (
          <div className="Chatuser_header">
            Participants ({participants?.length})
          </div>
        )}

        <div className="Chatuser_search">
          <input
            value={inputSearch}
            onChange={(e) => handleChange(e)}
            type="text"
            placeholder="Search Members"
          />
        </div>
        <div className="Chatuser_participants">
          {search &&
            chatParticipants.map((participant) => {
              return (
                <div className="Chatuser_participant">
                  <Avatar
                    firstname={participant.first_name.toUpperCase()}
                    lastname={participant.last_name.toUpperCase()}
                    is_attendant={participant.is_attendant}
                  />

                  <div className="Chatuser_info">
                    <p>
                      {participant.screen_name
                        ? `${participant.first_name} ${participant.last_name} - ${participant.screen_name}`
                        : participant.first_name + ' ' + participant.last_name}
                    </p>
                    <i className="fas fa-circle"></i>
                  </div>
                </div>
              );
            })}
          {!search &&
            participants.map((participant) => {
              return (
                <div className="Chatuser_participant">
                  <Avatar
                    firstname={participant.first_name.toUpperCase()}
                    lastname={participant.last_name.toUpperCase()}
                    is_attendant={participant.is_attendant}
                  />
                  <div className="Chatuser_info">
                    <p>
                      {participant.screen_name
                        ? `${participant.first_name} ${participant.last_name} - ${participant.screen_name}`
                        : participant.first_name + ' ' + participant.last_name}
                    </p>
                    <i className="fas fa-circle"></i>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default styled(Chatuser)`
  flex: 1;
  color: black;
  .Chatuser_header {
    background-color: ${setColor.midnightBlue};
    min-height: 3rem;
    text-align: center;
    padding: 5px 10px 5px 10px;
    font-size: 20px;
    font-family: ${setFont.lato};
    color: white;
  }
  .Chatuser_participants{
    padding-bottom: 3rem;
  }

  .Chatuser_participant {
    display: flex;
    align-items: center;
    padding: 5px 5px;
    justify-content: flex-start;
  }
  .Chatuser_search {
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* margin-right: auto; */
    /* margin-left: auto; */
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    i {
      position: absolute;
      color: lightgray;
      top: 8px;
      right: 14px;
      font-size: 1.5rem;
    }
    input {
      padding: 5px 20px 5px 10px;
      border: 0.5px solid ${setColor.secondaryBlue};
      height: 3rem;
      border-radius: 5px;
    }
  }
  .Chatuser_info {
    display: flex;
    align-items: center;

    justify-content: space-between;
    width: 100%;
    p {
      text-transform: capitalize;
      margin-left: 10px;
      font-weight: bold;
    }
    i {
      color: ${setColor.Onlinegreen};
      margin-right: 10px;
    }
  }

  /* Material-Ui styling */
  .MuiAvatar-root {
    border: 3px solid red;
  }
`;
