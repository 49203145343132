import styled from 'styled-components';
import { setColor } from '../../styles/styles';

export const LogoutButton = styled.button`
  width: 7rem;
  border-radius: 100rem;
  padding: .5rem 1rem;
  margin-left: 2rem;
  font-family: inherit;
  font-size: 1.4rem;
  padding: 0.5rem 3rem;
  color: ${setColor.primaryBlue};
  /* box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5); */
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, ${setColor.primaryBlue}, ${setColor.secondaryBlue});
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px ${setColor.mainGrey} inset;
  transition: all 0.2s;
  outline: none;
  backface-visibility: hidden;
  /* disable highlight */
  user-select: none;
  display: flex;
  justify-content: center;
  z-index: 30;
  /* position: relative; */

  :hover {
    box-shadow: none;
    color: white;
    transform: scale(1.05);
    transition: all 0.4s;

    /* backface-visibility: hidden; */
  }
`;

export default LogoutButton;
