import React, { useState, useEffect, useContext } from 'react';

import ReactModal from 'react-modal';

import ReactPlayer from 'react-player';
import VideoTransition from '../globals/VideoTransition';
import DirectionsRunSharpIcon from '@material-ui/icons/DirectionsWalkSharp';
import expoHallAPI from '../../api/expoHallAPI';

import styled from 'styled-components';

import { CloseIcon } from '../../styles/icons';

import { setColor, setBoxShadow } from '../../styles/styles';

import IconsBar from './iconsBar';

import RequestMeetingForm from '../requestMeetingForm/RequestMeetingForm';

import Chatbox from '../Chatbox/Chatbox';
import LoadingIcon from './loadingIcon';

import { Context as SocketContext } from '../../context/SocketContext';

import {
  sendChatMessage,
  retrieveMoreMessages,
  deleteChatMessage,
  sendUpdateChatParticipants,
} from '../../api/expoHallSocket';
import { Context as UserContext } from '../../context/UserContext';
import Smallchatbox from '../Chatbox/Smallchatbox';
import SiteDate from '../../config';

let update_chat_participant_interval = null;

const SliderItemModal = ({
  modalIsOpen,
  closeModal,
  companyData: { data },
  className,
  iconHandlers,
  chatIsOpen,
  requestMeetingIsOpen,
  additionalResources,
  onAfterOpen,
  auditoriumIsOpen,
  toggleVideo,
  setToggleVideo,
}) => {
  const [modalVideoIsMuted, setModalVideoIsMuted] = useState(true);
  const [loadChat, setLoadChat] = useState(false);
  const {
    state: {
      tileChatMessages,
      tileChatParticipants,
      loadTileChatContext,
      loadingMoreMessages,
      isTileclick,
      isPaused,
    },
    setLoadTileChatContext,
    setLoadingMoreMessages,
    setTileStatus,
  } = useContext(SocketContext);
  const { state: { user } = {}, setUser } = useContext(UserContext);
  const ct = false;
  let {
    name,
    zoomSpeed,
    zoomLevel,
    logo,
    image,
    video,
    color,
    videocall_url = '',
    booth,
    chat_url,
    image_brightness,
    blurb,
    blurb_link,
    id,
    vimeo_url,
    group_id,
    closed,
    video_call_toggle,
    chat_toggle,
    meeting_toggle,
    business_card_toggle,
  } = data;

  const handleUpdateChatParticipants = () => {
    update_chat_participant_interval = setInterval(() => {
      sendUpdateChatParticipants(group_id.id);
    }, 60000);
  };

  useEffect(() => {
    //handleUpdateChatParticipants();
    return () => {
      // clearInterval(update_chat_participant_interval);
      // update_chat_participant_interval = null;
    };
  }, []);
  useEffect(() => {
    if (video) {
      setToggleVideo(true);
    }
    closed = data.closed;
  }, [data]);

  useEffect(() => {
    setLoadChat(loadTileChatContext);
  }, [loadTileChatContext]);

  const sendChatMessageTile = (message) => {
    sendChatMessage(user, group_id, message);
  };

  const deleteMessageTile = (message) => {
    deleteChatMessage(group_id, message);
  };

  const retrieveMoreMessagesTile = (currentPage) => {
    retrieveMoreMessages(group_id, currentPage);
    setLoadingMoreMessages(true);
  };

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 900,
    },
    content: {
      height: '85%',
      width: '70%',
      transform: 'translate(-50%, -50%)',
      top: '53%',
      left: '41%',
      right: 'auto',
      bottom: 'auto',
      borderRadius: 0,
      borderWidth: 0,
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      backgroundColor: 'white',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      backfaceVisibility: 'hidden',
      boxShadow: '0px 0px 48px 0px rgba(0,0,0,0.4)',
      overflow: 'hidden',
      zIndex: 1000,
    },
  };

  return (
    <ReactModal
      isOpen={modalIsOpen}
      style={customStyles}
      onRequestClose={() => {
        if (isPaused) {
          setTileStatus(false);
        } else {
          console.log(isTileclick);
          setTileStatus(true);
        }
        closeModal();
      }}
      onAfterClose={() => {
        if (isPaused) {
          console.log(isTileclick);
          setTileStatus(false);
        } else {
          console.log(isTileclick);
          setTileStatus(true);
        }
      }}
      ariaHideApp={false}
      onAfterOpen={onAfterOpen}
    >
      <div className={className}>
        <div
          onClick={() => {
            setTileStatus(false);
            closeModal();
            setLoadTileChatContext(false);
          }}
          style={{
            position: 'absolute',
            zIndex: 10,
            top: '15px',
            /* display: flex; */
            height: '20px',
            right: '15px',
            width: '20px',
          }}
          className="close-icon"
        >
          <CloseIcon />
        </div>
        <div
          style={{
            display: chatIsOpen ? 'block' : 'flex',
          }}
          className="SliderItemModal_container"
        >
          <div
            style={
              closed
                ? { flex: '1' }
                : chatIsOpen
                ? { display: 'block', height: '100%', width: '100%' }
                : { flex: '0.7' }
            }
            className="SliderItemModal_right"
          >
            <div className="chat-overlay">
              {!(chatIsOpen || requestMeetingIsOpen || auditoriumIsOpen) && (
                <VideoTransition
                  trigger={toggleVideo}
                  duration={600}
                  timeout={500}
                  toggleVideo={toggleVideo}
                >
                  {({ defaultStyle, transitionStyles, state }) => {
                    return (
                      <ReactPlayer
                        url={video}
                        playing={toggleVideo}
                        //onClick={() => console.log('clicked!')}
                        height="100%"
                        width={
                          toggleVideo && !closed && chat_toggle
                            ? '70%'
                            : toggleVideo && closed
                            ? '100%'
                            : '100%'
                        }
                        loop
                        volume={0.3}
                        muted={modalVideoIsMuted}
                        //bring up issue with controls placement
                        controls={true}
                        style={{
                          left: 0,
                          top: 0,

                          backgroundColor: 'black',
                          overflow: 'hidden',
                          zIndex: chatIsOpen ? -1 : 30,
                          ...defaultStyle,
                          ...transitionStyles[state],
                        }}
                      />
                    );
                  }}
                </VideoTransition>
              )}
              <RequestMeetingDiv
                style={{
                  width: !closed && chat_toggle ? '70%' : '100%',
                  height: '100%',
                  position: requestMeetingIsOpen ? 'fixed' : 'absolute',
                  zIndex: requestMeetingIsOpen ? '1' : '-1',
                  display: requestMeetingIsOpen ? 'flex' : 'block',
                }}
                requestMeetingIsOpen={requestMeetingIsOpen}
                chatIsOpen={chatIsOpen}
              >
                <div className="RequestMeetingInnerContainer">
                  <RequestMeetingForm tileid={id} />
                </div>
              </RequestMeetingDiv>
              <ChatDiv
                style={{ width: '100%', height: '100%' }}
                chatIsOpen={chatIsOpen}
              >
                <Chatbox
                  messages={tileChatMessages}
                  participants={tileChatParticipants}
                  sendMessage={sendChatMessageTile}
                  setLoadingMoreMessages={setLoadingMoreMessages}
                  loadingMoreMessages={loadingMoreMessages}
                  retrieveMoreMessages={retrieveMoreMessagesTile}
                  deleteMessage={deleteMessageTile}
                />
              </ChatDiv>
              <div
                style={
                  chatIsOpen || requestMeetingIsOpen
                    ? { position: 'absolute', opacity: '0', zIndex: '-100' }
                    : {
                        position: 'absolute',
                        top: '0',
                        zIndex: '1',
                        width: closed ? '100%' : 'inherit',
                      }
                }
                className="sliderItemModal_description"
              >
                {closed && (
                  <AwayDiv>
                    <DirectionsRunSharpIcon />
                    This booth is set to "Away". Some Features are unavailable
                    until the attendant returns.
                  </AwayDiv>
                )}
                <div className="description">
                  <div className="image">
                    <img
                      src={image}
                      style={{
                        filter: `brightness(${image_brightness}%)`,
                        backgroundColor: `${color}`,
                      }}
                      alt="company logo"
                      height="100%"
                    />
                  </div>
                  <h1>{name}</h1>
                  <p>
                    {blurb}

                    {blurb_link && (
                      <LinkButton href={blurb_link} target="_blank">
                        Click Here
                      </LinkButton>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {chat_toggle &&
            !closed &&
            !chatIsOpen &&
            (loadChat ? (
              <div className="sliderItemModal_chat">
                <h6>Booth Discussion</h6>
                <Smallchatbox
                  AttendantDashboard={false}
                  messages={tileChatMessages}
                  participants={tileChatParticipants}
                  sendMessage={sendChatMessageTile}
                  setLoadingMoreMessages={setLoadingMoreMessages}
                  loadingMoreMessages={loadingMoreMessages}
                  retrieveMoreMessages={retrieveMoreMessagesTile}
                  deleteMessage={deleteMessageTile}
                />
              </div>
            ) : (
              <div className="sliderItemModal_chat">
                <LoadingIcon />
              </div>
            ))}
        </div>
        <IconsBar
          switchButton={!closed}
          modalIsOpen={modalIsOpen}
          className="Icon_bar"
          iconColor="#1b1b1b"
          // style={
          //   {
          //     //flexDirection: 'row',
          //   }
          // }
          iconHandlers={{
            ...iconHandlers,
            setModalVideoMute: () =>
              setModalVideoIsMuted((prevState) => !prevState),
          }}
          requestMeetingIsOpen={requestMeetingIsOpen}
          chatIsOpen={chatIsOpen}
          auditoriumIsOpen={auditoriumIsOpen}
          inModal
          isVideo={!!video}
          toggleVideo={toggleVideo}
          modalVideoIsMuted={modalVideoIsMuted}
          tooltipPlacement="right"
          additionalResources={additionalResources}
          video_call_toggle={video_call_toggle}
          chat_toggle={chat_toggle}
          meeting_toogle={meeting_toggle}
          businesscard_toggle={business_card_toggle}
        />
      </div>
    </ReactModal>
  );
};
const AwayDiv = styled.div`
  /* background-color: ${setColor.lightGrey}; */
  background-color: white;
  box-shadow: ${setBoxShadow.lightPressed};
  position: absolute;
  z-index: 1000;
  color: #9c9797;
  width: fit-content;
  margin: 0;
  top: 5%;
  left: 15%;
  font-size: 20px;
  padding: 1rem;
  border-radius: 5px;
  border: 0.5px solid ${setColor.mainGrey}2e;
  font-family: sans-serif;
  .MuiSvgIcon-root {
    font-size: 25px;
  }
`;
export default styled(SliderItemModal)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  .SliderItemModal_Videopanel {
    display: flex;
  }
  .SliderItemModal_container {
    height: 100%;
    width: 100%;
    p {
      opacity: ${(props) => (props.chatIsOpen ? `1` : `1`)};
      visibility: ${(props) => (props.chatIsOpen ? 'visible' : 'visible')};
    }
  }

  .sliderItemModal_chat {
    p {
      opacity: ${(props) =>
        props.requestMeetingIsOpen || !props.chatIsOpen ? '1' : '1'};
      visibility: ${(props) =>
        props.requestMeetingIsOpen || !props.chatIsOpen
          ? 'visible'
          : 'visible'};
    }
    flex: 0.3;
    background-color: ${setColor.ghostWhite};
    border-left: 1px solid ${setColor.lightGrey};
    /* z-index:2; */

    h6 {
      color: ${setColor.midnightBlue};
      text-align: center;
      margin-top: 10px;
    }
  }

  /* .close-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;
  } */

  .image {
    height: 15%;
    z-index: 0;
  }

  h1 {
    padding: 1rem 0;
    color: darkgrey;
    font-weight: 200;
    font-size: 20px;
  }

  a {
    text-decoration: none;

    h6 {
      font-weight: 300;
      color: ${setColor.primaryBlue};
    }
  }

  .chat-overlay {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow-y: ${(props) => (props.chatIsOpen ? 'hidden' : 'auto')};
  }

  p {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    z-index: 0;
    width: 100%;
    /* this is to recognize /n and /r in text and format correctly */
    white-space: pre-line;
    color: ${setColor.mainGrey};
    height: 80%;
    /* // ::-webkit-scrollbar {
    //   display: none;
    // } */
  }
  .sliderItemModal_description {
    position: absolute;
    /* top: 0;
    width: 100%; */
    opacity: ${(props) =>
      !props.requestMeetingIsOpen && !props.chatIsOpen ? `1` : `0`};
    visibility: ${(props) =>
      !props.requestMeetingIsOpen && !props.chatIsOpen ? 'visible' : 'hidden'};
    height: 100%;
  }
  .description {
    flex: 1;
    padding-top: 5rem;
    padding-left: 2rem;
    padding-right: 5rem;
    img {
      height: 20rem;
      width: 20rem;
      object-fit: contain;
    }
    p {
      padding-bottom: 2rem;
    }
  }
`;

const ChatDiv = styled.div`
  visibility: ${(props) => (props.chatIsOpen ? `visible` : `hidden`)};
  opacity: ${(props) => (props.chatIsOpen ? `1` : `0`)};
  /* transition: visibility 1s;
  transition: opacity 0.3s ease; */
  position: ${(props) => (props.chatIsOpen ? `fixed` : `absolute`)};
  top: 0;
  left: 0;
  bottom: 0;
  height: 90%;
  p {
    visibility: ${(props) => (props.chatIsOpen ? `visible` : `hidden`)};
    opacity: ${(props) => (props.chatIsOpen ? `1` : `0`)};
  }
  object {
    height: 100%;
    width: 100%;
    z-index: 200;
    color: white;
  }
`;

const AuditoriumDiv = styled.div`
  visibility: ${(props) => (props.auditoriumIsOpen ? `visible` : `hidden`)};
  opacity: ${(props) => (props.auditoriumIsOpen ? `1` : `0`)};
  /* transition: visibility 1s;
  transition: opacity 0.3s ease; */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 90%;
  p {
    visibility: ${(props) => (props.auditoriumIsOpen ? `visible` : `hidden`)};
    opacity: ${(props) => (props.auditoriumIsOpen ? `1` : `0`)};
  }
  object {
    height: 100%;
    width: 100%;
    z-index: 200;
    color: white;
  }
`;

const RequestMeetingDiv = styled.div`
  opacity: ${(props) =>
    props.requestMeetingIsOpen && !props.chatIsOpen ? `1` : `0`};
  visibility: ${(props) =>
    props.requestMeetingIsOpen && !props.chatIsOpen ? 'visible' : 'hidden'};
  position: ${(props) => (props.requestMeetingIsOpen ? `sticky` : `absolute`)};
  color: black;
  top: 0;
  left: 0;
  bottom: 0;
  height: 90%;
  width: 100%;
  p {
    opacity: ${(props) => (props.requestMeetingIsOpen ? `1` : `0`)};
    visibility: ${(props) =>
      props.requestMeetingIsOpen ? 'visible' : 'hidden'};
  }
  .RequestMeetingInnerContainer {
    height: 100%;
    width: 94%;
    z-index: 300;
    color: black;
    background-color: white;
  }
  .Request_chat {
    flex: 0.3;
  }
`;

const LinkButton = styled.a`
  display: block;
  background-color: ${setColor.secondaryBlue};
  width: fit-content;
  margin: 3rem auto;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  color: white;
`;
