import React, { useContext, useState } from 'react';

import styled, { css, keyframes } from 'styled-components';
import { setColor } from './styles';

import IconContext from '../context/IconContext';

import Tooltip from '@material-ui/core/Tooltip';

const shockwave = keyframes`
  0% {
    box-shadow: 0 0 2px ${setColor.secondaryBlue};
    opacity: 1;
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 50px ${setColor.tertiaryBlue}, inset 0 0 10px ${setColor.secondaryBlue};
}
`;

const IconDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  padding: ${(props) => (props.small ? '2px' : '5px')};
  border: ${(props) => (props.small ? '1px' : '2px')} white solid;
  border-radius: 50%;
  transition: 0.2s;
  margin: ${(props) => (props.small ? '0' : '5px 0')};
  /* stroke: ${(props) =>
    props.small ? props.iconColor : `${setColor.mainGrey}`}; */
  /* stroke: white; */
  /* stroke-width: ${(props) => (props.small ? '0' : '1px')}; */
  position: relative;
  height: ${(props) => (props.small ? '30px' : '50px')};
  width: ${(props) => (props.small ? '30px' : '50px')};
  /* fill: ${(props) => props.iconColor}; */
  fill: rgba(255, 255, 255, 0.6);

  :hover {
    transform: scale(1.1);
    transition: all 0.2s;
  }

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50%;
    text-align: center;
    height: ${(props) => (props.small ? '28px' : '47px')};
    width: ${(props) => (props.small ? '28px' : '47px')};
    transition: all 0.2s;
  }

  :hover:before {
    animation: ${shockwave} 1s ease-in infinite;
  }

  :active {
    transform: scale(1.2);
    transition: all 0.2s;
  }
`;

const IconsContainer = ({
  children,
  tooltipTitle,
  handleClick,
  switchButton,
}) => {
  const {
    small,
    iconColor,
    tooltipDelay,
    tooltipPlacement,
    tooltipFontSize,
  } = useContext(IconContext);
  const [isHover, setIsHover] = useState(false);
  return (
    <Tooltip
      title={
        <h1
          style={{
            fontSize: tooltipFontSize,
            color: 'white',
            fontFamily: 'Noto Sans JP',
            fontWeight: 300,
            userSelect: 'none',
          }}
        >
          {tooltipTitle}
        </h1>
      }
      placement={tooltipPlacement}
      arrow
      enterDelay={tooltipDelay}
    >
      <IconDiv
        small={small}
        switchButton={switchButton}
        iconColor={iconColor}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={(event) => {
          event.stopPropagation();
          //console.log('HANDLE CLICK', handleClick);
          if (handleClick) handleClick();
          //handler goes here
          //console.log('CLICKED!!!!');
        }}
      >
        {children}
      </IconDiv>
    </Tooltip>
  );
};

const StyledIconsContainer = styled(IconsContainer)`
  background-color: black;
`;

export const PhoneIcon = ({ tooltipTitle, switchButton }) => {
  const { iconHandlers } = useContext(IconContext);
  return (
    <StyledIconsContainer
      tooltipTitle={tooltipTitle}
      handleClick={switchButton && iconHandlers?.handleZoomCall}
    >
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 40">
        <g>
          <path d="M28,15.142V13c0-6.6172-5.3828-12-12-12S4,6.3828,4,13v2.142C2.2795,15.5891,1,17.1413,1,19v3c0,2.2061,1.7939,4,4,4h3   c0.5527,0,1-0.4473,1-1v-9c0-0.5527-0.4473-1-1-1H6v-2C6,7.4863,10.4863,3,16,3s10,4.4863,10,10v2h-2c-0.5527,0-1,0.4473-1,1v9   c0,0.5527,0.4473,1,1,1h1c0,0.5518-0.4482,1-1,1h-4v-1c0-0.5527-0.4473-1-1-1h-4c-1.6543,0-3,1.3457-3,3s1.3457,3,3,3h4   c0.5527,0,1-0.4473,1-1v-1h4c1.6543,0,3-1.3457,3-3c2.2061,0,4-1.7939,4-4v-3C31,17.1413,29.7205,15.5891,28,15.142z M7,24H5   c-1.1025,0-2-0.8975-2-2v-3c0-1.1025,0.8975-2,2-2h2V24z M18,29h-3c-0.5518,0-1-0.4482-1-1s0.4482-1,1-1h3V29z M29,22   c0,1.1025-0.8975,2-2,2h-2v-7h2c1.1025,0,2,0.8975,2,2V22z" />
        </g>
      </svg>
    </StyledIconsContainer>
  );
};

export const AuditoriumIcon = ({ tooltipTitle, switchButton }) => {
  const { iconHandlers } = useContext(IconContext);
  return (
    <StyledIconsContainer
      tooltipTitle={tooltipTitle}
      handleClick={iconHandlers?.handleAuditoriumClick}
    >
      <svg
        id="Capa_1"
        enable-background="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="m421 106c0 41.353 33.647 75 75 75h16v-181h-91z" />
          <path d="m248 153.007v25.986l23-12.993z" />
          <path d="m121 0v106c0 38.39-20.94 71.686-51.78 90 30.84 18.314 51.78 51.61 51.78 90v46h270v-46c0-38.39 20.94-71.686 51.78-90-30.84-18.314-51.78-51.61-51.78-90v-106zm97 230.937v-129.903l113 64.966z" />
          <path d="m421 286v46h91v-121h-16c-41.353 0-75 33.647-75 75z" />
          <path d="m91 286c0-41.353-33.647-75-75-75h-16v121h91z" />
          <path d="m91 106v-106h-91v181h16c41.353 0 75-33.647 75-75z" />
          <path d="m106 504.5c0-28.995-23.505-52.5-52.5-52.5s-53.5 23.505-53.5 52.5v7.5h106z" />
          <path d="m241 504.5c0-28.995-23.505-52.5-52.5-52.5s-52.5 23.505-52.5 52.5v7.5h105z" />
          <path d="m376 504.5c0-28.995-23.505-52.5-52.5-52.5s-52.5 23.505-52.5 52.5v7.5h105z" />
          <path d="m458.5 452c-28.995 0-52.5 23.505-52.5 52.5v7.5h106v-7.5c0-28.995-24.505-52.5-53.5-52.5z" />
          <path d="m174 423.516v-9.016c0-28.995-24.005-52.5-53-52.5s-52 23.505-52 52.5v9.016c21.652 4.003 39.814 16.423 52 33.803 12.186-17.38 31.348-29.8 53-33.803z" />
          <path d="m256 457.319c12.186-17.38 31.348-29.8 53-33.803v-9.016c0-28.995-24.005-52.5-53-52.5s-52 23.505-52 52.5v9.016c21.652 4.003 39.814 16.423 52 33.803z" />
          <path d="m391 457.319c12.186-17.38 31.348-29.8 53-33.803v-9.016c0-28.995-24.005-52.5-53-52.5s-52 23.505-52 52.5v9.016c21.652 4.003 39.814 16.423 52 33.803z" />
        </g>
      </svg>
    </StyledIconsContainer>
  );
};

export const IdIcon = ({ tooltipTitle, switchButton }) => {
  const { iconHandlers, small } = useContext(IconContext);

  const [animate, setAnimate] = useState(false);

  const handleAnimate = () => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  };

  return (
    <StyledIconsContainer
      tooltipTitle={tooltipTitle}
      handleClick={iconHandlers?.handleDropCard}
    >
      <DropCardDiv animate={animate} onClick={handleAnimate} small={small}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 80"
          x="0px"
          y="0px"
        >
          <g data-name="Business Card">
            <path d="M59,14H23a3,3,0,0,0-3,3v9H17a1,1,0,0,0-.707.293L12,30.586V30a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1V48a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1H23a1,1,0,0,0,.5-.134L30.194,44H59a3,3,0,0,0,3-3V17A3,3,0,0,0,59,14ZM10,47H4V31h6Zm50-6a1,1,0,0,1-1,1H33.657l1.96-1.132a4.995,4.995,0,0,0,1.84-6.826A5.019,5.019,0,0,0,30.62,32.2l-8.12,4.69,1,1.731,8.116-4.687a3.041,3.041,0,0,1,1.5-.4,3,3,0,0,1,2.9,3.781,2.974,2.974,0,0,1-1.4,1.813L22.732,46H12V33.414L17.414,28H20v9h2V17a1,1,0,0,1,1-1H59a1,1,0,0,1,1,1Z" />
            <path d="M32,18a6,6,0,1,0,6,6A6.006,6.006,0,0,0,32,18Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,32,28Z" />
            <rect x="46" y="20" width="10" height="2" />
            <rect x="42" y="20" width="2" height="2" />
            <rect x="42" y="24" width="14" height="2" />
            <rect x="40" y="32" width="6" height="2" />
            <rect x="48" y="32" width="8" height="2" />
            <rect x="40" y="36" width="16" height="2" />
          </g>
        </svg>
      </DropCardDiv>
    </StyledIconsContainer>
  );
};

const thankYouAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%) rotate(0);
  }
  50% {
    opacity: 1;
    transform: translateX(-100%) rotate(0);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-200%) rotate(-45deg);
  }

`;

const DropCardDiv = styled.div`
  ::before {
    display: block;
    content: 'sent!';
    position: absolute;
    color: ${setColor.primaryBlue};
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => (props.small ? `1.2rem` : `2rem`)};

    ${(props) =>
      props.animate
        ? css`
            animation: 1s ${thankYouAnimation};
          `
        : null}
  }
`;

export const GiftIcon = ({ tooltipTitle }) => (
  <StyledIconsContainer tooltipTitle={tooltipTitle}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 847 1058.75"
    >
      <g>
        <path d="M243 175l0 81c49,-75 133,16 75,63l457 0 0 -90c0,-30 -24,-54 -54,-54l-478 0zm0 247l0 249 478 0c30,0 54,-24 54,-53l0 -265 -465 0c73,47 -17,146 -67,69zm-33 249l0 -244c-44,68 -138,-26 -64,-74l-74 0 0 265c0,29 24,53 54,53l84 0zm0 -419l0 -77 -84 0c-30,0 -54,24 -54,54l0 90 66 0c-59,-48 28,-134 72,-67zm-84 -111l595 0c48,0 88,40 88,88l0 389c0,48 -40,87 -88,87l-595 0c-48,0 -88,-39 -88,-87l0 -389c0,-48 40,-88 88,-88zm79 173c-25,-96 -103,-26 0,0zm46 0c103,-26 25,-96 0,0zm-46 50c-103,26 -25,96 0,0zm46 0c25,96 103,26 0,0z" />
      </g>
    </svg>
  </StyledIconsContainer>
);

export const ArrowIcon = ({ tooltipTitle, handleClick }) => {
  const { iconHandlers } = useContext(IconContext);
  return (
    <StyledIconsContainer
      tooltipTitle={tooltipTitle}
      handleClick={iconHandlers?.handleOpenCompanyInfoModal}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 100 125"
      >
        <path d="M95.4,45L66.1,15.7c-2.7-2.7-7.2-2.7-9.9,0c-2.7,2.7-2.7,7.2,0,9.9L73.5,43h-64c-3.9,0-7,3.1-7,7s3.1,7,7,7h64L56.2,74.3    c-2.7,2.7-2.7,7.2,0,9.9c1.4,1.4,3.2,2.1,5,2.1s3.6-0.7,5-2.1L95.4,55c1.3-1.3,2.1-3.1,2.1-5C97.5,48.1,96.8,46.3,95.4,45z" />
      </svg>
    </StyledIconsContainer>
  );
};

export const ChatIcon = ({ tooltipTitle, switchButton }) => {
  const { iconHandlers, chatIsOpen } = useContext(IconContext);
  return (
    <StyledIconsContainer
      style={
        !switchButton
          ? {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              borderRadius: '50%',
              height: '50px',
              width: '50px',
            }
          : {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              borderRadius: '50%',
              height: '50px',
              width: '50px',
            }
      }
      tooltipTitle={tooltipTitle}
      handleClick={switchButton && iconHandlers?.handleChatClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 100 125"
      >
        {chatIsOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 6.82666 8.533325000000001"
          >
            <g>
              <path d="M4.03757 2.8957l0 2.32006c0,0.165913 -0.0659921,0.323776 -0.183224,0.441012 -0.117236,0.117232 -0.275094,0.183224 -0.441008,0.183224 -0.165917,0 -0.323776,-0.0659921 -0.441016,-0.183224 -0.117232,-0.117236 -0.183224,-0.275098 -0.183224,-0.441012l0 -2.32006c0,-0.165913 0.0659961,-0.323768 0.183224,-0.441004 0.11724,-0.117232 0.275091,-0.183224 0.441008,-0.183224 0.165921,0 0.323776,0.0659882 0.441016,0.183224 0.117228,0.117236 0.183224,0.275091 0.183224,0.441004zm-0.341335 2.32006l0 -2.32006c0,-0.0752756 -0.0300827,-0.146496 -0.0832402,-0.199654 -0.0531614,-0.0531614 -0.124386,-0.0832441 -0.199665,-0.0832441 -0.0752795,0 -0.146496,0.0300866 -0.199657,0.0832441 -0.0531575,0.0531575 -0.0832402,0.124378 -0.0832402,0.199654l0 2.32006c0,0.0752756 0.0300827,0.1465 0.0832402,0.199661 0.0531614,0.0531614 0.124386,0.0832402 0.199665,0.0832402 0.0752795,0 0.146496,-0.0300827 0.199657,-0.0832402 0.0531575,-0.0531614 0.0832402,-0.124386 0.0832402,-0.199661zm-0.282902 -4.49576c0.169846,0 0.331449,0.0669528 0.451524,0.187031 0.120075,0.120075 0.187031,0.281677 0.187031,0.45152 0,0.169846 -0.0669567,0.331453 -0.187031,0.451524 -0.120079,0.120075 -0.281677,0.187035 -0.451524,0.187035 -0.169846,0 -0.331445,-0.0669606 -0.451524,-0.187035 -0.120075,-0.120071 -0.187031,-0.281677 -0.187031,-0.451524 0,-0.169843 0.0669567,-0.331445 0.187031,-0.45152 0.120075,-0.120079 0.281677,-0.187031 0.451524,-0.187031zm0.210173 0.428382c-0.0559843,-0.0559843 -0.130992,-0.0870512 -0.210173,-0.0870512 -0.0791811,0 -0.154189,0.0310669 -0.210173,0.0870512 -0.0559843,0.0559843 -0.0870512,0.130988 -0.0870512,0.210169 0,0.079185 0.0310709,0.154189 0.0870512,0.210173 0.0559843,0.0559843 0.130988,0.0870512 0.210173,0.0870512 0.079185,0 0.154189,-0.0310669 0.210173,-0.0870512 0.0559803,-0.0559843 0.0870512,-0.130988 0.0870512,-0.210173 0,-0.0791811 -0.0310669,-0.154185 -0.0870512,-0.210169z" />
            </g>
          </svg>
        ) : (
          <g transform="translate(0,-952.36218)">
            <path
              transform="translate(0,-952.36218)"
              d="M 43 12 C 38.048601 12 34 16.04858 34 21 L 34 40 L 15 40 C 10.048595 40 6 44.04857 6 49 L 6 71 C 6 75.9514 10.048595 80 15 80 L 15 86 A 2.0001999 2.0001999 0 0 0 18.0625 87.71875 L 30.59375 80 L 57 80 C 61.951405 80 66 75.9514 66 71 L 66 52 L 69.40625 52 L 81.9375 59.71875 A 2.0001999 2.0001999 0 0 0 85 58 L 85 52 C 89.951405 52 94 47.95142 94 43 L 94 21 C 94 16.04858 89.951399 12 85 12 L 43 12 z M 43 16 L 85 16 C 87.804601 16 90 18.19538 90 21 L 90 43 C 90 45.80459 87.804595 48 85 48 L 83 48 A 2.0001999 2.0001999 0 0 0 81 50 L 81 54.40625 L 71.0625 48.28125 A 2.0001999 2.0001999 0 0 0 70 48 L 65.9375 48 C 65.430368 43.521121 61.609066 40 57 40 L 38 40 L 38 21 C 38 18.19538 40.195399 16 43 16 z M 45.8125 22 A 2.0021961 2.0021961 0 1 0 46 26 L 82 26 A 2.0001999 2.0001999 0 1 0 82 22 L 46 22 A 2.0001999 2.0001999 0 0 0 45.8125 22 z M 45.8125 30 A 2.0021961 2.0021961 0 1 0 46 34 L 82 34 A 2.0001999 2.0001999 0 1 0 82 30 L 46 30 A 2.0001999 2.0001999 0 0 0 45.8125 30 z M 69.8125 38 A 2.0021961 2.0021961 0 1 0 70 42 L 82 42 A 2.0001999 2.0001999 0 1 0 82 38 L 70 38 A 2.0001999 2.0001999 0 0 0 69.8125 38 z M 15 44 L 57 44 C 59.804595 44 62 46.19539 62 49 L 62 71 C 62 73.8046 59.804595 76 57 76 L 30 76 A 2.0001999 2.0001999 0 0 0 28.9375 76.28125 L 19 82.40625 L 19 78 A 2.0001999 2.0001999 0 0 0 17 76 L 15 76 C 12.195405 76 10 73.8046 10 71 L 10 49 C 10 46.19539 12.195405 44 15 44 z M 17.8125 50 A 2.0021961 2.0021961 0 1 0 18 54 L 54 54 A 2.0001999 2.0001999 0 1 0 54 50 L 18 50 A 2.0001999 2.0001999 0 0 0 17.8125 50 z M 17.8125 58 A 2.0021961 2.0021961 0 1 0 18 62 L 54 62 A 2.0001999 2.0001999 0 1 0 54 58 L 18 58 A 2.0001999 2.0001999 0 0 0 17.8125 58 z M 17.8125 66 A 2.0021961 2.0021961 0 1 0 18 70 L 34 70 A 2.0001999 2.0001999 0 1 0 34 66 L 18 66 A 2.0001999 2.0001999 0 0 0 17.8125 66 z "
              transform="translate(0,952.36218)"
            />
          </g>
        )}
      </svg>
    </StyledIconsContainer>
  );
};

export const CalendarIcon = ({ tooltipTitle }) => {
  const { iconHandlers, requestMeetingIsOpen } = useContext(IconContext);
  return (
    <StyledIconsContainer
      tooltipTitle={tooltipTitle}
      handleClick={iconHandlers?.handleRequestMeeting}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="-8 -7 115 125"
      >
        {requestMeetingIsOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 6.82666 8.533325000000001"
            x="-5px"
          >
            <g>
              <path d="M4.03757 2.8957l0 2.32006c0,0.165913 -0.0659921,0.323776 -0.183224,0.441012 -0.117236,0.117232 -0.275094,0.183224 -0.441008,0.183224 -0.165917,0 -0.323776,-0.0659921 -0.441016,-0.183224 -0.117232,-0.117236 -0.183224,-0.275098 -0.183224,-0.441012l0 -2.32006c0,-0.165913 0.0659961,-0.323768 0.183224,-0.441004 0.11724,-0.117232 0.275091,-0.183224 0.441008,-0.183224 0.165921,0 0.323776,0.0659882 0.441016,0.183224 0.117228,0.117236 0.183224,0.275091 0.183224,0.441004zm-0.341335 2.32006l0 -2.32006c0,-0.0752756 -0.0300827,-0.146496 -0.0832402,-0.199654 -0.0531614,-0.0531614 -0.124386,-0.0832441 -0.199665,-0.0832441 -0.0752795,0 -0.146496,0.0300866 -0.199657,0.0832441 -0.0531575,0.0531575 -0.0832402,0.124378 -0.0832402,0.199654l0 2.32006c0,0.0752756 0.0300827,0.1465 0.0832402,0.199661 0.0531614,0.0531614 0.124386,0.0832402 0.199665,0.0832402 0.0752795,0 0.146496,-0.0300827 0.199657,-0.0832402 0.0531575,-0.0531614 0.0832402,-0.124386 0.0832402,-0.199661zm-0.282902 -4.49576c0.169846,0 0.331449,0.0669528 0.451524,0.187031 0.120075,0.120075 0.187031,0.281677 0.187031,0.45152 0,0.169846 -0.0669567,0.331453 -0.187031,0.451524 -0.120079,0.120075 -0.281677,0.187035 -0.451524,0.187035 -0.169846,0 -0.331445,-0.0669606 -0.451524,-0.187035 -0.120075,-0.120071 -0.187031,-0.281677 -0.187031,-0.451524 0,-0.169843 0.0669567,-0.331445 0.187031,-0.45152 0.120075,-0.120079 0.281677,-0.187031 0.451524,-0.187031zm0.210173 0.428382c-0.0559843,-0.0559843 -0.130992,-0.0870512 -0.210173,-0.0870512 -0.0791811,0 -0.154189,0.0310669 -0.210173,0.0870512 -0.0559843,0.0559843 -0.0870512,0.130988 -0.0870512,0.210169 0,0.079185 0.0310709,0.154189 0.0870512,0.210173 0.0559843,0.0559843 0.130988,0.0870512 0.210173,0.0870512 0.079185,0 0.154189,-0.0310669 0.210173,-0.0870512 0.0559803,-0.0559843 0.0870512,-0.130988 0.0870512,-0.210173 0,-0.0791811 -0.0310669,-0.154185 -0.0870512,-0.210169z" />
            </g>
          </svg>
        ) : (
          <g transform="translate(0,-952.36218)">
            <path
              d="m 68.9688,958.36216 c 1.1046,0 2,0.8954 2,2 l 0,12.28125 c 3.4327,0.89897 6,4.01733 6,7.71875 0,4.3945 -3.6054,8.00005 -8,8.00005 -4.3946,0 -8,-3.60555 -8,-8.00005 0,-3.70142 2.5672,-6.81978 6,-7.71875 l 0,-12.28125 c 0,-1.1046 0.8953,-2 2,-2 z m -38,0 c 1.1046,0 2,0.8954 2,2 l 0,12.28125 c 3.4327,0.89897 6,4.01733 6,7.71875 0,4.3945 -3.6054,8.00005 -8,8.00005 -4.3946,0 -8,-3.60555 -8,-8.00005 0,-3.70142 2.5672,-6.81978 6,-7.71875 l 0,-12.28125 c 0,-1.1046 0.8953,-2 2,-2 z m 52,6 c 5.5211,0 10.0312,4.51015 10.0312,10.03125 l 0,61.93749 c 0,5.521 -4.5101,10.0313 -10.0312,10.0313 l -65.9375,0 c -5.5211,0 -10.0312998,-4.5103 -10.0312998,-10.0313 l 0,-61.93749 c 0,-5.52111 4.5101998,-10.03125 10.0312998,-10.03125 l 5.9687,0 c 1.0566,-0.015 2.0313,0.9433 2.0313,2 0,1.05669 -0.9747,2.0149 -2.0313,2 l -5.9687,0 c -3.3704,0 -6.0313,2.66075 -6.0313,6.03125 l 0,17.9688 78,0 0,-17.9688 c 0,-3.3705 -2.6606,-6.03125 -6.0312,-6.03125 l -5.9688,0 c -1.0566,0.015 -2.0312,-0.94331 -2.0312,-2 0,-1.0567 0.9746,-2.0149 2.0312,-2 z m -21.9688,0 c 1.1046,0 2,0.8954 2,2 0,1.1046 -0.8954,2 -2,2 l -22,0 c -1.1046,0 -2,-0.8954 -2,-2 0,-1.1046 0.8954,-2 2,-2 z m 7.9688,12 c -2.2328,0 -4,1.7671 -4,4 0,2.2328 1.7672,4 4,4 2.2328,0 4,-1.7672 4,-4 0,-2.2329 -1.7672,-4 -4,-4 z m -38,0 c -2.2328,0 -4,1.7671 -4,4 0,2.2328 1.7672,4 4,4 2.2328,0 4,-1.7672 4,-4 0,-2.2329 -1.7672,-4 -4,-4 z M 89,996.36221 l -78,0 0,39.96869 c 0,3.3705 2.6609,6.0313 6.0313,6.0313 l 65.9375,0 c 3.3706,0 6.0312,-2.6608 6.0312,-6.0313 z"
              fillOpacity="1"
              marker="none"
              visibility="visible"
              display="inline"
              overflow="visible"
            />
          </g>
        )}
      </svg>
    </StyledIconsContainer>
  );
};

export const PrivateMessageIcon = ({ tooltipTitle }) => {
  const { iconHandlers, requestMeetingIsOpen } = useContext(IconContext);
  return (
    <StyledIconsContainer
      tooltipTitle={tooltipTitle}
      handleClick={iconHandlers?.handleRequestMeeting}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="-8 -7 115 125"
      >
        {requestMeetingIsOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 6.82666 8.533325000000001"
            x="-5px"
          >
            <g>
              <path d="M4.03757 2.8957l0 2.32006c0,0.165913 -0.0659921,0.323776 -0.183224,0.441012 -0.117236,0.117232 -0.275094,0.183224 -0.441008,0.183224 -0.165917,0 -0.323776,-0.0659921 -0.441016,-0.183224 -0.117232,-0.117236 -0.183224,-0.275098 -0.183224,-0.441012l0 -2.32006c0,-0.165913 0.0659961,-0.323768 0.183224,-0.441004 0.11724,-0.117232 0.275091,-0.183224 0.441008,-0.183224 0.165921,0 0.323776,0.0659882 0.441016,0.183224 0.117228,0.117236 0.183224,0.275091 0.183224,0.441004zm-0.341335 2.32006l0 -2.32006c0,-0.0752756 -0.0300827,-0.146496 -0.0832402,-0.199654 -0.0531614,-0.0531614 -0.124386,-0.0832441 -0.199665,-0.0832441 -0.0752795,0 -0.146496,0.0300866 -0.199657,0.0832441 -0.0531575,0.0531575 -0.0832402,0.124378 -0.0832402,0.199654l0 2.32006c0,0.0752756 0.0300827,0.1465 0.0832402,0.199661 0.0531614,0.0531614 0.124386,0.0832402 0.199665,0.0832402 0.0752795,0 0.146496,-0.0300827 0.199657,-0.0832402 0.0531575,-0.0531614 0.0832402,-0.124386 0.0832402,-0.199661zm-0.282902 -4.49576c0.169846,0 0.331449,0.0669528 0.451524,0.187031 0.120075,0.120075 0.187031,0.281677 0.187031,0.45152 0,0.169846 -0.0669567,0.331453 -0.187031,0.451524 -0.120079,0.120075 -0.281677,0.187035 -0.451524,0.187035 -0.169846,0 -0.331445,-0.0669606 -0.451524,-0.187035 -0.120075,-0.120071 -0.187031,-0.281677 -0.187031,-0.451524 0,-0.169843 0.0669567,-0.331445 0.187031,-0.45152 0.120075,-0.120079 0.281677,-0.187031 0.451524,-0.187031zm0.210173 0.428382c-0.0559843,-0.0559843 -0.130992,-0.0870512 -0.210173,-0.0870512 -0.0791811,0 -0.154189,0.0310669 -0.210173,0.0870512 -0.0559843,0.0559843 -0.0870512,0.130988 -0.0870512,0.210169 0,0.079185 0.0310709,0.154189 0.0870512,0.210173 0.0559843,0.0559843 0.130988,0.0870512 0.210173,0.0870512 0.079185,0 0.154189,-0.0310669 0.210173,-0.0870512 0.0559803,-0.0559843 0.0870512,-0.130988 0.0870512,-0.210173 0,-0.0791811 -0.0310669,-0.154185 -0.0870512,-0.210169z" />
            </g>
          </svg>
        ) : (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="-5px"
            y="0px"
            viewBox="0 0 491.52 491.52"
          >
            <g>
              <g>
                <polygon points="295.285,333.8 226.84,402.24 196.24,371.64 181.76,386.12 226.84,431.2 309.765,348.28 		" />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M491.52,0H0v327.68h145.554c-7.893,15.382-12.434,32.756-12.434,51.2c0,62.11,50.53,112.64,112.64,112.64
                  S358.4,440.99,358.4,378.88c0-18.444-4.542-35.818-12.434-51.2H491.52V0z M451.14,20.48L245.76,173.025L40.384,20.48H451.14z
                   M20.48,31.205l147.702,109.708L20.48,292.284V31.205z M245.76,471.04c-50.815,0-92.16-41.345-92.16-92.16
                  s41.345-92.16,92.16-92.16s92.16,41.345,92.16,92.16S296.575,471.04,245.76,471.04z M332.581,307.2
                  c-20.677-25.001-51.919-40.96-86.821-40.96s-66.143,15.959-86.821,40.96H34.539l150.233-153.965l60.988,45.3l60.992-45.302
                  L456.982,307.2H332.581z M471.04,292.283L323.342,140.911L471.04,31.208V292.283z"
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        )}
      </svg>
    </StyledIconsContainer>
  );
};

const Close = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 100 125"
      >
        <path d="M61.2,50.5l32.1,32.1c3,3,3,7.7,0,10.7c-3,3-7.7,3-10.7,0L50.5,61.2L18.4,93.3c-3,3-7.7,3-10.7,0  c-3-3-3-7.7,0-10.7l32.1-32.1L7.7,18.4c-3-3-3-7.7,0-10.7s7.7-3,10.7,0l32.1,32.1L82.6,7.7c3-3,7.7-3,10.7,0c3,3,3,7.7,0,10.7  L61.2,50.5z" />
      </svg>
    </div>
  );
};

export const EmojiIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10" />
      <path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0" />
    </svg>
  );
};

export const CloseIcon = styled(Close)`
  fill: #a5a5a5;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    fill: ${setColor.primaryBlue};
    transition: 0.2s;
  }
`;

export const SigninIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="3 0 48 60">
      <g>
        <path d="M42,7.5H18c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5h22.5v27H18c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5h24   c0.83,0,1.5-0.67,1.5-1.5V9C43.5,8.17,42.83,7.5,42,7.5z" />
        <path d="M27.06,30.83c-0.64,0.52-0.75,1.46-0.23,2.11c0.29,0.36,0.71,0.56,1.17,0.56c0.34,0,0.67-0.12,0.94-0.33l10-8   c0.13-0.11,0.24-0.24,0.34-0.39l0.14-0.27l0.01-0.01l-0.01-0.01l0.08-0.22l-0.01-0.09c0.03-0.2,0.01-0.4-0.04-0.59   c-0.01-0.03-0.02-0.05-0.03-0.08l0.01-0.01h-0.01c-0.06-0.16-0.14-0.31-0.25-0.44c-0.01-0.01-0.02-0.02-0.02-0.03l-10.21-8.2   c-0.64-0.51-1.59-0.41-2.11,0.24c-0.52,0.65-0.41,1.59,0.23,2.11l6.66,5.33H6c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5h27.72   L27.06,30.83z" />
      </g>
    </svg>
  );
};

export const SignupIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="5 0 100 125">
      <path d="M61.862,52.887h1.127c7.596,0,13.775-6.18,13.775-13.777v-9.162c0-7.596-6.18-13.775-13.775-13.775h-1.127  c-7.597,0-13.777,6.18-13.777,13.775v9.162C48.085,46.707,54.266,52.887,61.862,52.887z M53.085,29.947  c0-4.838,3.938-8.775,8.777-8.775h1.127c4.839,0,8.775,3.938,8.775,8.775v9.162c0,4.84-3.937,8.777-8.775,8.777h-1.127  c-4.84,0-8.777-3.938-8.777-8.777V29.947z M92.257,68.025c-0.615-1.367-1.359-1.965-2.071-2.463  c-2.111-1.471-13.315-8.787-27.335-8.936c-0.105-0.01-0.214-0.01-0.321-0.008c-0.15-0.004-0.219,0-0.312,0.008  c-14.025,0.146-25.224,7.465-27.333,8.936c-0.708,0.492-1.451,1.09-2.074,2.465c-0.468,1.039-0.505,2.465-0.499,3.004v10.297  c0,1.381,1.119,2.5,2.5,2.5h55.447c1.381,0,2.5-1.119,2.5-2.5V71.057C92.765,70.492,92.728,69.064,92.257,68.025z M87.759,78.828  H37.312v-7.822c-0.003-0.305,0.038-0.83,0.057-0.922c0.104-0.23,0.104-0.23,0.375-0.42c1.169-0.814,11.794-7.963,24.696-8.039h0.189  c12.898,0.076,23.529,7.225,24.695,8.037c0.272,0.191,0.272,0.191,0.344,0.34c0.049,0.164,0.093,0.654,0.091,1.029V78.828z   M40.24,43.852c0-1.381-1.119-2.5-2.5-2.5h-11.5v-11.5c0-1.381-1.119-2.5-2.5-2.5s-2.5,1.119-2.5,2.5v11.5H9.74  c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h11.5v11.5c0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5v-11.5h11.5  C39.121,46.352,40.24,45.232,40.24,43.852z" />
    </svg>
  );
};

export const InformationalIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 125">
      <g>
        <path d="M50,5C25.3,5,5.2,25.1,5,49.7c-0.1,12,4.5,23.4,13,31.9c8.4,8.6,19.7,13.3,31.7,13.4c0,0,0.3,0,0.3,0     c24.7,0,44.8-20.1,45-44.7C95.2,25.5,75.1,5.2,50,5z M50,88.9h-0.3c-10.4-0.1-20.1-4.2-27.5-11.6C15,70,11,60.2,11.1,49.8     c0.1-21.3,17.6-38.7,39.2-38.7c21.5,0.1,38.8,17.7,38.7,39.2C88.8,71.6,71.3,88.9,50,88.9z" />
        <path d="M50,60c1.9,0,3.4-1.5,3.4-3.4v-28c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4v28C46.6,58.5,48.1,60,50,60z" />
        <path d="M50,66.6c-2.5,0-4.6,2.1-4.6,4.6v0.2c0,2.5,2.1,4.6,4.6,4.6c2.5,0,4.6-2.1,4.6-4.6v-0.2C54.6,68.7,52.5,66.6,50,66.6z" />
      </g>
    </svg>
  );
};

const Loading = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 100 100"
      >
        <g>
          <g>
            <g>
              <path d="M87.874,57.844c-0.46,0.364-1.061,0.594-1.812,0.594c-0.626,0-1.139-0.169-1.561-0.433     c-0.035,0.231-0.104,0.46-0.189,0.686c-0.021,0.127-0.059,0.249-0.095,0.37c-0.017,0.104-0.04,0.202-0.067,0.301     C81.247,75.748,66.957,88.25,49.75,88.25c-19.299,0-35-15.701-35-35s15.701-35,35-35c11.2,0,21.167,5.304,27.575,13.517     c2.371-2.371,4.742-4.742,7.113-7.113C76.185,14.646,63.707,8.25,49.75,8.25c-24.813,0-45,20.187-45,45c0,24.813,20.187,45,45,45     c22.369,0,40.969-16.409,44.415-37.821C92.027,59.696,89.978,58.734,87.874,57.844z" />
            </g>
          </g>
          <g>
            <line x1="83" y1="30" x2="60" y2="30" />
            <path d="M83,35H60c-2.762,0-5-2.239-5-5s2.238-5,5-5h23c2.762,0,5,2.239,5,5S85.762,35,83,35z" />
          </g>
          <g>
            <line x1="83" y1="30" x2="83" y2="7" />
            <path d="M83,35c-2.762,0-5-2.239-5-5V7c0-2.761,2.238-5,5-5s5,2.239,5,5v23C88,32.761,85.762,35,83,35z" />
          </g>
          <g>
            <circle cx="89.249" cy="59.5" r="5" />
          </g>
        </g>
      </svg>
    </div>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(Loading)`
  fill: inherit;
  svg {
    animation: ${rotate} 2s linear infinite;
  }
  display: inline-block;
  width: 2rem;
  transform: translateY(3px);
  fill: #d1d1d1;
`;

export const ExhibitorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 640">
      <g>
        <path d="M443.562,49.578H265.094V9.125C265.094,4.094,261.031,0,256,0s-9.102,4.094-9.102,9.125v40.453H68.43   c-21.367,0-38.758,17.391-38.758,38.766c0,14.688,8.156,27.938,21.281,34.594l3.141,1.594v215.938   c0,13.188,10.734,23.938,23.938,23.938h168.867v43.281l-117.945,87.891c-4.023,3-4.859,8.719-1.867,12.734   c2.922,3.922,8.828,4.781,12.758,1.875l107.055-79.781v72.484c0,5.016,4.07,9.109,9.102,9.109s9.094-4.094,9.094-9.109v-72.484   l107.062,79.781c3.938,2.906,9.844,2.047,12.75-1.875c1.453-1.938,2.062-4.344,1.703-6.734c-0.359-2.406-1.609-4.547-3.578-6   l-117.938-87.891v-43.281h168.875c13.203,0,23.938-10.75,23.938-23.938V124.531l3.125-1.594   c13.141-6.656,21.297-19.922,21.297-34.594C482.328,66.969,464.938,49.578,443.562,49.578z M439.688,346.188H72.305V127.125   h367.383V346.188z M443.562,108.891H68.43c-11.328,0-20.555-9.219-20.555-20.547c0-11.344,9.227-20.562,20.555-20.562h375.133   c11.344,0,20.562,9.219,20.562,20.562C464.125,99.672,454.906,108.891,443.562,108.891z" />
        <path d="M165.07,291.75l42.5-42.5l38.039,38.031c3.516,3.531,9.25,3.562,12.703,0.344l101.406-101.422   c3.641-3.641,3.641-9.406,0.094-12.969c-3.438-3.422-9.438-3.422-12.875,0.016l-94.812,94.812l-38.117-38.156   c-1.727-1.703-4.031-2.656-6.469-2.656c-2.422,0-4.719,0.938-6.438,2.656l-48.914,48.969c-3.547,3.562-3.555,9.344,0,12.875   C155.617,295.188,161.617,295.188,165.07,291.75z" />
      </g>
    </svg>
  );
};

export const SoundIcon = ({ className, tooltipTitle }) => {
  const { iconHandlers, videoIsMuted } = useContext(IconContext);
  //console.log('VIDEO IS MUTED');
  return (
    <StyledIconsContainer
      tooltipTitle={tooltipTitle}
      handleClick={iconHandlers?.handleToggleVideoSound}
    >
      {videoIsMuted ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="-255 334 100 125"
        >
          <g>
            <path d="M-206.1,358.5v11.6l6.3,6.3v-23.8c0-1.2-0.6-2.2-1.7-2.8c-1-0.5-2.3-0.5-3.2,0.2l-12.8,8.7l4.5,4.5L-206.1,358.5z" />
            <path
              d="M-190.6,384c0,0.5,0,1-0.1,1.5l5.2,5.2c0.8-2.1,1.2-4.3,1.2-6.6c0-3.9-1.2-7.6-3.5-10.8c-1-1.4-3-1.7-4.4-0.7
		c-1.4,1-1.7,3-0.7,4.4C-191.4,379-190.6,381.4-190.6,384z"
            />
            <path
              d="M-177.5,384c0,4.1-1,8.2-2.9,11.8l4.6,4.6c3-4.9,4.6-10.6,4.6-16.4c0-7.4-2.6-14.6-7.4-20.3c-1.1-1.3-3.1-1.5-4.4-0.4
		c-1.3,1.1-1.5,3.1-0.4,4.4C-179.6,372.3-177.5,378-177.5,384z"
            />
            <path
              d="M-164.3,384c0,7.7-2.3,15.1-6.5,21.4l4.5,4.5c5.4-7.5,8.3-16.6,8.3-25.9c0-11-4-21.5-11.3-29.7c-1.2-1.3-3.1-1.4-4.4-0.3
		c-1.3,1.1-1.4,3.1-0.3,4.4C-167.7,365.5-164.3,374.6-164.3,384z"
            />
            <path
              d="M-172.3,421c2,2,4.2,1,4.8,0.4c1.1-1.2,0.8-3.5-0.4-4.8l-71-71c-1.1-1.1-2.7-1.3-4-0.5c-1.7,1.1-1.6,3.7-0.3,5l15.3,15.3
		c0,0,0,0.1,0,0.1l-2.1,1.4c0,0,0,0-0.1,0h-18.7c-1.8,0-3.3,1.5-3.3,3.3v27.6c0,1.7,1.4,3.1,3.2,3.1h18.8c0,0,0,0,0.1,0l25.3,17
		c0.5,0.4,1.1,0.5,1.8,0.5c0.5,0,1-0.1,1.5-0.4c1-0.6,1.7-1.6,1.7-2.8v-21.5c0-0.1,0.1-0.1,0.2-0.1 M-232.2,394.7h-13.4
		c-0.1,0-0.1,0-0.1-0.1v-21.2c0-0.1,0-0.1,0.1-0.1h13.4c0.1,0,0.1,0,0.1,0.1L-232.2,394.7C-232.1,394.7-232.1,394.7-232.2,394.7z
		 M-206.1,409.5l-19.8-13.3c0,0,0,0,0-0.1v-24.3c0,0,0-0.1,0-0.1l2.4-1.6c0,0,0.1,0,0.1,0l17.1,17.1c0,0,0,0,0,0.1L-206.1,409.5
		L-206.1,409.5z"
            />
          </g>
        </svg>
      ) : (
        <svg version="1.1" viewBox="0 0 100 125">
          <g>
            <path d="M53.6,15.4c-1-0.6-2.3-0.5-3.3,0.2L24.8,32.8H5.7c-1.8,0-3.2,1.4-3.2,3.2v28c0,1.8,1.4,3.2,3.2,3.2h19.1l25.5,17.2     c0.5,0.4,1.2,0.5,1.8,0.5c0.5,0,1-0.1,1.5-0.4c1-0.6,1.7-1.6,1.7-2.8V18.2C55.2,17.1,54.6,16,53.6,15.4z M8.8,39.2h13.7v21.6H8.8     V39.2z M48.9,75.8l-20-13.5V37.7l20-13.5V75.8z" />
            <path d="M63,38.4c-1.4,1-1.8,3-0.7,4.4c1.5,2.1,2.3,4.6,2.3,7.2s-0.8,5.1-2.3,7.2c-1,1.4-0.7,3.4,0.7,4.4     c0.6,0.4,1.2,0.6,1.8,0.6c1,0,2-0.5,2.6-1.3c2.3-3.2,3.5-7,3.5-10.9s-1.2-7.7-3.5-10.9C66.4,37.7,64.4,37.3,63,38.4z" />
            <path d="M76.7,29.4c-1.1-1.3-3.1-1.5-4.5-0.4c-1.3,1.1-1.5,3.1-0.4,4.5c3.9,4.6,6,10.5,6,16.5c0,6-2.1,11.9-6,16.5     c-1.1,1.3-0.9,3.3,0.4,4.5c0.6,0.5,1.3,0.7,2,0.7c0.9,0,1.8-0.4,2.4-1.1c4.8-5.8,7.4-13.1,7.4-20.6     C84.2,42.5,81.6,35.2,76.7,29.4z" />
            <path d="M86.1,19.9c-1.2-1.3-3.2-1.4-4.5-0.3c-1.3,1.2-1.4,3.2-0.3,4.5c6.3,7.1,9.8,16.3,9.8,25.9c0,9.5-3.5,18.7-9.8,25.9     c-1.2,1.3-1,3.3,0.3,4.5c0.6,0.5,1.4,0.8,2.1,0.8c0.9,0,1.8-0.4,2.4-1.1c7.3-8.3,11.4-19,11.4-30.1S93.5,28.2,86.1,19.9z" />
          </g>
        </svg>
      )}
    </StyledIconsContainer>
  );
};

export const ModalSoundIcon = ({ className, tooltipTitle }) => {
  const { iconHandlers, modalVideoIsMuted } = useContext(IconContext);
  //console.log('VIDEO IS MUTED');
  return (
    <StyledIconsContainer
      tooltipTitle={tooltipTitle}
      handleClick={iconHandlers?.setModalVideoMute}
    >
      {modalVideoIsMuted ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="-255 334 100 125"
        >
          <g>
            <path d="M-206.1,358.5v11.6l6.3,6.3v-23.8c0-1.2-0.6-2.2-1.7-2.8c-1-0.5-2.3-0.5-3.2,0.2l-12.8,8.7l4.5,4.5L-206.1,358.5z" />
            <path
              d="M-190.6,384c0,0.5,0,1-0.1,1.5l5.2,5.2c0.8-2.1,1.2-4.3,1.2-6.6c0-3.9-1.2-7.6-3.5-10.8c-1-1.4-3-1.7-4.4-0.7
		c-1.4,1-1.7,3-0.7,4.4C-191.4,379-190.6,381.4-190.6,384z"
            />
            <path
              d="M-177.5,384c0,4.1-1,8.2-2.9,11.8l4.6,4.6c3-4.9,4.6-10.6,4.6-16.4c0-7.4-2.6-14.6-7.4-20.3c-1.1-1.3-3.1-1.5-4.4-0.4
		c-1.3,1.1-1.5,3.1-0.4,4.4C-179.6,372.3-177.5,378-177.5,384z"
            />
            <path
              d="M-164.3,384c0,7.7-2.3,15.1-6.5,21.4l4.5,4.5c5.4-7.5,8.3-16.6,8.3-25.9c0-11-4-21.5-11.3-29.7c-1.2-1.3-3.1-1.4-4.4-0.3
		c-1.3,1.1-1.4,3.1-0.3,4.4C-167.7,365.5-164.3,374.6-164.3,384z"
            />
            <path
              d="M-172.3,421c2,2,4.2,1,4.8,0.4c1.1-1.2,0.8-3.5-0.4-4.8l-71-71c-1.1-1.1-2.7-1.3-4-0.5c-1.7,1.1-1.6,3.7-0.3,5l15.3,15.3
		c0,0,0,0.1,0,0.1l-2.1,1.4c0,0,0,0-0.1,0h-18.7c-1.8,0-3.3,1.5-3.3,3.3v27.6c0,1.7,1.4,3.1,3.2,3.1h18.8c0,0,0,0,0.1,0l25.3,17
		c0.5,0.4,1.1,0.5,1.8,0.5c0.5,0,1-0.1,1.5-0.4c1-0.6,1.7-1.6,1.7-2.8v-21.5c0-0.1,0.1-0.1,0.2-0.1 M-232.2,394.7h-13.4
		c-0.1,0-0.1,0-0.1-0.1v-21.2c0-0.1,0-0.1,0.1-0.1h13.4c0.1,0,0.1,0,0.1,0.1L-232.2,394.7C-232.1,394.7-232.1,394.7-232.2,394.7z
		 M-206.1,409.5l-19.8-13.3c0,0,0,0,0-0.1v-24.3c0,0,0-0.1,0-0.1l2.4-1.6c0,0,0.1,0,0.1,0l17.1,17.1c0,0,0,0,0,0.1L-206.1,409.5
		L-206.1,409.5z"
            />
          </g>
        </svg>
      ) : (
        <svg version="1.1" viewBox="0 0 100 125">
          <g>
            <path d="M53.6,15.4c-1-0.6-2.3-0.5-3.3,0.2L24.8,32.8H5.7c-1.8,0-3.2,1.4-3.2,3.2v28c0,1.8,1.4,3.2,3.2,3.2h19.1l25.5,17.2     c0.5,0.4,1.2,0.5,1.8,0.5c0.5,0,1-0.1,1.5-0.4c1-0.6,1.7-1.6,1.7-2.8V18.2C55.2,17.1,54.6,16,53.6,15.4z M8.8,39.2h13.7v21.6H8.8     V39.2z M48.9,75.8l-20-13.5V37.7l20-13.5V75.8z" />
            <path d="M63,38.4c-1.4,1-1.8,3-0.7,4.4c1.5,2.1,2.3,4.6,2.3,7.2s-0.8,5.1-2.3,7.2c-1,1.4-0.7,3.4,0.7,4.4     c0.6,0.4,1.2,0.6,1.8,0.6c1,0,2-0.5,2.6-1.3c2.3-3.2,3.5-7,3.5-10.9s-1.2-7.7-3.5-10.9C66.4,37.7,64.4,37.3,63,38.4z" />
            <path d="M76.7,29.4c-1.1-1.3-3.1-1.5-4.5-0.4c-1.3,1.1-1.5,3.1-0.4,4.5c3.9,4.6,6,10.5,6,16.5c0,6-2.1,11.9-6,16.5     c-1.1,1.3-0.9,3.3,0.4,4.5c0.6,0.5,1.3,0.7,2,0.7c0.9,0,1.8-0.4,2.4-1.1c4.8-5.8,7.4-13.1,7.4-20.6     C84.2,42.5,81.6,35.2,76.7,29.4z" />
            <path d="M86.1,19.9c-1.2-1.3-3.2-1.4-4.5-0.3c-1.3,1.2-1.4,3.2-0.3,4.5c6.3,7.1,9.8,16.3,9.8,25.9c0,9.5-3.5,18.7-9.8,25.9     c-1.2,1.3-1,3.3,0.3,4.5c0.6,0.5,1.4,0.8,2.1,0.8c0.9,0,1.8-0.4,2.4-1.1c7.3-8.3,11.4-19,11.4-30.1S93.5,28.2,86.1,19.9z" />
          </g>
        </svg>
      )}
    </StyledIconsContainer>
  );
};

export const PlayVideoIcon = ({ className, tooltipTitle, toggleVideo }) => {
  const { iconHandlers } = useContext(IconContext);
  return (
    <StyledIconsContainer
      tooltipTitle={tooltipTitle}
      handleClick={iconHandlers?.handleVideoPlayer}
    >
      {toggleVideo ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 6.82666 8.533325000000001"
          x="-5px"
        >
          <g>
            <path d="M4.03757 2.8957l0 2.32006c0,0.165913 -0.0659921,0.323776 -0.183224,0.441012 -0.117236,0.117232 -0.275094,0.183224 -0.441008,0.183224 -0.165917,0 -0.323776,-0.0659921 -0.441016,-0.183224 -0.117232,-0.117236 -0.183224,-0.275098 -0.183224,-0.441012l0 -2.32006c0,-0.165913 0.0659961,-0.323768 0.183224,-0.441004 0.11724,-0.117232 0.275091,-0.183224 0.441008,-0.183224 0.165921,0 0.323776,0.0659882 0.441016,0.183224 0.117228,0.117236 0.183224,0.275091 0.183224,0.441004zm-0.341335 2.32006l0 -2.32006c0,-0.0752756 -0.0300827,-0.146496 -0.0832402,-0.199654 -0.0531614,-0.0531614 -0.124386,-0.0832441 -0.199665,-0.0832441 -0.0752795,0 -0.146496,0.0300866 -0.199657,0.0832441 -0.0531575,0.0531575 -0.0832402,0.124378 -0.0832402,0.199654l0 2.32006c0,0.0752756 0.0300827,0.1465 0.0832402,0.199661 0.0531614,0.0531614 0.124386,0.0832402 0.199665,0.0832402 0.0752795,0 0.146496,-0.0300827 0.199657,-0.0832402 0.0531575,-0.0531614 0.0832402,-0.124386 0.0832402,-0.199661zm-0.282902 -4.49576c0.169846,0 0.331449,0.0669528 0.451524,0.187031 0.120075,0.120075 0.187031,0.281677 0.187031,0.45152 0,0.169846 -0.0669567,0.331453 -0.187031,0.451524 -0.120079,0.120075 -0.281677,0.187035 -0.451524,0.187035 -0.169846,0 -0.331445,-0.0669606 -0.451524,-0.187035 -0.120075,-0.120071 -0.187031,-0.281677 -0.187031,-0.451524 0,-0.169843 0.0669567,-0.331445 0.187031,-0.45152 0.120075,-0.120079 0.281677,-0.187031 0.451524,-0.187031zm0.210173 0.428382c-0.0559843,-0.0559843 -0.130992,-0.0870512 -0.210173,-0.0870512 -0.0791811,0 -0.154189,0.0310669 -0.210173,0.0870512 -0.0559843,0.0559843 -0.0870512,0.130988 -0.0870512,0.210169 0,0.079185 0.0310709,0.154189 0.0870512,0.210173 0.0559843,0.0559843 0.130988,0.0870512 0.210173,0.0870512 0.079185,0 0.154189,-0.0310669 0.210173,-0.0870512 0.0559803,-0.0559843 0.0870512,-0.130988 0.0870512,-0.210173 0,-0.0791811 -0.0310669,-0.154185 -0.0870512,-0.210169z" />
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 100 125"
        >
          <g transform="translate(0,-952.36218)">
            <path d="m 24.000001,971.36216 c -8.82931,0 -16,7.1707 -16,16 l 0,30.00004 c 0,8.8293 7.17069,16 16,16 l 52,0 c 8.82931,0 15.999998,-7.1707 15.999998,-16 l 0,-30.00004 c 0,-8.8293 -7.170688,-16 -15.999998,-16 l -52,0 z m 0,4 52,0 c 6.6825,0 11.999998,5.3175 11.999998,12 l 0,30.00004 c 0,6.6825 -5.317498,12 -11.999998,12 l -52,0 c -6.6825,0 -12,-5.3175 -12,-12 l 0,-30.00004 c 0,-6.6825 5.3175,-12 12,-12 z m 17.84375,12 a 2.0002002,2.0002002 0 0 0 -1.84375,2 l 0,26.00004 a 2.0002002,2.0002002 0 0 0 1.65625,2 2.0002002,2.0002002 0 0 0 1.375,-0.25 l 22,-13 a 2.0002002,2.0002002 0 0 0 0,-3.4688 l -22,-13.00004 a 2.0002002,2.0002002 0 0 0 -1.1875,-0.2812 z m 2.15625,5.5312 16.03125,9.46884 -16.03125,9.5 0,-18.96884 z" />
          </g>
        </svg>
      )}
    </StyledIconsContainer>
  );
};

export const BackArrow = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -12 100 125">
        <g>
          <path d="M 15 9 C 11.710595 9 9 11.71059 9 15 L 9 85 C 9 88.2894 11.710595 91 15 91 L 85 91 C 88.289405 91 91 88.2894 91 85 L 91 64 A 2.0001996 2.0001996 0 1 0 87 64 L 87 85 C 87 86.1426 86.142593 87 85 87 L 15 87 C 13.857407 87 13 86.1426 13 85 L 13 15 C 13 13.85741 13.857407 13 15 13 L 85 13 C 86.142593 13 87 13.85741 87 15 L 87 36 A 2.0001996 2.0001996 0 1 0 91 36 L 91 15 C 91 11.71059 88.289405 9 85 9 L 15 9 z M 52.84375 27.96875 A 2.0001996 2.0001996 0 0 0 51.5 28.65625 L 33.5 48.65625 A 2.0001996 2.0001996 0 0 0 33.5 51.3125 L 51.5 71.3125 A 2.0034926 2.0034926 0 0 0 54.5 68.65625 L 39.5 52 L 89 52 A 2.0001996 2.0001996 0 1 0 89 48 L 39.5 48 L 54.5 31.3125 A 2.0001996 2.0001996 0 0 0 52.84375 27.96875 z " />
        </g>
      </svg>
    </div>
  );
};

export const ShowHideIcon = ({ className, passcodeShown }) => {
  //const { iconHandlers } = useContext(IconContext);
  return (
    <Tooltip
      title={
        <h1
          style={{
            fontSize: 12,
            color: 'white',
            fontFamily: 'Noto Sans JP',
            fontWeight: 300,
            userSelect: 'none',
          }}
        >
          show/hide
        </h1>
      }
      placement="top"
      arrow
      enterDelay={200}
    >
      {!passcodeShown ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 100 125"
        >
          <path d="M85.875,48.849C79.372,36.31,65.29,28.208,50,28.208S20.628,36.31,14.125,48.849c-0.375,0.722-0.375,1.581,0,2.303  C20.628,63.69,34.71,71.792,50,71.792s29.372-8.102,35.875-20.641C86.25,50.43,86.25,49.57,85.875,48.849z M50,65.414  c-8.499,0-15.414-6.915-15.414-15.414S41.501,34.586,50,34.586S65.414,41.501,65.414,50S58.499,65.414,50,65.414z M19.193,50  c3.472-6.021,9.037-10.752,15.662-13.641c-3.264,3.62-5.27,8.395-5.27,13.641s2.006,10.021,5.27,13.641  C28.23,60.752,22.665,56.021,19.193,50z M65.145,63.641c3.264-3.62,5.27-8.395,5.27-13.641s-2.006-10.021-5.27-13.641  C71.77,39.248,77.335,43.979,80.807,50C77.335,56.021,71.77,60.752,65.145,63.641z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 100 125"
        >
          <path d="M85.875,48.849C81.872,41.13,74.991,35.103,66.73,31.602L80.51,12.987c0.821-1.109,0.588-2.675-0.522-3.497  s-2.674-0.586-3.497,0.522L61.793,29.867c-3.769-1.073-7.735-1.659-11.793-1.659c-15.29,0-29.372,8.102-35.875,20.641  c-0.375,0.722-0.375,1.581,0,2.303c4.003,7.719,10.884,13.746,19.145,17.247L19.49,87.013c-0.821,1.109-0.588,2.675,0.522,3.497  C20.46,90.841,20.981,91,21.498,91c0.766,0,1.521-0.351,2.012-1.013l14.697-19.854c3.769,1.073,7.735,1.659,11.793,1.659  c15.29,0,29.372-8.102,35.875-20.641C86.25,50.43,86.25,49.57,85.875,48.849z M50,34.586c2.533,0,4.92,0.626,7.031,1.714  l-18.08,24.424c-2.696-2.777-4.365-6.557-4.365-10.724C34.586,41.501,41.501,34.586,50,34.586z M50,65.414  c-2.533,0-4.92-0.626-7.031-1.714l18.08-24.424c2.696,2.777,4.365,6.557,4.365,10.724C65.414,58.499,58.499,65.414,50,65.414z   M19.193,50c3.472-6.021,9.037-10.752,15.662-13.641c-3.264,3.62-5.27,8.395-5.27,13.641s2.006,10.021,5.27,13.641  C28.23,60.752,22.665,56.021,19.193,50z M65.145,63.641c3.264-3.62,5.27-8.395,5.27-13.641s-2.006-10.021-5.27-13.641  C71.77,39.248,77.335,43.979,80.807,50C77.335,56.021,71.77,60.752,65.145,63.641z" />
        </svg>
      )}
    </Tooltip>
  );
};

export const ClipboardIcon = ({ className, passcodeShown }) => {
  //const { iconHandlers } = useContext(IconContext);
  return (
    <Tooltip
      title={
        <h1
          style={{
            fontSize: 12,
            color: 'white',
            fontFamily: 'Noto Sans JP',
            fontWeight: 300,
            userSelect: 'none',
          }}
        >
          Copy to clipboard
        </h1>
      }
      placement="top"
      arrow
      enterDelay={200}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 90 112.5"
      >
        <g>
          <path d="M68.2,12h-7.1v-0.2c0-1.7-1.4-3-3-3h-5.2c-0.9-3.4-4.1-6-7.8-6c-3.7,0-6.9,2.6-7.8,6h-5.4c-1.6,0-3,1.3-3,3V12h-7.1   c-4.4,0-8,3.6-8,8v59c0,4.4,3.6,8,8,8h46.5c4.4,0,8-3.6,8-8V20C76.2,15.6,72.6,12,68.2,12z M45.1,8.8c1.2,0,2.1,0.9,2.1,2.1   c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1C42.9,9.7,43.9,8.8,45.1,8.8z M70.2,78.9c0,1.1-0.9,2-2,2H21.8c-1.1,0-2-0.9-2-2V20   c0-1.1,0.9-2,2-2h7.1v0.1c0,1.7,1.4,3,3,3h26.3c1.6,0,3-1.3,3-3V18h7.1c1.1,0,2,0.9,2,2V78.9z" />
        </g>
        <g>
          <path d="M60.1,53.9H29.9c-1.7,0-3-1.3-3-3s1.3-3,3-3h30.3c1.7,0,3,1.3,3,3S61.8,53.9,60.1,53.9z" />
        </g>
        <g>
          <path d="M60.1,37.8H29.9c-1.7,0-3-1.3-3-3s1.3-3,3-3h30.3c1.7,0,3,1.3,3,3S61.8,37.8,60.1,37.8z" />
        </g>
        <g>
          <path d="M60.1,70H29.9c-1.7,0-3-1.3-3-3s1.3-3,3-3h30.3c1.7,0,3,1.3,3,3S61.8,70,60.1,70z" />
        </g>
      </svg>
    </Tooltip>
  );
};

export const UploadIcon = ({ className }) => {
  return (
    <span className={className}>
      <svg viewBox="0 0 640 512" width="32">
        <path d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path>
      </svg>
    </span>
  );
};

export const MoreIcons = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 32 40"
        x="0px"
        y="0px"
        style={{
          fill: 'black',
          stroke: 'black',
          height: '20px',
          width: '20px',
        }}
      >
        <circle cx="16" cy="5" r="3" />
        <circle cx="16" cy="16" r="3" />
        <circle cx="16" cy="27" r="3" />
      </svg>
    </div>
  );
};
