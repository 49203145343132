import React, { useState, useEffect, useRef, useContext } from 'react';

import { useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { setColor, setBackground } from '../../styles/styles';

import 'react-upload-gallery/dist/style.css';

import Button from '../globals/Button';

import expoHallAPI from '../../api/expoHallAPI';

import FormInput from '../globals/FormInput';
import { UploaderButton } from '../globals/UploaderButton';

import {UploadIcon} from '../../styles/icons';

import { Context as AttendantContext } from '../../context/AttendantContext';

import {NotificationFactory} from '../globals/NotificationMessage';

const TileEditorForm = ({ className,data }) => {

  const [toggleForm, setToggleForm] = useState(true);
  const [boothName, setBoothName] = useState('');
  const [blurb, setBlurb] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [videoHostURL, setVideoHostURL] = useState('');
  const [chatURL, setChatURL] = useState('');
  const [loading, setLoading] = useState(false);
  const { state: { booth, attendant }, setBooth, } = useContext(AttendantContext);

  const logoInputRef = useRef();
  const backgroundInputRef = useRef();
  const videoInputRef = useRef();

  const [logoFileName, setLogoFileName] = useState('');
  const [backgroundFileName, setBackgroundFileName] = useState('');
  const [videoFileName, setVideoFileName] = useState('');

  const modalRef = useRef();


  useEffect(() => {
    if(data){
      setBlurb(data.blurb)
      setBoothName(data.name);
      setBooth(data)
    }
  }, [data]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const dataUpdated = await expoHallAPI
        .put(`/api/tiles/${attendant.id}/`, {
          name: boothName,
          blurb: blurb
        });
      dataUpdated.data.group_id = dataUpdated.data.group_id.id;
      setBooth(dataUpdated.data);
      setToggleForm(true);
      const notification = {
        title: "Success",
        message: "Tile Information Updated",
        type: 'success',
        insert: 'top',
        container: 'top-right',
        duration: 2000
      };
      NotificationFactory(notification)

    } catch(error) {
      console.log('ERROR', error);
    }
  };

  const handlePictureSubmit = (ref, fieldName) => async (event) => {
    event.preventDefault();
    const data = new FormData();
    // console.log(data);
    if (ref.current.files[0]) {
      // console.log(ref.current.files);
      data.append(fieldName, ref.current.files[0]);
    }
    try {
      let notification = {
        title: "",
        message: "Uploading",
        type: 'info',
        insert: 'top',
        container: 'top-right',
        duration: 2000
      };
      NotificationFactory(notification)
      const dataUpdated  = await expoHallAPI.put(`/api/tiles/${attendant.id}/`, data);
      dataUpdated.data.group_id = dataUpdated.data.group_id.id;
      setBooth(dataUpdated.data);
      notification.title = 'Success';
      notification.message = 'File Uploaded';
      notification.type = 'success';
      NotificationFactory(notification)

    } catch (error) {
      console.log('error updloading photo: ', error?.message);
    }
  };
  return (
    <div className={className}>
      <Button
        onClick={() => {
          setToggleForm((prevState) => !prevState);
        }}
      >
        {toggleForm ? 'Edit' : 'Cancel'}
      </Button>
      <form>
        <fieldset disabled={toggleForm}>
          <div className="form-input-group">
            <label for="booth-name" className="form-label">
              Booth Name
              <span className="required">&nbsp;required</span>
            </label>
            <FormInput
              type="text"
              id="booth-name"
              placeholder="booth name"
              value={boothName}
              onChange={(event) => setBoothName(event.target.value)}
            />
          </div>
          <div className="form-input-group">
            <label for="name" className="form-label">
              Blurb
            </label>
            <textarea
              rows="10"
              cols="60"
              value={blurb}
              onChange={(event) => setBlurb(event.target.value)}
            />
          </div>
          {/*<div className="form-input-group">*/}
            {/*<label for="video-url" className="form-label">*/}
              {/*Video Chat URL*/}
              {/*<span className="required">&nbsp;required</span>*/}
            {/*</label>*/}
            {/*<FormInput*/}
              {/*type="url"*/}
              {/*pattern="http://.*"*/}
              {/*id="video-url"*/}
              {/*placeholder="zoom url"*/}
              {/*value={videoURL}*/}
              {/*onChange={(event) => setVideoURL(event.target.value)}*/}
            {/*/>*/}
          {/*</div>*/}
          {/*<div className="form-input-group">*/}
            {/*<label for="video-host-url" className="form-label">*/}
              {/*Video Chat Host URL*/}
              {/*<span className="required">&nbsp;required</span>*/}
            {/*</label>*/}
            {/*<FormInput*/}
              {/*type="url"*/}
              {/*pattern="http://.*"*/}
              {/*id="video-host-url"*/}
              {/*placeholder="zoom host url"*/}
              {/*value={videoHostURL}*/}
              {/*onChange={(event) => setVideoHostURL(event.target.value)}*/}
            {/*/>*/}
          {/*</div>*/}
          {/*<div className="form-input-group">*/}
            {/*<label for="chat-url" className="form-label">*/}
              {/*Chat URL*/}
              {/*<span className="required">&nbsp;required</span>*/}
            {/*</label>*/}
            {/*<FormInput*/}
              {/*type="url"*/}
              {/*pattern="http://.*"*/}
              {/*id="chat-url"*/}
              {/*placeholder="chat url"*/}
              {/*value={chatURL}*/}
              {/*onChange={(event) => setChatURL(event.target.value)}*/}
            {/*/>*/}
          {/*</div>*/}
          {!toggleForm && (
            <Button type="submit" onClick={handleSubmit} disabled={!boothName || !blurb}>
              Submit
            </Button>
          )}
        </fieldset>
      </form>
      {/*<form className="uploader">*/}
        {/*<div className="form-input-group">*/}
          {/*<label for="logo" className="form-label">*/}
            {/*<UploadIcon className='upload-icon'/>*/}
            {/*Upload Logo*/}
            {/*<span className="required">&nbsp;required</span>*/}
            {/*<span className="file-name">{logoFileName}</span>*/}
          {/*</label>*/}
          {/*<input*/}
            {/*type="file"*/}
            {/*ref={logoInputRef}*/}
            {/*id="logo"*/}
            {/*accept="image/*"*/}
            {/*onChange={() => {*/}
              {/*setLogoFileName(logoInputRef?.current?.files[0]?.name);*/}
            {/*}}*/}
          {/*/>*/}
        {/*</div>*/}
        {/*<UploaderButton*/}
          {/*type="submit"*/}
          {/*onClick={handlePictureSubmit(logoInputRef, 'logo')}*/}
        {/*>*/}
          {/*Update*/}
        {/*</UploaderButton>*/}
      {/*</form>*/}
      <form className="uploader">
        <div className="form-input-group">
          <label for="background-image" className="form-label">
            <UploadIcon className='upload-icon'/>
            Upload Background Image
            {/* <span className="required">&nbsp;required</span> */}
            <span className="file-name">{backgroundFileName}</span>
          </label>
          <input
            type="file"
            ref={backgroundInputRef}
            id="background-image"
            accept="image/*"
            onChange={() => {
              setBackgroundFileName(
                backgroundInputRef?.current?.files[0]?.name
              );
            }}
          />
        </div>
        <UploaderButton
          type="submit"
          onClick={handlePictureSubmit(backgroundInputRef, 'image')}
        >
          Update
        </UploaderButton>
      </form>
      <form className="uploader">
        <div className="form-input-group">
          <label for="video" className="form-label">
            <UploadIcon className='upload-icon'/>
            Upload Video
            <span className="file-name">{videoFileName}</span>
          </label>
          <input
            type="file"
            ref={videoInputRef}
            id="video"
            accept="video/*"
            onChange={() => {
              setVideoFileName(videoInputRef?.current?.files[0]?.name);
            }}
          />
        </div>
        <UploaderButton
          type="submit"
          onClick={handlePictureSubmit(videoInputRef, 'video')}
        >
          Update
        </UploaderButton>
      </form>
    </div>
  );
};

export default styled(TileEditorForm)`
  fieldset {
    outline: none;
    border: none;
    /* border: 2px solid gray;
    border-radius: 2rem; */

    textarea {
      :not(:placeholder-shown) {
        border-color: ${setColor.primaryBlue};
      }
    }

    :disabled {
      input {
        color: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input,
      textarea {
        border-color: transparent;
      }

      :invalid {
        border-color: red;
      }
    }
  }

  .uploader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.2s;
    max-width: 40rem;

    :hover {
      label {
        color: ${setColor.secondaryBlue};
        cursor: pointer;
        transition: 0.2s;
      }
    }

    input {
      font-family: inherit;
      color: white;
      outline: none;
      display: none;
    }

    button {
      cursor: pointer;
      align-self: center;
    }
  }

  .file-name {
    font-size: 1.2rem;
    color: white;
    margin: 0 1rem;
  }

  .form-input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .form-label {
    margin: 1rem 0;
    color: ${setColor.primaryBlue};
    font-size: 1.6rem;
    
    .upload-icon {
      margin: 0 0.25rem;
      fill: ${setColor.primaryBlue};
      font-size: 1.6rem;
    }
    :hover{
      .upload-icon {
          fill: ${setColor.secondaryBlue};
      }
    }
  }
  
 

  .required {
    font-style: italic;
    font-size: 1.4rem;
    color: rgb(186, 57, 45);
  }

  textarea {
    background-color: inherit;
    color: inherit;
    font-family: inherit;
    padding: 1rem 2rem;
    border-radius: 2rem;
    border: 2px solid;
    outline: none;
    border-color: grey;

    :placeholder-shown {
      border-color: grey;
    }

    :focus {
      border-color: ${setColor.primaryBlue};
    }
  }
`;
