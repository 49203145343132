import React from 'react';

import { Transition } from 'react-transition-group';

const VideoTransition = ({
  children,
  trigger,
  timeout,
  duration,
  toggleVideo,
}) => {
  //TRANSITION STYLES
  //console.log('VIDEO MOUNT')
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    transitionDelay: '.4s',
    opacity: 0,
    zIndex: -1,
    position: toggleVideo ? 'fixed' : 'absolute',
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1, zIndex: 1 },
    exiting: { opacity: 0, transitionDelay: 'opacity 0s', zIndex: 1 },
    exited: { opacity: 0, transitionDelay: 'opacity 0s', zIndex: 0 },
  };
  return (
    <Transition in={trigger} timeout={timeout}>
      {(state) => children({ defaultStyle, transitionStyles, state })}
    </Transition>
  );
};

export default VideoTransition;
