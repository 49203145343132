import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { setColor } from '../../styles/styles';

import ReactDateTimePicker from '../globals/DateTimePicker';

import { Context as UserContext } from '../../context/UserContext';

import expoHallAPI from '../../api/expoHallAPI';

import RegistrationInput from '../globals/RegistrationInput';
import { CloseIcon, LoadingIcon } from '../../styles/icons';
import RequestMeetingButton from '../globals/RequestMeetingButton';
import { Context as AttendantContext } from '../../context/AttendantContext';
import { NotificationFactory } from '../globals/NotificationMessage';

const RequestMeetingForm = ({
  className,
  data,
  closeModal,
  handleProcessed,
}) => {
  const [processed, setProcessed] = useState(data.processed);
  useEffect(() => {}, []);

  const handleMeetingCompleted = async () => {
    try {
      const response = await expoHallAPI.put(`/api/meetings/${data.id}`, {
        processed: !data.processed,
      });
      handleProcessed(data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={className}>
      <div className="close-icon" onClick={() => closeModal()}>
        <CloseIcon />
      </div>
      <div className="MeetingContainer">
        <div className="meeting_personalinfo">
          <div className="meeting_header">
            <h3>Meeting Information</h3>
          </div>
          <h4>
            {' '}
            <i class="fa fa-user" aria-hidden="true"></i> Personal Details
          </h4>
          <p className="meeting_personaldetails">
            First Name <span>{data.first_name}</span>
          </p>
          <p className="meeting_personaldetails">
            Last Name <span>{data.last_name}</span>
          </p>
        </div>
        <div className="meetingContact_info">
          <h4>
            {' '}
            <i class="fa fa-address-book"></i> Contact Information
          </h4>
          <p className="meeting_personaldetails">
            Email <span>{data.email}</span>
          </p>
          <p className="meeting_personaldetails">
            Phone Number <span>{data.phone ? data.phone : 'Not Provided'}</span>
          </p>
        </div>
        <div className="meeting_availability">
          {(data.date1 || data.date2 || data.date3) && (
            <h4>
              {' '}
              <i class="fa fa-clock-o" aria-hidden="true"></i> Avalabilities
            </h4>
          )}

          <p className="meeting_time">
            {data.date1 && new Date(data.date1).toLocaleString()}
          </p>
          <p className="meeting_time">
            {data.date2 && new Date(data.date2).toLocaleString()}
          </p>
          <p className="meeting_time">
            {data.date3 && new Date(data.date3).toLocaleString()}
          </p>
        </div>
        <div className="meeting_message">
          <div className="meeting_subject">
            <h6> Subject </h6>
            <p>{data.subject ? data.subject : 'Not Provided'}</p>
          </div>

          <div className="meeting_comment">
            <h6> comment </h6>
            <p>{data.comment ? data.comment : 'Not Provided'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default styled(RequestMeetingForm)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;

  .close-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;
  }

  .MeetingContainer > h2 {
    font-size: 15px;
    text-align: center;
    margin-top: 100px;
  }
  .MeetingContainer > h2 > span {
    font-size: 20px;
    color: ${setColor.secondaryBlue};
  }
  .MeetingContainer {
    height: 100%;
    width: 100%;
    color: #d1d1d1;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    overflow-y: auto;
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .MeetingContainer::-webkit-scrollbar {
    display: none;
  }
  .meeting_footer {
    background-color: ${setColor.secondaryBlue};
    height: 20px;
    width: 100%;
    margin-bottom: 30px;
  }
  .meeting_header {
    background-color: ${setColor.secondaryBlue};
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: white;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: 'Montserrat', sans-serif;

    i {
      font-size: 20px;
      margin-right: 30px;
    }
    h3 {
      font-size: 25px;
      margin-bottom: 0;
    }
  }
  .meeting_time {
    color: ${setColor.secondaryBlue};
  }
  .meeting_personalinfo,
  .meetingContact_info {
    h4 {
      font-size: 20px;
      color: #d1d1d1;
      text-align: start;
      margin-top: 40px;
      margin-bottom: 10px;
      border-bottom: 0.1px solid lightgray;
    }
    h4 > i {
      color: ${setColor.secondaryBlue};
    }
    .meeting_personaldetails {
      span {
        color: ${setColor.secondaryBlue};
        font-weight: bold;
        display: flex;
      }
    }
  }
  .meeting_availability {
    h4 {
      font-size: 20px;
      color: #d1d1d1;
      text-align: start;
      margin-top: 40px;
      margin-bottom: 10px;
      border-bottom: 0.1px solid lightgray;
    }
    h4 > i {
      color: ${setColor.secondaryBlue};
    }
  }
  .meeting_message {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    min-height: 40px;
    p {
      color: ${setColor.secondaryBlue};
    }
  }

  h4 {
    text-align: center;
    margin-bottom: 4rem;
    color: ${setColor.mainGrey};
  }
`;

const DateTimePickerContainer = styled.div`
  margin-bottom: 25px;
`;
