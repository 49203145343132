import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { setColor } from '../../styles/styles';

import ReactDateTimePicker from '../globals/DateTimePicker';

import { Context as UserContext } from '../../context/UserContext';

import expoHallAPI from '../../api/expoHallAPI';

import RegistrationInput from '../globals/RegistrationInput';
import { LoadingIcon } from '../../styles/icons';
import RequestMeetingButton from '../globals/RequestMeetingButton';
import { Context as AttendantContext } from '../../context/AttendantContext';
import { NotificationFactory } from '../globals/NotificationMessage';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const RequestMeetingForm = ({ className, tileid }) => {
  const classes = useStyles();

  const {
    state: { user },
  } = useContext(UserContext);
  // console.log(state)
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [contactmethod, setContactmethod] = useState('');
  const [dateTime1, setDateTime1] = useState(new Date().toISOString());
  const [dateTime2, setDateTime2] = useState(new Date().toISOString());
  const [dateTime3, setDateTime3] = useState(new Date().toISOString());
  const [subject, setSubject] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (user) {
      setFirstname(user.first_name);
      setLastname(user.last_name);
      setEmail(user.email);
      setPhone(user.phone);
      setCompany(user.company);
    }
  }, [user]);
  const handleCheck = () => {};
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await expoHallAPI.post('/api/meetings/', {
        attendee: user.id,
        tile: tileid,
        first_name: firstname,
        last_name: lastname,
        email: email,
        phone: phone,
        company: company,
        contact_method: contactmethod,
        date1: dateTime1 ? dateTime1.toString() : null,
        date2: dateTime2 ? dateTime2.toString() : null,
        date3: dateTime3 ? dateTime3.toString() : null,
        subject: subject,
        comment: comment,
        processed: false,
      });
      const notification = {
        title: 'Success',
        message: 'Meeting Requested',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        duration: 2000,
      };
      NotificationFactory(notification);
    } catch (error) {
      const notification = {
        title: 'Error',
        message: 'Meeting Request Failed',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        duration: 2000,
      };
      NotificationFactory(notification);
    }
  };

  return (
    <div className={className}>
      <div className="request_container">
        <h6>Meeting Request</h6>
        <form
          onSubmit={handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <div className="request_name">
            <TextField
              error={firstname === ''}
              helperText={firstname === '' ? 'Required' : ' '}
              id="filled-basic"
              label="First Name"
              variant="filled"
              placeholder="Your First Name"
              id="request_firstname"
              type="text"
              style={{
                marginRight: '10px',
              }}
              name="firstname"
              value={firstname}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
            />

            <TextField
              error={lastname === ''}
              helperText={lastname === '' ? 'Required' : ' '}
              placeholder="Your Last Name"
              id="filled-basic"
              label="Last Name"
              variant="filled"
              name="lastname"
              value={lastname}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
            />
          </div>

          <TextField
            error={email === ''}
            helperText={email === '' ? 'Required' : ' '}
            id="filled-basic"
            label="Email"
            variant="filled"
            type="email"
            placeholder="Your Email"
            emailField
            name="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            id="filled-basic"
            label="Telephone"
            variant="filled"
            type="text"
            placeholder="Ex: +1 514-111-2233"
            name="phone"
            value={phone}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />

          <TextField
            id="filled-basic"
            label="Company"
            variant="filled"
            type="text"
            placeholder="Company's Name"
            name="company"
            value={company}
            onChange={(event) => {
              setCompany(event.target.value);
            }}
          />
          <TextField
            error={subject === ''}
            helperText={subject === '' ? 'Required' : ' '}
            id="filled-basic"
            label="Subject"
            variant="filled"
            type="text"
            placeholder="Subject"
            name="subject"
            value={subject}
            onChange={(event) => {
              setSubject(event.target.value);
            }}
          />

          <label className="meeting_available" htmlFor="">
            Pick Avalabilities (<span>In Order of preference</span>){' '}
          </label>
          <div className="request_timeslot">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                value={dateTime1}
                disablePast
                format="yyyy/MM/dd HH:mm"
                ampm
                onChange={(date) => {
                  setDateTime1(date.toISOString());
                  console.log('DATE1', date.toISOString());
                }}
              />
              <KeyboardDateTimePicker
                variant="inline"
                value={dateTime2}
                disablePast
                format="yyyy/MM/dd HH:mm"
                ampm
                onChange={(date) => {
                  setDateTime2(date.toISOString());
                  console.log('DATE2', date.toISOString());
                }}
              />
              <KeyboardDateTimePicker
                variant="inline"
                value={dateTime3}
                disablePast
                format="yyyy/MM/dd HH:mm"
                ampm
                onChange={(date) => {
                  setDateTime3(date.toISOString());
                  console.log('DATE3', date.toISOString());
                }}
              />
            </MuiPickersUtilsProvider>
            {/* <input
              id="datetime-local"
              label=""
              type="datetime-local"
              min={
                new Date(new Date().toString().split('GMT')[0] + ' UTC')
                  .toISOString()
                  .split('.')[0]
              }
              className={classes.textField}
              value={dateTime1}
              onChange={(e) => {
                setDateTime1(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <input
              id="datetime-local"
              label=""
              type="datetime-local"
              min={
                new Date(new Date().toString().split('GMT')[0] + ' UTC')
                  .toISOString()
                  .split('.')[0]
              }
              className={classes.textField}
              value={dateTime2}
              onChange={(e) => {
                setDateTime2(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <input
              id="datetime-local"
              label=""
              type="datetime-local"
              min={
                new Date(new Date().toString().split('GMT')[0] + ' UTC')
                  .toISOString()
                  .split('.')[0]
              }
              className={classes.textField}
              value={dateTime3}
              onChange={(e) => {
                setDateTime3(e.target.value);
              }}
            /> */}
          </div>
          <textarea
            placeholder="Describe How you would Like to be contacted"
            name=""
            id=""
            cols="30"
            rows="10"
            name="comment"
            className="request_textarea"
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          ></textarea>
          <button
            disabled={!firstname || !lastname || !email || !subject}
            className="request_register"
            type="submit"
          >
            Confirm
          </button>
        </form>
      </div>
    </div>
  );
};

export default styled(RequestMeetingForm)`
  height: 100%;
  width: 100%;
  color: #d1d1d1;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 2.5rem;
  font-weight: 300;
  overflow-y: auto;
  .request_container {
    margin-bottom: 3rem;
    background: white;
  }
  .request_container h6 {
    color: ${setColor.secondaryBlue};
    text-align: center;
    margin-top: 2rem;
  }
  .request_timeslot {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    width: 100%;
  }
  .request_name {
    display: flex;
  }

  .request_name .MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 80%;
  }
  .meeting_available {
    margin-bottom: 2rem;
    color: ${setColor.secondaryBlue};
  }
  .request_textarea {
    background-color: inherit;
    color: black;
    font-family: inherit;
    padding: 1rem 2rem;
    /* border-radius: 2rem; */
    border: 1px solid;
    outline: none;
    border-color: grey;
    resize: none;
    border-radius: 0;
  }
  .request_datepicker label {
    font-size: 14px;
    top: -1px;
  }
  .request_register {
    background: ${setColor.secondaryBlue};
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 3px;
    transition: all ease 0.3s;
    color: white;
  }
  .request_register:hover {
    background: ${setColor.tertiaryBlue};
    color: white;
    font-size: bold;
  }
  label + .MuiInput-formControl {
    margin-top: 0 !important;
  }
  .request_date {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .request_date .MuiFormHelperText-root.Mui-error {
    color: #171616;
    font-size: 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    align-items: flex-start;
    width: 100%;
  }

  h4 {
    text-align: center;
    color: ${setColor.mainGrey};
  }
`;

const DateTimePickerContainer = styled.div`
  margin-bottom: 25px;
`;
