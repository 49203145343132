import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { setColor } from '../../styles/styles';

import Button from '../globals/Button';

import expoHallAPI from '../../api/expoHallAPI';

import FormInput from '../globals/FormInput';
import { UploaderButton } from '../globals/UploaderButton';

const ClientCreateTileForm = ({ className }) => {
  const [exhibitorName, setExhibitorName] = useState('');

  const [boothName, setBoothName] = useState('');
  const [description, setDescription] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [videoHostURL, setVideoHostURL] = useState('');
  const [companyURL, setCompanyURL] = useState('');
  const [chatURL, setChatURL] = useState('');
  const [loading, setLoading] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const logoInputRef = useRef();
  const backgroundInputRef = useRef();
  const videoInputRef = useRef();

  useEffect(() => {
    isSubmitButtonDisabled();
  }, [boothName, description, videoURL, videoHostURL, chatURL]);

  const isSubmitButtonDisabled = () => {
    setSubmitButtonDisabled(
      !(boothName && description && videoURL && videoHostURL && chatURL)
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // console.log('clicked');
    // try {
    //   await expoHallAPI.post('/api/booths/1', {
    //     videocall_url: videoURL,
    //     videocall_host_url: videoHostURL,
    //     chat_url: chatURL,
    //     blurb: description,
    //   });
    //   await expoHallAPI.post('/api/tiles/1', {
    //     name: boothName,
    //   });
    // } catch (error) {
    //   console.log('ERROR', error);
    // }
    setLoading(false);
  };

  const handlePictureSubmit = (ref, fieldName) => async (event) => {
    event.preventDefault();
    // console.log(ref.current.files[0]);
    const data = new FormData();
    if (ref.current.files[0]) {
      data.append(fieldName, ref.current.files[0]);
    }
    try {
      // console.log('uploading');
      await expoHallAPI.put('/api/tiles/1', data);
      // console.log('done');
    } catch (error) {
      console.log('error updloading photo: ', error?.message);
    }
  };

  return (
    <div className={className}>
      <h4>Create an exhibitor</h4>
      <form>
        <fieldset>
          <div className="form-input-group">
            <label for="booth-name" className="form-label">
              Exhibitor Name
              <span className="required">&nbsp;required</span>
            </label>
            <FormInput
              type="text"
              id="booth-name"
              placeholder="booth name"
              value={boothName}
              onChange={(event) => setBoothName(event.target.value)}
            />
          </div>
          <div className="form-input-group">
            <label for="description" className="form-label">
              Description
              <span className="required">&nbsp;required</span>
            </label>
            <textarea
              rows="5"
              cols="30"
              id="description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <div className="form-input-group">
            <label for="video-url" className="form-label">
              Video Chat URL
              <span className="required">&nbsp;required</span>
            </label>
            <FormInput
              type="url"
              pattern="http://.*"
              id="video-url"
              placeholder="company url"
              value={videoURL}
              onChange={(event) => setVideoURL(event.target.value)}
            />
          </div>
          <div className="form-input-group">
            <label for="video-host-url" className="form-label">
              Video Chat Host URL
              <span className="required">&nbsp;required</span>
            </label>
            <FormInput
              type="url"
              pattern="http://.*"
              id="video-host-url"
              placeholder="company url"
              value={videoHostURL}
              onChange={(event) => setVideoHostURL(event.target.value)}
            />
          </div>
          <div className="form-input-group">
            <label for="chat-url" className="form-label">
              Chat URL
              <span className="required">&nbsp;required</span>
            </label>
            <FormInput
              type="url"
              pattern="http://.*"
              id="chat-url"
              placeholder="company url"
              value={chatURL}
              onChange={(event) => setChatURL(event.target.value)}
            />
          </div>
          <Button
            type="submit"
            disabled={submitButtonDisabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </fieldset>
      </form>
      <form className="uploader">
        <div className="form-input-group">
          <label for="logo" className="form-label">
            Upload Logo
            <span className="required">&nbsp;required</span>
          </label>
          <input type="file" ref={logoInputRef} id="logo" accept="image/*" />
        </div>
        <UploaderButton
          type="submit"
          onClick={handlePictureSubmit(logoInputRef, 'logo')}
        >
          Update
        </UploaderButton>
      </form>
      <form className="uploader">
        <div className="form-input-group">
          <label for="background-image" className="form-label">
            Upload Background Image
            <span className="required">&nbsp;required</span>
          </label>
          <input
            type="file"
            ref={backgroundInputRef}
            id="background-image"
            accept="image/*"
          />
        </div>
        <UploaderButton
          type="submit"
          onClick={handlePictureSubmit(backgroundInputRef, 'image')}
        >
          Update
        </UploaderButton>
      </form>
      <form className="uploader">
        <div className="form-input-group">
          <label for="video" className="form-label">
            Upload Video
            <span className="required">&nbsp;required</span>
          </label>
          <input type="file" ref={videoInputRef} id="video" accept="video/*" />
        </div>
        <UploaderButton
          type="submit"
          onClick={handlePictureSubmit(videoInputRef, 'video')}
        >
          Update
        </UploaderButton>
      </form>
    </div>
  );
};

export default styled(ClientCreateTileForm)`
  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    align-items: flex-start;
    width: 100%;
  }

  fieldset {
    outline: none;
    border: none;

    textarea {
      :not(:placeholder-shown) {
        border-color: ${setColor.primaryBlue};
      }
    }

    .form-input-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
    }

    .form-label {
      margin-bottom: 1rem;
      color: ${setColor.primaryBlue};
      font-size: 1.6rem;
    }

    .required {
      font-style: italic;
      font-size: 1.4rem;
      color: rgb(186, 57, 45);
    }
  }
`;
