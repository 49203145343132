import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import LoginForm from './LoginForm';
import { useLocation, useHistory } from 'react-router-dom';

import queryString from 'query-string';

import { Context as UserContext } from '../../context/UserContext';

import expoHallAPI from '../../api/expoHallAPI';
import { Context as AttendantContext } from '../../context/AttendantContext';
import SiteData from '../../config';
import getTimeLogged from '../../utils/getTimeLogged';

const RegistrationForm = ({ className }) => {
  const location = useLocation();
  const history = useHistory();

  const {
    state: { user },
    setUser,
  } = useContext(UserContext);
  const {
    state: { attendant },
    setAttendant,
  } = useContext(AttendantContext);

  const [alreadyExist, setalreadyExist] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [loadCategories, setLoadCategories] = useState(false);

  useEffect(() => {
    setEmail(queryString.parse(location.search).email);
  }, []);

  useEffect(() => {
    authenticateUser();
  }, []);

  const authenticateUser = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-user');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendee/profile/');
      // console.log(data);
      if (data) {
        setUser(data);
        history.push('/');
      }
    } catch (error) {
      expoHallAPI.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('shrm-li-expo-user');
      setUser(undefined);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await expoHallAPI.post('/login/', {
        email: email.toLowerCase(),
        password,
        schema: SiteData.schemaName,
      });
      if (data) {
        if (data.is_attendant == 'True') {
          localStorage.setItem('shrm-li-expo-attendant', data.token);
          expoHallAPI.defaults.headers.common['Authorization'] =
            'Bearer ' + data.token;
          if (data.tile === true) {
            history.push('/dashboard');
          } else if (data.tile === false) {
            history.push('/');
          }
        }
        if (data.is_attendee == 'True') {
          localStorage.setItem('shrm-li-expo-user', data.token);
          expoHallAPI.defaults.headers.common['Authorization'] =
            'Bearer ' + data.token;
          getNotification();
          history.push('/');
        }
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 403) {
          history.push('/register', { email: email, password: password });
        }
        if (error.response.status === 500) {
          setalreadyExist(true);
        }
      }
    }
  };

  const getNotification = async () => {
    try {
      const { data } = await expoHallAPI.get('/api/notifications/login/');
      data.map((eachData) => {
        timeTriggerActions(eachData);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const timeTriggerActions = (data) => {
    switch (data.notif_type) {
      case 'NOW':
        timeTriggerNow(data);
        break;
      case 'PERIOD':
        timeTriggerPeriod(data);
        break;
      default:
      // code block
    }
  };

  const timeTriggerNow = (data) => {
    const now = new Date();
    const TimeNow = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
    const EventTime = parseFloat(data.milliseconds);
    //time left for event
    let TimeLeftForEvent = EventTime - TimeNow;
    if (TimeLeftForEvent < 0) {
      TimeLeftForEvent += 86400000; // adds 1 day (runs everyday)
    }

    const eventDay = new Date(EventTime).getUTCDate();
    if (now.getUTCDate() === eventDay) {
      setTimeout(() => {
        auditoriumActions(data);
      }, TimeLeftForEvent);
    }
  };

  const timeTriggerPeriod = (data) => {
    const now = new Date();
    const EventTime = new Date(data.time);
    const EventEndTime = new Date(data.end_time);
    if (
      new Date(now.toUTCString()) > new Date(EventTime.toUTCString()) &&
      new Date(now.toUTCString()) < new Date(EventEndTime.toUTCString())
    ) {
      auditoriumActions(data);
    }
  };
  const proceedToAuditorium = () => {
    history.push('/auditorium');
  };
  const auditoriumActions = (data) => {
    const now = new Date(new Date().toUTCString());
    const EventTime = new Date(new Date(data.time).toUTCString());
    const EventEndTime = new Date(new Date(data.end_time).toUTCString());
    const differenceAfter = now.getTime() - EventTime.getTime();
    const differenceBefore = EventEndTime.getTime() - now.getTime();
    const minutes = Math.ceil(differenceBefore / 60000);

    getTimeLogged(data.id.toString());

    switch (data.id.toString()) {
      case '7':
        // case for when auidtorium is live
        proceedToAuditorium();

      default:
    }
  };

  return (
    <div className={className}>
      <LoginForm
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handleSubmit={handleSubmit}
        setalreadyExist={setalreadyExist}
        alreadyExist={alreadyExist}
      />
    </div>
  );
};

export default styled(RegistrationForm)``;
