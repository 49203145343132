import SiteData from '../config';

// export let Socket = new WebSocket(`ws://dev.localhost:8001/socket/${SiteData.schemaName}/`);

export let Socket = new WebSocket(
  `wss://${SiteData.schemaName}.mbvsbackend.com/socket/${SiteData.schemaName}/`
);

export const createSocket = () => {
  // Socket = new WebSocket(`ws://dev.localhost:8001/socket/${SiteData.schemaName}/`);
  Socket = new WebSocket(
    `wss://${SiteData.schemaName}.mbvsbackend.com/socket/${SiteData.schemaName}/`
  );
  return Socket;
};

export const getSocket = () => {
  return Socket;
};

export const connectChat = (data, chatid) => {
  //console.log(data,chatid)
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      connectChat(data, chatid);
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'init_chat',
          email: data.email,
          chatid: chatid,
          schema: SiteData.schemaName,
        })
      );
    } catch (error) {
      Socket.close();
    }
  }
};

export const disconnectChat = (data, chatid) => {
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      disconnectChat(data, chatid);
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'leave_chat',
          email: data.email,
          chatid: chatid,
          schema: SiteData.schemaName,
        })
      );
    } catch (error) {
      Socket.close();
    }
  }
};

export const connectChatViews = (data, chatid, tileid, views) => {
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      connectChatViews(data, chatid, tileid, views);
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'init_chat',
          email: data.email,
          chatid: chatid,
          schema: SiteData.schemaName,
          tileid: tileid,
          views: views,
        })
      );
      // playSound();
    } catch (error) {
      Socket.close();
    }
  }
};

export const retrieveMoreMessages = (chatid, currentPage) => {
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      retrieveMoreMessages(chatid, currentPage);
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'load_more_messages',
          number: currentPage * 15,
          chatid: chatid,
          schema: SiteData.schemaName,
        })
      );
    } catch (error) {
      Socket.close();
    }
  }
};

export const sendChatMessage = (data, chatid, message) => {
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      sendChatMessage(data, chatid, message);
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'send_message_chat',
          email: data.email,
          chatid: chatid,
          message: message,
          schema: SiteData.schemaName,
        })
      );
    } catch (error) {
      Socket.close();
    }
  }
};

export const deleteChatMessage = (chatid, message) => {
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      deleteChatMessage(chatid, message);
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'delete_chat_message',
          chatid: chatid,
          message: message,
          schema: SiteData.schemaName,
        })
      );
    } catch (error) {
      Socket.close();
    }
  }
};

export const sendHeartbeat = () => {
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      sendHeartbeat();
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'heartbeat',
          schema: SiteData.schemaName,
        })
      );
    } catch (error) {
      Socket.close();
    }
  }
};

export const heartbeat_msg = '--heartbeat--';

export const sendUpdateLastAction = () => {
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      sendUpdateLastAction();
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'update_last_action',
          schema: SiteData.schemaName,
        })
      );
    } catch (error) {
      Socket.close();
    }
  }
};

export const sendPruneChatParticipants = () => {
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      sendPruneChatParticipants();
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'prune_chat_participants',
          schema: SiteData.schemaName,
        })
      );
    } catch (error) {
      Socket.close();
    }
  }
};
export const sendUpdateChatParticipants = (chatid) => {
  if (Socket.readyState !== 1) {
    setTimeout(() => {
      sendUpdateChatParticipants();
    }, 1000);
  } else {
    try {
      Socket.send(
        JSON.stringify({
          action: 'update_chat_participants',
          chatid: chatid,
          schema: SiteData.schemaName,
        })
      );
    } catch (error) {
      Socket.close();
    }
  }
};
