import contextFactory from './contextFactory';

const initialState = {
  tileChatMessages: [],
  tileChatParticipants: [],
  generalChatMessages: [],
  generalChatParticipants: [],
  auditoriumChatMessages: [],
  auditoriumChatParticipants: [],
  loadingMoreMessages: false,
  switchButton: false,
  viewsNumber: 0,
  categories: [],
  loadTileChatContext: false,
  connection: false,
  EventisLive: false,
  isTileClicked: true,
  isPaused: false,
};

const socketReducer = (state, action) => {
  switch (action.type) {
    case 'set_tile_chat_messages':
      return { ...state, tileChatMessages: action.payload };
    case 'set_tile_chat_participants':
      return { ...state, tileChatParticipants: action.payload };
    case 'set_general_chat_messages':
      return { ...state, generalChatMessages: action.payload };
    case 'set_general_chat_participants':
      return { ...state, generalChatParticipants: action.payload };
    case 'set_auditorium_chat_messages':
      return { ...state, auditoriumChatMessages: action.payload };
    case 'set_auditorium_chat_participants':
      return { ...state, auditoriumChatParticipants: action.payload };
    case 'set_loading_more_messages':
      return { ...state, loadingMoreMessages: action.payload };
    case 'set_switch_button':
      return { ...state, switchButton: action.payload };
    case 'set_views_number':
      return { ...state, viewsNumber: action.payload };
    case 'set_categories':
      return { ...state, categories: action.payload };
    case 'set_load_tile_chat_context':
      return { ...state, loadTileChatContext: action.payload };
    case 'set_connection':
      return { ...state, connection: action.payload };
    case 'set_eventislive':
      return { ...state, EventisLive: action.payload };
    case 'set_tilestatus':
      return { ...state, isTileClicked: action.payload };
    case 'set_ispaused':
      return { ...state, isPaused: action.payload };

    default:
      return state;
  }
};

const socketActions = {
  setTileChatMessages: (dispatch) => (tileChatMessages) => {
    dispatch({ type: 'set_tile_chat_messages', payload: tileChatMessages });
  },
  setTileChatParticipants: (dispatch) => (tileChatParticipants) => {
    dispatch({
      type: 'set_tile_chat_participants',
      payload: tileChatParticipants,
    });
  },
  setGeneralChatMessages: (dispatch) => (generalChatMessages) => {
    dispatch({
      type: 'set_general_chat_messages',
      payload: generalChatMessages,
    });
  },
  setGeneralChatParticipants: (dispatch) => (generalChatParticipants) => {
    dispatch({
      type: 'set_general_chat_participants',
      payload: generalChatParticipants,
    });
  },
  setAuditoriumChatMessages: (dispatch) => (auditoriumChatMessages) => {
    dispatch({
      type: 'set_auditorium_chat_messages',
      payload: auditoriumChatMessages,
    });
  },
  setAuditoriumChatParticipants: (dispatch) => (auditoriumChatParticipants) => {
    dispatch({
      type: 'set_auditorium_chat_participants',
      payload: auditoriumChatParticipants,
    });
  },
  setLoadingMoreMessages: (dispatch) => (loadingMoreMessages) => {
    dispatch({
      type: 'set_loading_more_messages',
      payload: loadingMoreMessages,
    });
  },
  setSwitchButton: (dispatch) => (switchButton) => {
    dispatch({ type: 'set_switch_button', payload: switchButton });
  },
  setViewsNumber: (dispatch) => (viewsNumber) => {
    dispatch({ type: 'set_views_number', payload: viewsNumber });
  },
  setCategories: (dispatch) => (categories) => {
    dispatch({ type: 'set_categories', payload: categories });
  },
  setLoadTileChatContext: (dispatch) => (loadTileChatContext) => {
    dispatch({
      type: 'set_load_tile_chat_context',
      payload: loadTileChatContext,
    });
  },
  setConnection: (dispatch) => (connection) => {
    dispatch({ type: 'set_connection', payload: connection });
  },
  setEventisLive: (dispatch) => (EventisLive) => {
    dispatch({ type: 'set_eventislive', payload: EventisLive });
  },
  setTileStatus: (dispatch) => (connection) => {
    dispatch({ type: 'set_tilestatus', payload: connection });
  },
  setIsPaused: (dispatch) => (connection) => {
    dispatch({ type: 'set_ispaused', payload: connection });
  },
};

export const { Context, Provider } = contextFactory(
  socketReducer,
  { ...socketActions },
  initialState
);
