import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import expoHallAPI from '../api/expoHallAPI';
import { Context as UserContext } from '../context/UserContext';
import { Context as AttendantContext } from '../context/AttendantContext';
import { useHistory } from 'react-router-dom';
import { CustomNotification } from '../components/globals/NotificationAgreement';
import { setColor } from '../styles/styles';
import { connectChat, disconnectChat } from '../api/expoHallSocket';
import { Context as SocketContext } from '../context/SocketContext';
import SiteData from '../config';
import { BackArrow } from '../styles/icons';
import ReactPlayer from 'react-player';
import {
  sendChatMessage,
  retrieveMoreMessages,
  deleteChatMessage,
  sendUpdateChatParticipants,
} from '../api/expoHallSocket';
//time logger for event triggers function
import getTimeLogged from '../utils/getTimeLogged';

//Sweetalert library, second attempt at doing alert for video unmute
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//material UI slider component for video
import Grid from '@material-ui/core/Grid';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import Slider from '@material-ui/core/Slider';
import LogoutButton from '../components/globals/LogoutButton';
import Smallchatbox from '../components/Chatbox/Smallchatbox';

const AuditoriumPage = ({ className }) => {
  const { state, state: { user } = {}, setUser } = useContext(UserContext);
  const { state: { attendant = undefined } = {}, setAttendant } = useContext(
    AttendantContext
  );
  const {
    state: {
      loadingMoreMessages,
      auditoriumChatMessages,
      auditoriumChatParticipants,
      connection,
    },
    setAuditoriumChatMessages,
    setAuditoriumChatParticipants,
    setLoadingMoreMessages,
    setEventisLive,
  } = useContext(SocketContext);
  const [auditorium, setAuditorium] = useState(undefined);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [preVideoIsPlaying, setPreVideoIsPlaying] = useState(true);
  const [videoIsMuted, setVideoIsMuted] = useState(true);
  const [videoRefHasMounted, setVideoRefHasMounted] = useState(false);
  const [volume, setVolume] = useState(100);
  const [differenceAfter, setDifferenceAfter] = useState(0);
  const [isPlaybackTriggered, setIsPlaybackTriggered] = useState(false);

  const [videoURL, setVideoURL] = useState(
    'https://expohallimages.s3.amazonaws.com/media/trinity/auditorium_videos/Open_House_Parent_Instruction_Slides_2.mp4'
  );
  const [presentationVideoURL, setPresentationVideoURL] = useState('');
  const [isLooped, setIsLooped] = useState(true);

  const history = useHistory();
  const videoRef = useRef();

  //const playbackRef = useRef(false);

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    handleAuthCheck();
  }, []);

  useEffect(() => {
    if (auditorium) getNotification();
  }, [auditorium]);

  useEffect(() => {
    if (user) {
      getAuditorium();
      //handleConnectAuditoriumChat(user);
    }

    return () => {
      if (user) {
        handleDisconnectAuditoriumChat();
      }
    };
  }, [user]);

  useEffect(() => {
    if (user && connection) {
      handleConnectAuditoriumChat(user);
    }
  }, [connection, user]);

  useLayoutEffect(() => {
    setPresentationVideoURL(auditorium?.live_event_link);
  }, [auditorium]);

  useEffect(() => {
    //console.log('playbackRef', playbackRef.current);
    if (isPlaybackTriggered) {
      videoRef.current.seekTo(parseInt(Math.floor(differenceAfter / 1000)));
      // console.log('differenceAfter', differenceAfter);
      // console.log('before set timeout')
      // setTimeout(() => {
      //   videoRef.current.seekTo(parseInt(Math.floor(differenceAfter / 1000)));
      //   console.log('differenceAfter', differenceAfter);
      // }, 1000);
    }
  }, [isPlaybackTriggered]);

  const handleConnectAuditoriumChat = (data) => {
    connectChat(data, SiteData.auditoriumChatId);
  };

  const sendChatMessageAuditorium = (message) => {
    sendChatMessage(user, SiteData.auditoriumChatId, message);
  };
  const retrieveMoreMessagesAuditorium = (currentPage) => {
    retrieveMoreMessages(SiteData.auditoriumChatId, currentPage);
    setLoadingMoreMessages(true);
  };
  const deleteMessageAuditorium = (message) => {
    deleteChatMessage(SiteData.auditoriumChatId, message);
  };

  const handleDisconnectAuditoriumChat = () => {
    disconnectChat(user, SiteData.auditoriumChatId);
  };

  const handleAuthCheck = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-user');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendee/profile/');
      if (data) {
        setUser(data);
      }
    } catch (error) {
      handleAuthCheckAttendant();
    }
  };

  const handleAuthCheckAttendant = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-attendant');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendant/profile/');
      if (data) {
        setUser(data);
        setAttendant(data);
      }
    } catch (error) {
      expoHallAPI.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('shrm-li-expo-user');
      localStorage.removeItem('shrm-li-expo-attendant');
      history.push('/login');
      setUser(undefined);
      setAttendant(undefined);
    }
  };

  const getAuditorium = async () => {
    try {
      const { data } = await expoHallAPI.get('/api/auditoriums/');
      setAuditorium(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getNotification = async () => {
    try {
      const { data } = await expoHallAPI.get(
        '/api/notifications/auditorium/1/'
      );
      data.map((eachData) => {
        timeTriggerActions(eachData);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const timeTriggerNow = (data) => {
    const now = new Date();
    const TimeNow = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
    const EventTime = parseFloat(data.milliseconds);
    //time left for event
    let TimeLeftForEvent = EventTime - TimeNow;
    if (TimeLeftForEvent < 0) {
      TimeLeftForEvent += 86400000; // adds 1 day (runs everyday)
    }

    const eventDay = new Date(EventTime).getUTCDate();
    if (now.getUTCDate() === eventDay) {
      setTimeout(() => {
        auditoriumActions(data);
      }, TimeLeftForEvent);
    }
  };

  const timeTriggerPeriod = (data) => {
    const now = new Date();
    const EventTime = new Date(data.time);
    const EventEndTime = new Date(data.end_time);
    if (
      new Date(now.toUTCString()) > new Date(EventTime.toUTCString()) &&
      new Date(now.toUTCString()) < new Date(EventEndTime.toUTCString())
    ) {
      auditoriumActions(data);
    }
  };

  const timeTriggerActions = (data) => {
    switch (data.notif_type) {
      case 'NOW':
        timeTriggerNow(data);
        break;
      case 'PERIOD':
        timeTriggerPeriod(data);
        break;
      default:
      // code block
    }
  };

  const auditoriumActions = (data) => {
    const now = new Date(new Date().toUTCString());
    const EventTime = new Date(new Date(data.time).toUTCString());
    const EventEndTime = new Date(new Date(data.end_time).toUTCString());
    //console.log('auditorium actions data', data);
    const differenceAfter = now.getTime() - EventTime.getTime();
    //console.log('differenceAfter', differenceAfter)
    const differenceBefore = EventEndTime.getTime() - now.getTime();
    const minutes = Math.ceil(differenceAfter / 60000);

    setDifferenceAfter(differenceAfter);
    getTimeLogged(data.id.toString());

    switch (data.id.toString()) {
      case '3':
        setOpenNotification(false);
        if (auditorium) handlePlayVideo();
        setPresentationVideoURL(auditorium?.live_event_link);
        handleAfterStart(differenceAfter);

        break;

      case '4':
        setEventisLive(true);
        data.message = data.message.replace('TIME', minutes.toString());
        data.message = data.message.split('. ').join('.\n');
        data.time = '';
        setNotificationData(data);
        setOpenNotification(true);

        if (auditorium) handleAfterStart(differenceAfter);
        setTimeout(() => {
          setOpenNotification(false);
        }, 10000);
        break;
      case '5':
        // pop up notification
        // indicating Event has Ended
        data.buttonText = 'proceed';
        data.time = '';
        setNotificationData(data);
        setOpenNotification(true);
        setEventisLive(false);
        setTimeout(() => {
          proceedToExpohall();
        }, 20000);
        break;
      default:
    }
  };

  const handlePreload = () => {
    setVideoIsMuted(true);
    setPreVideoIsPlaying(true);
    setVideoIsPlaying(false);
    handleUnmuteAlertPreload();
  };

  const handlePlayVideo = () => {
    setPreVideoIsPlaying(false);
    setIsLooped(false);
    setVideoIsPlaying(true);
  };

  const handleAfterStart = (differenceAfter) => {
    // console.log('differenceAfter', differenceAfter / 1000);
    // console.log('videoRef', videoRef);
    // console.log('videoRef.current', videoRef.current);
    setVideoIsPlaying(true);
    setVideoIsMuted(true);
    setIsLooped(false);
    setPreVideoIsPlaying(false);
    setVideoIsPlaying(true);
    //setTimeout(() => {
    //videoRef.current.seekTo(parseInt(Math.floor(differenceAfter / 1000)));
    //setVideoIsPlaying(true);
    //}, 2000);

    handleUnmuteAlert();

    //videoRef.current.seekTo(parseInt(Math.floor(differenceAfter/1000)));

    //setVideoIsPlaying(true);
    //setVideoIsMuted(false);

    //setVideoIsMuted(false);
  };

  const proceedToExpohall = () => {
    history.push('/');
  };

  const handleUnmuteAlertPreload = () => {
    MySwal.fire({
      title: '<b>The Video Will Start Playing Soon</b>',
      text: 'enjoy the presentation!',
      //timer: 6000,
      //timerProgressBar: true,
      willClose: () => {
        setVideoIsMuted(false);
      },
    }).then(() => {
      //return something after, not needed for now
    });
  };

  const handleUnmuteAlert = () => {
    MySwal.fire({
      title: '<b>The Video Is Now Playing</b>',
      text: 'enjoy the presentation!',
      //timer: 6000,
      //timerProgressBar: true,
      onClose: () => {
        setVideoIsMuted(false);
      },
    }).then(() => {
      //return something after, not needed for now
    });
  };

  const handleVolume = (event, newVolume) => {
    setVolume(newVolume);
    //console.log('volume', volume);
  };
  //console.log(videoIsMuted)
  return (
    <Container className={className}>
      <LeftContainer>
        {attendant ? (
          <TopGoBack onClick={(e) => history.push('/dashboard')}>
            <BackArrow />
            <IconContainer>Back To Event Console</IconContainer>
          </TopGoBack>
        ) : (
          <NavigationBar>
            <TopGoBack
              onClick={() => {
                proceedToExpohall();
              }}
            >
              <BackArrow />
              <IconContainer>RECEPTION HALL</IconContainer>
            </TopGoBack>
            {/* <TopGoBack
              onClick={() => {
                expoHallAPI.defaults.headers.common['Authorization'] = '';
                history.push('/login');
                localStorage.removeItem('shrm-li-expo-user');
                setUser(undefined);
                setAttendant(undefined);
                localStorage.removeItem('shrm-li-expo-attendant');
              }}
            > */}
            <LogOutContainer
              onClick={() => {
                expoHallAPI.defaults.headers.common['Authorization'] = '';
                history.push('/login');
                localStorage.removeItem('shrm-li-expo-user');
                setUser(undefined);
                setAttendant(undefined);
                localStorage.removeItem('shrm-li-expo-attendant');
              }}
            >
              <LogoutButton>Logout</LogoutButton>
            </LogOutContainer>
          </NavigationBar>
        )}
        <CustomNotification
          onButtonClick={(e) => proceedToExpohall()}
          buttonText={notificationData.buttonText}
          title={notificationData.title}
          description={notificationData.message}
          time={notificationData.time}
          open={openNotification}
          onCloseIconClick={(e) => {
            setOpenNotification(false);
          }}
        />
        <div className="video-container">
          <div className="video-overlay" />
          <ReactPlayer
            ref={videoRef}
            config={{
              file: { attributes: { controlsList: 'nodownload' } },
            }}
            // url={
            //   'https://expohallimages.s3-us-west-2.amazonaws.com/media/trinity/videos/TrinityHall_Introduction_Session_1.mp4'
            // }
            url={!!presentationVideoURL && presentationVideoURL}
            playing={videoIsPlaying}
            height="100%"
            width={'100%'}
            volume={volume / 100}
            muted={videoIsMuted}
            //controls
            onStart={() => {
              console.log(
                `%cTIME VIDEO STARTED: ${new Date(Date.now()).toUTCString()}`,
                'color: green; font-family:monospace; font-size: 20px; font-weight: bold'
              );
              //playbackRef.current = true;
              //console.log('playbackRef Changed: ', playbackRef.current)
              // setIsPlaybackTriggered(true);
            }}
            onPlay={() => {
              console.log(
                `%cTIME VIDEO PLAYED: ${new Date(Date.now()).toUTCString()}`,
                'color: green; font-family:monospace; font-size: 20px; font-weight: bold'
              );
              //playbackRef.current = true;
              //console.log('playbackRef Changed: ', playbackRef.current)
              //setIsPlaybackTriggered(true);
            }}
            onSeek={() => {
              console.log(
                `%cTIME VIDEO SEEKED: ${new Date(Date.now()).toUTCString()}`,
                'color: green; font-family:monospace; font-size: 20px; font-weight: bold'
              );
              //playbackRef.current = true;
              //console.log('playbackRef Changed: ', playbackRef.current)
            }}
            onReady={() => {
              console.log(
                `%cTIME VIDEO READY: ${new Date(Date.now()).toUTCString()}`,
                'color: green; font-family:monospace; font-size: 20px; font-weight: bold'
              );
              //playbackRef.current = true;
              //console.log('playbackRef Changed: ', playbackRef.current)
            }}
            onBuffer={() => {
              console.log(
                `%cTIME VIDEO BUFFER: ${new Date(Date.now()).toUTCString()}`,
                'color: green; font-family:monospace; font-size: 20px; font-weight: bold'
              );
              //playbackRef.current = true;
              //console.log('playbackRef Changed: ', playbackRef.current)
            }}
            onBufferEnd={() => {
              console.log(
                `%cTIME VIDEO BUFFER END: ${new Date(
                  Date.now()
                ).toUTCString()}`,
                'color: green; font-family:monospace; font-size: 20px; font-weight: bold'
              );
              //playbackRef.current = true;
              //console.log('playbackRef Changed: ', playbackRef.current)
              setIsPlaybackTriggered(true);
            }}
            onEnded={() => {
              console.log(
                `%cTIME VIDEO ENDED: ${new Date(Date.now()).toUTCString()}`,
                'color: orange; font-family:monospace; font-size: 20px; font-weight: bold'
              );
            }}
            loop={isLooped}
            //bring up issue with controls placement
            //CHANGE BACK TO FALSE
            //controls={true}
            style={{
              display: videoIsPlaying ? 'flex' : 'none',
              left: 0,
              top: 0,
              backgroundColor: 'black',
              overflow: 'hidden',
              zIndex: 0,
            }}
            //override the html video attributes using config object
            //in this case set preload to load video
            config={{
              file: {
                attributes: {
                  preload: 'auto',
                },
              },
            }}
          />
          <ReactPlayer
            /* url={auditorium?.live_event_link} */
            url={auditorium?.waiting_video}
            playing={preVideoIsPlaying}
            height="100%"
            width={'100%'}
            volume={volume / 100}
            muted={videoIsMuted}
            //controls
            onStart={() => {
              console.log(
                `%cTIME STARTED: ${new Date(Date.now()).toUTCString()}`,
                'color: green; font-family:monospace; font-size: 20px; font-weight: bold'
              );
            }}
            onEnded={() => {
              console.log(
                `%cTIME ENDED: ${new Date(Date.now()).toUTCString()}`,
                'color: orange; font-family:monospace; font-size: 20px; font-weight: bold'
              );
            }}
            loop={isLooped}
            //bring up issue with controls placement
            //CHANGE BACK TO FALSE
            //controls={true}
            style={{
              display: videoIsPlaying ? 'none' : 'flex',
              left: 0,
              top: 0,
              backgroundColor: 'black',
              overflow: 'hidden',
              zIndex: 0,
            }}
            //override the html video attributes using config object
            //in this case set preload to load video
            config={{
              file: {
                attributes: {
                  preload: 'auto',
                },
              },
            }}
          />
        </div>
        <div className="volume-container">
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item>
              <VolumeDown fontSize="large" />
            </Grid>
            <Grid item xs>
              <Slider
                value={volume}
                onChange={handleVolume}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid item>
              <VolumeUp fontSize="large" />
            </Grid>
          </Grid>
        </div>
      </LeftContainer>
      <RightContainer>
        <ChatTitle>Event Discussion</ChatTitle>
        <Smallchatbox
          messages={auditoriumChatMessages}
          participants={auditoriumChatParticipants}
          sendMessage={sendChatMessageAuditorium}
          setLoadingMoreMessages={setLoadingMoreMessages}
          loadingMoreMessages={loadingMoreMessages}
          retrieveMoreMessages={retrieveMoreMessagesAuditorium}
          deleteMessage={deleteMessageAuditorium}

          // className="tab_chat_footer"
        />
      </RightContainer>
    </Container>
  );
};

export default styled(AuditoriumPage)``;

const Container = styled.div`
  display: flex;
`;

const LeftContainer = styled.div`
  position: relative;
  flex: 0.8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .volume-container {
    width: 25%;
    align-self: flex-end;
    margin: 1rem;
  }
  .video-container {
    position: relative;
    height: 80%;
    margin-top: 8rem;
  }
  .video-overlay {
    position: absolute;
    left: 0;
    //height: 7%;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0);
    z-index: 1;
  }
`;

const TopGoBack = styled.div`
  position: absolute;
  top: 20px;
  left: 10px;
  display: flex;
  z-index: 200;
  fill: white;
  transition: all 0.2s;
  flex-direction: row;
  align-items: center;
  :hover {
    cursor: pointer;
    svg {
      transform: translateX(-5px);
      transition: all 0.2s;
    }
    transition: all 0.2s;
  }
  svg {
    height: 4rem;
    width: 4rem;
    display: block;
    margin: 0 auto;
    opacity: 1;
    stroke-width: 3px;
    stroke: white;
  }
`;

const IconContainer = styled.div`
  font-size: 2rem;
  opacity: 1;
  margin-left: 1rem;
`;
const LogOutContainer = styled.div`
  font-size: 2rem;
  opacity: 1;
  margin-left: 1rem;
  position: absolute;
  right: 1%;
  top: 5%;
`;

const RightContainer = styled.div`
  position: relative;
  flex: 0.2;
  height: 100vh;
  position: fixed;
  width: 20%;
  right: 0;
  background-color: white;
  height: 100vh;
`;

const ChatTitle = styled.h6`
  padding-top: 5px;
  text-align: center;
  background-color: ${setColor.midnightBlue};
  padding-bottom: 1rem;
`;

const NavigationBar = styled.div``;
