import React, { useContext, useState, useRef } from 'react';
import Avatar from './AvatarUser';
import styled from 'styled-components';
import { setColor, setFont, setBoxShadow } from '../../styles/styles';
import { Context as UserContext } from '../../context/UserContext';
import { Context as AttendantContext } from '../../context/AttendantContext';
import { MoreIcons } from '../../styles/icons';

function ChatMessage({ className, message, deleteMessage }) {
  const [openOptions, setOpenOptions] = useState(false);
  const {
    state,
    state: { user },
    setUser,
  } = useContext(UserContext);

  const { state: { attendant } = {}, setAttendant } = useContext(
    AttendantContext
  );

  return (
    <div className={className}>
      {message.delete ? (
        <div className="ChatDeleteMessage">This message is Deleted</div>
      ) : (
        <div className="ChatMessages">
          <Avatar
            firstname={
              message.screen_name
                ? message.screen_name.toUpperCase()
                : message.author.toUpperCase()
            }
            is_attendant={message.is_attendant}
          />
          <div className="ChatMessage">
            <p className="ChatMessage_author">
              {message.screen_name ? message.screen_name : message.author}
              <span className="ChatMessage_time">
                {message.timestamp &&
                  `${new Date(
                    message.timestamp.replace(/ /g, 'T')
                  ).getHours()}:${
                    new Date(
                      message.timestamp.replace(/ /g, 'T')
                    ).getMinutes() > 9
                      ? new Date(
                          message.timestamp.replace(/ /g, 'T')
                        ).getMinutes()
                      : '0' +
                        new Date(
                          message.timestamp.replace(/ /g, 'T')
                        ).getMinutes()
                  }`}
              </span>
            </p>
            <div className="ChatMessage_content_container">
              <p className="ChatMessage_content">{message.content}</p>

              {(message?.authorid === user?.id || attendant) && (
                <>
                  <div
                    onClick={() => setOpenOptions((prev) => !prev)}
                    className="messageOptions"
                  >
                    <MoreIcons />
                  </div>
                  <div className="ChatMessage_options_select_container">
                    {openOptions && (
                      <div className="ChatMessage_select">
                        <div
                          className="ChatMessage_select_options"
                          onClick={() => {
                            setOpenOptions((prevState) => !prevState);
                            deleteMessage(message?.id);
                          }}
                        >
                          Delete Message
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default styled(ChatMessage)`
  .ChatDeleteMessage {
    text-align: center;
    color: #bcc0c4;
    border-radius: 4px;
    font-size: 16px;
    font-style: italic;
    margin: 2px;
    display: none;
  }
  .ChatMessages {
    display: flex;
    margin-bottom: -20px;

    margin-left: -10px;
    text-align: left;
    letter-spacing: 0px;
    width: 100%;
    overflow-wrap: anywhere;

    .MuiAvatar-colorDefault {
      background-color: firebrick !important;
      font-weight: bold;
      font-size: 20px;
      border-radius: 50%;
    }
  }
  .ChatMessage {
    margin-left: 4px;
    width: 100%;
    color: black;
    border-radius: 10px;
    position: relative;
    .ChatMessage_time {
      font-size: 1rem;
      color: #949393;
      margin-left: 5px;
    }
    .ChatMessage_author {
      text-transform: capitalize;
      font-weight: bolder;
      font-size: 1.2rem;
      font-family: ${setFont.lato};
      text-transform: capitalize;
      height: unset;
    }
    .ChatMessage_content {
      font-size: 1.2rem;
      font-weight: 500;
      font-family: arial;
      padding-bottom: 30px;
    }
    .ChatMessage_content_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .messageOptions {
        position: absolute;
        right: 0;
      }
    }
    .ChatMessage_options_select_container {
      display: flex;

      .ChatMessage_select {
        z-index: 100;
        background: #fff;
        min-width: 100px;
        height: fit-content;
        color: black;
        border-radius: 2px;
        font-family: ${setFont.main};
        box-shadow: ${setBoxShadow.dark};
        position: absolute;
        top: 50%;
        right: 0;
        .ChatMessage_select_options {
          text-align: center;
          min-width: 100px;
          height: 28px;
          padding: 5px;
          cursor: pointer;
          :hover {
            background: #fff;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }
    .ChatMessage_options_container {
      height: 25px;
      width: 25px;
      text-align: center;
      cursor: pointer;
      transition: opacity 0.25s ease;

      .ChatMessage_options {
        border: none;
        margin: auto;
        margin-bottom: 2px;
        height: 3px;
        width: 3px;
        cursor: pointer;
        border-radius: 5px;
        background: #bcc0c4;
      }
    }
  }
`;
