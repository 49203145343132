import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import queryString from 'query-string';
import { TextField } from '@material-ui/core';
import SiteData from '../../config';
import styled from 'styled-components';
import { setColor, setBoxShadow, sizes } from '../../styles/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import {
  SigninIcon,
  SignupIcon,
  InformationalIcon,
  LoadingIcon,
} from '../../styles/icons';

const LoginForm = ({
  className,
  email,
  setEmail,
  password,
  setPassword,
  handleSubmit,
  alreadyExist,
  setalreadyExist,
}) => {
  const [visible, setvisible] = useState(false);
  return (
    <div className={className}>
      <div className="LoginFormInnerContainer">
        <h3>{SiteData.loginTitle}</h3>
        <div className="LoginForm_image">
          <img src={SiteData.loginImagesrc} alt={SiteData.loginImagealt} />
        </div>
        <div className="LoginForm_body">
          {alreadyExist && (
            <section>
              <div className="instruction-box">
                <div className="item">
                  <div className="item-icon">
                    <InformationalIcon />
                  </div>
                  <div className="item-text">
                    There was a problem
                    <br />
                    Your Password is incorrect
                  </div>
                </div>
              </div>
            </section>
          )}
          <form
            onSubmit={handleSubmit}
            action=""
            className="LoginForm_loginForm"
          >
            <TextField
              name="email"
              id="Filled-basic"
              type="Email"
              label="Email"
              variant="filled"
              placeholder="Your valid Email address"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <div className="passworddiv">
              <TextField
                name="password"
                id="Filled-password-input"
                label="Password"
                type={visible ? 'text' : 'password'}
                required
                value={password}
                autoComplete="current-password"
                variant="filled"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <div
                onClick={() => setvisible((prev) => !prev)}
                className="visible"
              >
                {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </div>
            </div>

            <div className="LoginForm_questions">
              <span>
                <Link to="/register"> Create an Account</Link>
              </span>
              <span style={{ marginLeft: '15px' }}>
                <Link to="/forgotpassword"> Forgot Password?</Link>
              </span>
            </div>
            <button disabled={!email || !password} type="submit">
              Proceed
            </button>
          </form>
          <section>
            <div className="instruction-box">
              <div className="item">
                <div className="item-icon">
                  <InformationalIcon />
                </div>
                <div className="support-text">
                  If you are having problems signing in, <br />
                  please contact us at spacessupport@matchboxvirtual.com
                  {/* <br />
                  or
                  <br />
                  join our{' '}
                  <a
                    className="zoomLink"
                    href={
                      'https://zoom.us/j/99858503399?pwd=OEZzb1pPbG9FdDNvRnJXSlJuaTVTQT09'
                    }
                  >
                    zoom
                  </a>{' '}
                  room to speak directly with technical support. */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default styled(LoginForm)`
  height: 100vh;
  width: 100%;
  justify-content: center;
  display: flex;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif, arial;
    text-align: center;
  }
  /*-----------BOB STYLING---------  */
  .zoomLink {
    color: ${setColor.secondaryBlue};
  }
  .LoginForm_redirect {
    text-align: center;
  }

  .LoginFormInnerContainer {
    /* align-self: center; */
    height: 100vh;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .passworddiv {
    position: relative;
    svg {
      height: 30px;
      width: 30px;
      fill: ${setColor.secondaryBlue};
    }
    .visible {
      position: absolute;
      top: 40%;
      right: 1%;
      margin-left: 10px;
    }
    input {
      padding-right: 50px;
    }
  }
  .LoginForm_image {
    display: flex;
    justify-content: center;
  }
  .LoginForm_body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
  }
  .LoginForm_questions {
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 15px;

      a {
        text-decoration: none;
        color: ${setColor.secondaryBlue};
      }
    }
  }
  .LoginForm_loginForm {
    display: flex;
    flex-direction: column;
    button {
      width: fit-content;
      margin: 10% auto;
      background: ${setColor.secondaryBlue};
      border: none;
      color: white;
      padding: 0.5rem 1.5rem;
      border-radius: 5px;
      font-size: 20px;
      cursor: pointer;
      transition: background-color ease 0.3s;
      :hover {
        background-color: ${setColor.tertiaryBlue};
        box-shadow: ${setBoxShadow.lightPressed};
      }
      :focus {
        outline: none;
        box-shadow: none;
      }
    }
    #LoginForm_forgotPassword {
      text-align: start;
    }
    .LoginForm_checkbox {
      margin-top: 10%;
      .checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label {
          margin-left: 10px;
        }
      }
    }
    /* Material-UI inline modification  */
    .MuiInputBase-root {
      margin-top: 12px;
      font-size: 20px;
      input {
        color: white;
      }
    }
    .MuiFormLabel-root {
      font-size: 20px;
      color: white;
    }
    .MuiInput-underline:before {
      border-bottom: 1px solid ${setColor.secondaryBlue};
    }
    .MuiInput-underline:after {
      border-bottom: 1px solid ${setColor.secondaryBlue};
    }
    /* Material-UI inline modification  */
  }

  .instruction-box {
    display: flex;
    flex-direction: column;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${setColor.primaryBlue};
    padding: 10px;
    border-radius: 10px;

    :not(:last-child) {
      margin-bottom: 2rem;
    }

    .item-icon {
      height: 6rem;
      width: 6rem;
      fill: ${setColor.primaryBlue};
      padding: 0.5rem;
    }

    .item-text {
      font-size: 1.6rem;
      margin-left: 1rem;
      letter-spacing: 1.2px;
    }

    .support-text {
      font-size: 1.6rem;
      margin-left: 1rem;
      letter-spacing: 1.2px;
      text-align: center;
    }
  }
  @media screen and (max-height: ${sizes.phone}px) {
    .LoginFormInnerContainer {
      justify-content: normal;
    }
  }
`;
