import styled from 'styled-components';
import { setColor } from '../../styles/styles';

export const ChatButton = styled.button`
  width: 100%;
  height: 100%;
  background-color: ${setColor.secondaryBlue};
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in;
  font-weight: bold;

  :hover {
    background-color: ${setColor.tertiaryBlue};
  }
`;

export default ChatButton;
