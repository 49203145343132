import React from 'react';
import ClientCreateTileForm from './ClientCreateExhibitorForm';

import styled from 'styled-components';
import ClientExhibitorList from './ClientExhibitorList';

const ClientDashboard = ({ className }) => {
  return (
    <div className={className}>
      <ClientCreateTileForm />
      <ClientExhibitorList />
    </div>
  );
};

export default styled(ClientDashboard)`
  margin-top: 7rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  justify-content: space-evenly;
  grid-gap: 2rem;
`;
