import React, { useState, useEffect, useContext, useRef } from 'react';

import styled from 'styled-components';

import useEffectAfterMount from '../../utils/hooks/useEffectAfterMount';

import { useHistory } from 'react-router-dom';

import { useDebouncedFn } from 'beautiful-react-hooks';

import { Context as ClientContext } from '../../context/ClientContext';

import AttendantInput from '../globals/AttendantInput';
import RegistrationButton from '../globals/RegistrationButton';

import expoHallAPI from '../../api/expoHallAPI';
import PasswordButton from '../globals/PasswordButton';

import { setColor } from '../../styles/styles';

const ClientLogin = ({ className }) => {
  const { state ,setClient } = useContext(ClientContext);

  const passwordRef = useRef();
  const submitRef = useRef();

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const [clientMatch, setClientMatch] = useState(undefined);

  useEffect(() => {
  }, []);

  useEffect(() => {
    isSubmitButtonDisabled();
  }, [email, password, clientMatch]);

  useEffectAfterMount(() => {
    if (email && password) findClient(email, password);
    if (!email) setClientMatch(undefined);
    return () => findClient.cancel();
  }, [password, email]);

  const findClient = useDebouncedFn(async (email, password) => {
    try {
      await expoHallAPI.post('/client/login/', {
        email: email.toLowerCase(),
        password,
      });
      setClientMatch(true);
      //console.log('ATTENDANT MATCH DEBOUNCED', data);
    } catch (error) {
      setClientMatch(false);
      //console.log('ATTENDANT ERROR', error);
    }
  }, 1000);

  const isSubmitButtonDisabled = () => {
    if (clientMatch) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (clientMatch) {
      try {
        const { data } = await expoHallAPI.post('/client/login/', {
          email: email.toLowerCase(),
          password,
        });
        // console.log('ATTENDANT', data.filter(attendant => email === attendant.email));
        // const attendantData = data.filter(attendant => email === attendant.email)
        if (data) {
          setClient(data);
          setClientMatch(true);
          localStorage.setItem('shrm-li-expo-client', JSON.stringify({email: email.toLowerCase(), password}));
          history.push('/client');
        }
      } catch (error) {
        setClientMatch(false);
        setClient({});
        //console.log('ATTENDANT ERROR', error);
      }
    }
  };

  return (
    <div className={className}>
      <h1>Client Login</h1>
      <form>
        <div className="formItem">
          <label htmlFor="email" className="formLabel">
            Email:
          </label>
          <AttendantInput
            type="email"
            name="email"
            id="email"
            placeholder="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            attendantMatch={clientMatch}
          />
        </div>
        <div className="formItem password">
          <label htmlFor="password" className="formLabel">
            Password:
          </label>
          <div className="password-button-group">
            <AttendantInput
              ref={passwordRef}
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="password"
              placeholder="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
                if (clientMatch) findClient(event.target.value);
              }}
              onBlur={() => {
                if (!password) setClientMatch(undefined);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  //console.log('clicked');
                  submitRef.current.click();
                }
              }}
              attendantMatch={clientMatch}
              id="password-input"
              passwordField
            />

            <PasswordButton
              onClick={(event) => {
                event.preventDefault();
                setShowPassword((prevState) => !prevState);
                passwordRef.current.focus();
                setTimeout(()=>{ 
                  passwordRef.current.value = ''; 
                  passwordRef.current.value = password;
                  });
              }}
              className="show-password-button"
            >
              {showPassword ? 'Hide' : 'Show'}
            </PasswordButton>
          </div>
        </div>
        <RegistrationButton
          onClick={handleSubmit}
          disabled={submitButtonDisabled}
          isDisabled={submitButtonDisabled}
          type="submit"
          ref={submitRef}
        >
          Submit
        </RegistrationButton>
      </form>
    </div>
  );
};

export default styled(ClientLogin)`
  height: 100%;
  color: #d1d1d1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 7rem;
  font-weight: 300;
  margin: 0 auto;

  .formLabel {
    margin-bottom: 1rem;
    color: ${setColor.primaryBlue};
  }

  label {
    font-size: 2rem;
  }

  .formItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .password-button-group {
    position: relative;
  }

  .show-password-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 1rem;
  }
`;
