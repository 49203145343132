import React, { useState, useEffect, useContext, Fragment } from 'react';

import { useHistory } from 'react-router-dom';
import useSound from 'use-sound';
import { setColor } from '../../styles/styles';

import useEffectAfterMount from '../../utils/hooks/useEffectAfterMount';
import sound1 from '../../styles/sounds/sound1.mp3';

import expoHallAPI from '../../api/expoHallAPI';

import { Context as AttendantContext } from '../../context/AttendantContext';

import styled from 'styled-components';

import AttendantDashboardTile from './AttendantDashboardTile';
import AttendantDashboardInfo from './AttendantDashboardInfo';

import ReactModal from 'react-modal';

import { DetailsButton } from '../globals/DetailsButton';

import RequestMeetingDetails from '../requestMeetingForm/RequestMeetingDetails';

import _ from 'lodash';
import {
  Socket,
  connectChat,
  disconnectChat,
  connectChatViews,
  retrieveMoreMessages,
  sendChatMessage,
  deleteChatMessage,
  sendUpdateChatParticipants,
} from '../../api/expoHallSocket';
import { Context as SocketContext } from '../../context/SocketContext';
import Smallchatbox from '../Chatbox/Smallchatbox';
import SiteData from '../../config';
import NavbarAttendant from '../globals/NavbarAttendant';
import LoadingPage from '../../pages/LoadingPage';
import getTimeLogged from '../../utils/getTimeLogged';
import Countdown from 'react-countdown';
import { CustomNotification } from '../globals/NotificationAgreement';
import { Context as UserContext } from '../../context/UserContext';
import { LivePulse } from '../../pages/SliderPage';
import { Tooltip } from '@material-ui/core';

let update_chat_participant_interval = null;

const AttendantDashboard = ({
  className,
  loadGeneralChat,
  loadTileChat,
  setAttendantNavbarBackLink,
}) => {
  const {
    state,
    state: { booth, attendant = {} } = {},
    setBooth,
    setAttendant,
  } = useContext(AttendantContext);

  const {
    state: { user },
    setUser,
  } = useContext(UserContext);
  const {
    state: {
      tileChatMessages,
      tileChatParticipants,
      generalChatMessages,
      generalChatParticipants,
      viewsNumber,
      loadingMoreMessages,
      connection,
      EventisLive,
    },
    setTileChatMessages,
    setTileChatParticipants,
    setGeneralChatMessages,
    setGeneralChatParticipants,
    setViewsNumber,
    setLoadingMoreMessages,
    setEventisLive,
  } = useContext(SocketContext);

  const {
    first_name = '',
    email = '',
    password = '',
    booth: {
      id = '',
      name = '',
      //videocall_host_url = '',
      image = '',
    } = {},
  } = attendant;

  const history = useHistory();
  const [play] = useSound(sound1);
  const [droppedCards, setDroppedCards] = useState([]);
  const [requestedMeetings, setRequestedMeetings] = useState([]);
  // const [booth, setBooth] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadBooth, setLoadBooth] = useState(false);
  const [processed, setProcessed] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});

  const {
    chat_url,
    videocall_host_url,
    chat_toggle,
    business_card_toggle,
    video_call_toggle,
    meeting_toggle,
  } = booth;

  // console.log('ATTENDANT CHAT', chat_url);

  const handleUpdateChatParticipants = (booth) => {
    update_chat_participant_interval = setInterval(() => {
      sendUpdateChatParticipants(booth.group_id.id);
    }, 60000);
  };

  const playSound = () => {
    play();
  };
  useEffect(() => {
    fetchUser();

    //console.log('CHAT URL', chat_url)
  }, []);

  const handleMeetingCompleted = async (data) => {
    try {
      const response = await expoHallAPI.put(`/api/meetings/${data.id}/`, {
        processed: !data.processed,
      });
      handleProcessed(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (booth.group_id && attendant) {
      //handleConnectTileChat(booth);
      //handleConnectGeneralChat();
      //handleUpdateChatParticipants(booth);
    }
    return () => {
      handleDisconnectGeneralChat();
      handleDisconnectTileChat();
      // clearInterval(update_chat_participant_interval);
      // update_chat_participant_interval = null;
    };
  }, [booth]);
  useEffect(() => {
    if (attendant.id) {
      getBooth();
      getNotification();
      // fetchMeetings();
      // fetchDroppedCards();
    }
  }, [attendant]);

  useEffect(() => {
    if (booth.group_id && attendant && connection) {
      handleConnectTileChat(booth);
      handleConnectGeneralChat();
      // handleUpdateChatParticipants(booth);
    }
  }, [connection, attendant, booth]);

  const getBooth = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-attendant');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get(`/api/tiles/${attendant.id}/`);
      setDroppedCards(data.dropped_cards);
      setRequestedMeetings(data.meeting);
      setViewsNumber(data.views);
      setBooth(data);
      setLoadBooth(true);
    } catch (error) {}
  };

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-attendant');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendant/profile/');
      // console.log(data);
      if (data) {
        setAttendant(data);
      }
    } catch (error) {
      expoHallAPI.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('shrm-li-expo-attendant');
      history.push('/login');
      setAttendant(undefined);
    }
  };

  const handleProcessed = (data) => {
    const index = requestedMeetings.findIndex((x) => x.id === data.id);
    let array = requestedMeetings;
    array[index].processed = !data.processed;
    setProcessed(array[index].processed);
    setRequestedMeetings(array);
  };

  const handleDisconnectGeneralChat = () => {
    disconnectChat(attendant, SiteData.generalChatId);
  };

  const handleDisconnectTileChat = () => {
    if (booth.group_id) {
      disconnectChat(attendant, booth.group_id.id);
    }
  };

  const handleConnectTileChat = (tile) => {
    connectChatViews(attendant, tile.group_id.id, tile.id, 'show');
  };

  const handleConnectGeneralChat = () => {
    connectChat(attendant, SiteData.generalChatId);
  };

  const retrieveMoreMessagesGeneralChat = (currentPage) => {
    retrieveMoreMessages(SiteData.generalChatId, currentPage);
    setLoadingMoreMessages(true);
  };

  const retrieveMoreMessagesTileChat = (currentPage) => {
    retrieveMoreMessages(booth.group_id.id, currentPage);
    setLoadingMoreMessages(true);
  };

  const sendGeneralChatMessage = (message) => {
    sendChatMessage(attendant, SiteData.generalChatId, message);
  };

  const sendTileChatMessage = (message) => {
    sendChatMessage(attendant, booth.group_id.id, message);
  };

  const deleteMessageGeneralChat = (message) => {
    deleteChatMessage(SiteData.generalChatId, message);
  };

  const deleteMessageTileChat = (message) => {
    deleteChatMessage(booth.group_id.id, message);
  };

  const getNotification = async () => {
    try {
      const { data } = await expoHallAPI.get('/api/notifications/expo/');
      data.map((eachData) => {
        timeTriggerActions(eachData);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const timeTriggerNow = (data) => {
    const now = new Date();
    const TimeNow = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
    const EventTime = parseFloat(data.milliseconds);
    //time left for event
    let TimeLeftForEvent = EventTime - TimeNow;
    if (TimeLeftForEvent < 0) {
      TimeLeftForEvent += 86400000; // adds 1 day (runs everyday)
    }

    const eventDay = new Date(EventTime).getUTCDate();
    if (now.getUTCDate() === eventDay) {
      setTimeout(() => {
        auditoriumActions(data);
      }, TimeLeftForEvent);
    }
  };

  const timeTriggerPeriod = (data) => {
    const now = new Date();
    const EventTime = new Date(data.time);
    const EventEndTime = new Date(data.end_time);
    if (
      new Date(now.toUTCString()) > new Date(EventTime.toUTCString()) &&
      new Date(now.toUTCString()) < new Date(EventEndTime.toUTCString())
    ) {
      auditoriumActions(data);
    }
  };

  const timeTriggerActions = (data) => {
    switch (data.notif_type) {
      case 'NOW':
        timeTriggerNow(data);
        break;
      case 'PERIOD':
        timeTriggerPeriod(data);
        break;
      default:
      // code block
    }
  };

  const auditoriumActions = (data) => {
    const now = new Date(new Date().toUTCString());
    const EventTime = new Date(new Date(data.time).toUTCString());
    const differenceAfter = now.getTime() - EventTime.getTime();
    const differenceBefore = EventTime.getTime() - now.getTime();
    const minutes = Math.ceil(differenceBefore / 60000);

    getTimeLogged(data.id.toString());

    switch (data.id.toString()) {
      case '1':
        // first action to take take.
        /**
         * when usern logs in in this period. they are moved to the expoHall.
         * countDown Begins
         */
        data.time = <Countdown date={Date.now() + differenceBefore} />;
        data.buttonText = '';
        setNotificationData(data);
        setOpenNotification(true);
        break;
      case '2':
        /**
         * NOW event. Users are moved to expoHall
         */
        proceedToAuditorium();
        break;

      case '6':
        // when Live event is on going with the join to go back to the expohall
        // While live event is going on if you return to the expor hall.
        setNotificationData(data);
        setOpenNotification(true);
        data.buttonText = '';
        data.time = '';
        setTimeout(() => {
          setOpenNotification(false);
        }, 5000);

        setEventisLive(true);
        setTimeout(() => {
          setEventisLive(false);
        }, differenceBefore);

        break;

      // case '8':
      //   // Auditorium has ended. Set live pulse to false
      //   setEventisLive(false);
      //   break;
      case '8':
        /**
         *It displays the Countdown for the next webinar session
         * */
        data.time = <Countdown date={Date.now() + differenceBefore} />;
        data.buttonText = '';
        setNotificationData(data);
        setOpenNotification(true);
        break;
      case '9':
        // pop up notification
        // When to display the next agenda. and the action to do

        setOpenNotification(true);
        data.buttonText = 'Join Here';
        data.time = '';
        setNotificationData(data);
        setTimeout(() => {
          setOpenNotification(false);
        }, 50000);
        break;

      default:
      // code block
    }
  };

  const proceedToAuditorium = () => {
    history.push('/auditorium');
  };

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 900,
    },
    content: {
      height: '75%',
      width: '60%',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      borderRadius: 0,
      borderWidth: 0,
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      backgroundColor: 'white',
      paddingLeft: 40,
      paddingRight: 80,
      backfaceVisibility: 'hidden',
      boxShadow: '0px 0px 48px 0px rgba(0,0,0,0.4)',
      overflow: 'hidden',
      zIndex: 1000,
    },
  };
  if (!loadBooth || !loadGeneralChat || !loadTileChat) {
    return <LoadingPage />;
  } else {
    return (
      <div className={className}>
        <CustomNotification
          title={notificationData.title}
          description={notificationData.message}
          time={notificationData.time}
          open={openNotification}
          onCloseIconClick={(e) => {
            setOpenNotification(false);
          }}
        />
        <ReactModal
          isOpen={modalIsOpen}
          style={customStyles}
          onRequestClose={() => setModalIsOpen(false)}
          ariaHideApp={false}
          className="ReactModal__customContent"
        >
          <RequestMeetingDetails
            handleProcessed={handleProcessed}
            data={modalIsOpen}
            closeModal={() => setModalIsOpen(false)}
          />
        </ReactModal>
        {EventisLive && (
          <Tooltip
            title={
              <h6
                style={{
                  color: 'white',
                  display: 'flex',
                  paddingTop: '0.5rem',
                }}
              >
                Auditorium is Live
              </h6>
            }
          >
            <LivePulse
              onClick={() => {
                proceedToAuditorium();
              }}
            />
          </Tooltip>
        )}
        <header>
          <h2>{name}</h2>
        </header>
        {video_call_toggle && (
          <AttendantDashboardInfo
            boothInfo={{
              id: booth.id,
              name,
              closed: booth.closed,
              videocallHostUrl: videocall_host_url,
              firstName: first_name,
              password,
            }}
          />
        )}

        <TileContainer>
          <AttendantDashboardTile title="event discussion" subtitle={'  '}>
            {/*<iframe*/}
            {/*src={'https://rumbletalk.com/client/chat.php?UIY8xXJR'}*/}
            {/*title="chat"*/}
            {/*></iframe>*/}
            <Smallchatbox
              AttendantDashboard={true}
              messages={generalChatMessages}
              user={attendant}
              participants={generalChatParticipants}
              sendMessage={sendGeneralChatMessage}
              setLoadingMoreMessages={setLoadingMoreMessages}
              loadingMoreMessages={loadingMoreMessages}
              retrieveMoreMessages={retrieveMoreMessagesGeneralChat}
              deleteMessage={deleteMessageGeneralChat}
            />
            {/*<object*/}
            {/*data="https://rumbletalk.com/client/chat.php?UIY8xXJR"*/}
            {/*aria-label="chat"*/}
            {/*/>*/}
          </AttendantDashboardTile>
          {chat_toggle && (
            <AttendantDashboardTile
              title="booth discussion"
              subtitle={'Views: ' + viewsNumber}
            >
              {/*<iframe src={chat_url} title="chat"></iframe>*/}
              <Smallchatbox
                AttendantDashboard={true}
                messages={tileChatMessages}
                user={attendant}
                participants={tileChatParticipants}
                sendMessage={sendTileChatMessage}
                setLoadingMoreMessages={setLoadingMoreMessages}
                loadingMoreMessages={loadingMoreMessages}
                retrieveMoreMessages={retrieveMoreMessagesTileChat}
                deleteMessage={deleteMessageTileChat}
              />

              {/*<object data={chat_url} aria-label="chat" />*/}
            </AttendantDashboardTile>
          )}
          {business_card_toggle && (
            <AttendantDashboardTile title="dropped cards">
              <div className="table">
                <div className="row">
                  <div className="emailDropCards row-data">Email</div>
                  <div className="first-nameDropCards row-data">First Name</div>
                  <div className="last-nameDropCards row-data">Last Name</div>
                </div>

                <div className="data-columns">
                  {droppedCards.map((card) => (
                    <div className="row" key={card.email}>
                      <div className="emailDropCards row-data">
                        {card.email}
                      </div>
                      <div className="first-nameDropCards row-data">
                        {card.first_name}
                      </div>
                      <div className="last-nameDropCards row-data">
                        {card.last_name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </AttendantDashboardTile>
          )}
          {meeting_toggle && (
            <AttendantDashboardTile title="requested meetings">
              <div className="table">
                <div className="row">
                  <div className="emailMeetings row-data">Email@email.com</div>
                  <div className="first-nameMeetings row-data">First Name</div>
                  <div className="last-nameMeetings row-data">Last Name</div>
                  <div className="detailsMeetings row-data"></div>
                </div>
                <div className="data-columns">
                  {requestedMeetings.map((card, index) => {
                    return (
                      <div
                        className={card.processed ? 'rowCompleted' : 'row'}
                        key={index}
                      >
                        <div className="emailMeetings row-data">
                          {card.email}
                        </div>
                        <div className="first-nameMeetings row-data">
                          {card.first_name}
                        </div>
                        <div className="last-nameMeetings row-data">
                          {card.last_name}
                        </div>
                        <div className="detailsMeetings row-data details_button">
                          <DetailsButton onClick={() => setModalIsOpen(card)}>
                            Details
                          </DetailsButton>
                          <div className="meeting_checkbok">
                            <input
                              onClick={() => {
                                handleMeetingCompleted(card);
                              }}
                              checked={card.processed ? true : false}
                              name="meeting_complete"
                              id="meeting_complete"
                              type="checkbox"
                            />
                            <label htmlFor="meeting_complete"></label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </AttendantDashboardTile>
          )}
        </TileContainer>
      </div>
    );
  }
};

const TileContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  justify-content: space-evenly;
  grid-gap: 2rem;
  margin-top: 2rem;
`;

export default styled(AttendantDashboard)`
  margin-top: 7rem;
  position: relative;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .data-columns {
    overflow-y: auto;
    scrollbar-color: grey ${setColor.mainGrey};
    height: 100%;

    /* scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    } */
  }
  .ReactModal {
    padding: 10px;
  }
  .table {
    height: 100%;
  }

  .row-data {
    padding: 0.2rem 0.4rem;
  }

  .row {
    display: flex;
    flex-direction: row;
    transition: 0.2s;

    :not(:last-child) {
      border-bottom: 1px solid rgba(100, 100, 100, 0.2);
    }

    :hover {
      transform: scale(1.01);
      transition: 0.2s;
    }
  }
  #meeting_complete {
    margin-left: 10px !important;
  }
  .details_button {
    display: flex;
    align-items: center;
  }
  .rowCompleted {
    display: flex;
    flex-direction: row;
    transition: 0.2s;

    :not(:last-child) {
      border-bottom: 1px solid rgba(100, 100, 100, 0.2);
    }
    .emailMeetings,
    .first-nameMeetings,
    .last-nameMeetings {
      text-decoration: line-through;
    }
    :hover {
      transform: scale(1.01);
      transition: 0.2s;
    }
  }

  .emailDropCards {
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
  }

  .first-nameDropCards {
    width: 25%;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
  }

  .last-nameDropCards {
    width: 25%;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
  }

  .emailMeetings {
    width: 40%;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
  }

  .first-nameMeetings {
    width: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
  }

  .last-nameMeetings {
    width: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
  }

  .detailsMeetings {
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
    margin-left: -30px;
  }

  h1 {
    text-align: center;
  }

  object {
    height: 100%;
    width: 100%;
  }
`;
