import React, { useContext, useState } from 'react';

import { useLocation, useHistory, Link, NavLink } from 'react-router-dom';

import styled from 'styled-components';
import { setColor, sizes } from '../../styles/styles';

import LogoutButton from './LogoutButton';

import { Context as AttendantContext } from '../../context/AttendantContext';

//config js file to check if the event has an auditorium
import SiteData from '../../config';

import { BackArrow } from '../../styles/icons';

import { useWindowScroll } from 'beautiful-react-hooks';
import { Context as UserContext } from '../../context/UserContext';
import expoHallAPI from '../../api/expoHallAPI';

const NavDiv = styled.div`
  height: ${(props) => (props.scrollY ? '5rem' : '7rem')};
  transition: height 0.2s;
`;

const NavbarAttendant = ({ className, attendantNavbarBackLink }) => {
  const { state: { user = {} } = {}, setUser } = useContext(UserContext);
  const {
    state: { attendant: { first_name = '', email = '' } = {} },
    setAttendant,
  } = useContext(AttendantContext);

  // console.log(first_name, email)
  const history = useHistory();

  const [scrollY, setScrollY] = useState(0);

  //console.log('BACK LINK', attendantNavbarBackLink)

  useWindowScroll(() => setScrollY(window.scrollY));

  return (
    <NavDiv className={className} scrollY={scrollY}>
      <NavLink to={'/'} className="back-button">
        <BackArrow />
        <div className="caption">Go To Event</div>
      </NavLink>
      {/* <div className="title">SHRM-LI Attendant Console</div> */}
      <div className="title">{SiteData.attendantTitle}</div>
      <div className="greeting">
        <nav>
          <NavLink to="/dashboard">Dashboard</NavLink>
          <NavLink to="/editor">Tile Editor</NavLink>
          {/*<NavLink to="/tabs">Tab Manager</NavLink>*/}
        </nav>
        {/* Welcome{attendant?.first_name && `, ${attendant?.first_name}`}! */}
        <div className="greeting-box">
          <div className="greeting-name">Welcome, {first_name}!</div>
          <div className="greeting-email">{email}</div>
        </div>
        <LogoutButton
          onClick={() => {
            expoHallAPI.defaults.headers.common['Authorization'] = '';
            history.push('/login');
            localStorage.removeItem('shrm-li-expo-attendant');
            localStorage.removeItem('shrm-li-expo-user');
            setAttendant(undefined);
            setUser(undefined);
          }}
        >
          Logout
        </LogoutButton>
      </div>
    </NavDiv>
  );
};

export default styled(NavbarAttendant)`
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  padding: 2rem;
  align-items: center;

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  .title {
    font-size: 2rem;
    /* position: absolute; */
    /* text-align: center; */
    /* left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    width: 70%; */

    @media screen and (max-width: ${sizes.desktop}px) {
      visibility: hidden;
    }
  }

  nav {
    a {
      text-decoration: none;
      font-family: inherit;
      font-size: 2rem;
      color: white;
      transition: all 0.2s;

      border-right: 2px solid rgba(100, 100, 100, 0.3);
      padding: 0.5rem 1rem;

      :last-child {
        margin-right: 1rem;
      }

      :hover {
        color: ${setColor.primaryBlue};
        filter: brightness(110%);
        transition: all 0.2s;
      }
    }

    .active {
      color: ${setColor.primaryBlue};
    }
  }

  .greeting {
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .greeting-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .greeting-name {
  }

  .greeting-email {
    font-size: 1.2rem;
    font-weight: 300;
  }

  .title {
    font-size: 2rem;
    /* position: absolute; */
    /* text-align: center; */
    /* left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    width: 70%; */

    @media only screen and (max-width: ${sizes.desktop}px) {
      visibility: hidden;
    }
  }

  .caption {
    font-size: 2rem;
    opacity: 1;
    margin-left: 1rem;
  }

  svg {
    height: 4rem;
    width: 4rem;
    display: block;
    margin: 0 auto;
    opacity: 1;
    stroke-width: 3px;
    stroke: white;
  }

  .back-button {
    fill: white;
    transition: all 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 30;
    text-decoration: none;
    color: white;

    :hover {
      cursor: pointer;
      svg {
        transform: translateX(-5px);
        transition: all 0.2s;
      }
      transition: all 0.2s;
    }
  }
`;
