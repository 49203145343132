import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { TextField } from '@material-ui/core';
import SiteData from '../../config';
import styled from 'styled-components';
import { setColor, setBoxShadow } from '../../styles/styles';

import { InformationalIcon } from '../../styles/icons';

import { Context as UserContext } from '../../context/UserContext';
import { Context as AttendantContext } from '../../context/AttendantContext';
import expoHallAPI from '../../api/expoHallAPI';

const ForgotPassword = ({ className }) => {
  const location = useLocation();
  const history = useHistory();

  const {
    state: { user },
    setUser,
  } = useContext(UserContext);
  const {
    state: { attendant },
    setAttendant,
  } = useContext(AttendantContext);

  const [email, setEmail] = useState('');
  const [alreadyExist, setalreadyExist] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    // setEmail(queryString.parse(location.search).email);
    if (location.state) {
      if (location.state.email) {
        setEmail(location.state.email);
      }
    }
  }, []);

  useEffect(() => {
    authenticateUser();
  }, []);

  const authenticateUser = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-user');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendee/profile/');
      if (data) {
        history.push('/');
      }
    } catch (error) {
      expoHallAPI.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('shrm-li-expo-user');
      setUser(undefined);
      authenticateAttendant();
    }
  };

  const authenticateAttendant = async () => {
    try {
      const token = localStorage.getItem('shrm-li-expo-attendant');
      expoHallAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const { data } = await expoHallAPI.get('/attendant/profile/');
      // console.log(data);
      if (data) {
        history.push('/dashboard');
      }
    } catch (error) {
      expoHallAPI.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('shrm-li-expo-attendant');
      setAttendant(undefined);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await expoHallAPI.post('/password_reset/', {
        email: email.toLowerCase(),
        schema: SiteData.schemaName,
      });
      setEmailSent(true);
      setalreadyExist(false);

      console.log(data);
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 403) {
          setEmailSent(false);
          setalreadyExist(email.toLowerCase());
        }
      }
    }
  };
  return (
    <div className={className}>
      <div className="LoginFormInnerContainer">
        <h3>Forgot Password</h3>
        <div className="LoginForm_image">
          <img src={SiteData.loginImagesrc} alt={SiteData.loginImagealt} />
        </div>
        <div className="LoginForm_body">
          {alreadyExist && (
            <section>
              <div className="instruction-box">
                <div className="item">
                  <div className="item-icon">
                    <InformationalIcon />
                  </div>
                  <div className="item-text">
                    There was a problem
                    <br />
                    The user with email {alreadyExist} does not exist.
                  </div>
                </div>
              </div>
            </section>
          )}
          {emailSent && (
            <section>
              <div className="instruction-box">
                <div className="item">
                  <div className="item-icon">
                    <InformationalIcon />
                  </div>
                  <div className="item-text">
                    A message with reset password instructions has been sent to
                    your inbox.
                  </div>
                </div>
              </div>
            </section>
          )}
          <form
            onSubmit={handleSubmit}
            action=""
            className="LoginForm_loginForm"
          >
            <TextField
              name="email"
              id="Filled-basic"
              type="Email"
              label="Email"
              variant="filled"
              placeholder="Your valid Email address"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleSubmit(event);
                }
              }}
            />
            <div className="LoginForm_questions">
              <span>
                <Link to="/register"> Create an Account</Link>
              </span>
              <span>
                <Link to="/login"> Sign In </Link>
              </span>
            </div>
            <button disabled={!email} type="submit">
              Reset
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default styled(ForgotPassword)`
  height: 80vh;
  width: 100%;
  justify-content: center;
  display: flex;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif, arial;
    text-align: center;
  }
  /*-----------BOB STYLING---------  */
  .zoomLink {
    color: ${setColor.secondaryBlue};
  }
  .LoginForm_redirect {
    text-align: center;
  }

  .LoginFormInnerContainer {
    /* align-self: center; */
    height: 100vh;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .LoginForm_image {
    display: flex;
    justify-content: center;
  }
  .LoginForm_body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
  }
  .LoginForm_questions {
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 15px;

      a {
        text-decoration: none;
        color: ${setColor.secondaryBlue};
      }
    }
  }
  .LoginForm_loginForm {
    display: flex;
    flex-direction: column;
    button {
      width: fit-content;
      margin: 10% auto;
      background: ${setColor.secondaryBlue};
      border: none;
      color: white;
      padding: 0.5rem 1.5rem;
      border-radius: 5px;
      font-size: 20px;
      cursor: pointer;
      transition: background-color ease 0.3s;
      :hover {
        background-color: ${setColor.tertiaryBlue};
        box-shadow: ${setBoxShadow.lightPressed};
      }
      :focus {
        outline: none;
        box-shadow: none;
      }
    }
    #LoginForm_forgotPassword {
      text-align: start;
    }
    .LoginForm_checkbox {
      margin-top: 10%;
      .checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label {
          margin-left: 10px;
        }
      }
    }
    /* Material-UI inline modification  */
    .MuiInputBase-root {
      margin-top: 12px;
      font-size: 20px;
      input {
        color: white;
      }
    }
    .MuiFormLabel-root {
      font-size: 20px;
      color: white;
    }
    .MuiInput-underline:before {
      border-bottom: 1px solid ${setColor.secondaryBlue};
    }
    .MuiInput-underline:after {
      border-bottom: 1px solid ${setColor.secondaryBlue};
    }
    /* Material-UI inline modification  */
  }

  .instruction-box {
    display: flex;
    flex-direction: column;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${setColor.primaryBlue};
    padding: 10px;
    border-radius: 10px;

    :not(:last-child) {
      margin-bottom: 2rem;
    }

    .item-icon {
      height: 6rem;
      width: 6rem;
      fill: ${setColor.primaryBlue};
      padding: 0.5rem;
    }

    .item-text {
      font-size: 1.6rem;
      margin-left: 1rem;
      letter-spacing: 1.2px;
    }

    .support-text {
      font-size: 1.6rem;
      margin-left: 1rem;
      letter-spacing: 1.2px;
      text-align: center;
    }
  }
`;
