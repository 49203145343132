import React from 'react';
import styled from 'styled-components';
import { setColor, setFont, setBoxShadow } from '../../styles/styles';

const Rooms = ({ className }) => {
  return (
    <div className={className}>
      <div className="Rooms">
        <h6>rooms </h6>{' '}
      </div>
    </div>
  );
};

export default styled(Rooms)`
  flex: 1;
  color: white;
  height: 100%;
  background: ${setColor.ghostWhite};
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  line-height: 0;

  .Rooms {
    border-bottom: 0.2px solid ${setColor.midnightBlue};
    h6 {
      color: ${setColor.midnightBlue};
      font-family: monospace;
    }
  }
`;
