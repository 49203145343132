import React, { useContext } from 'react';
import styled from 'styled-components';
import { CloseIcon, LoadingIcon } from '../../styles/icons';
import Chatbox from '../Chatbox/Chatbox'
import { Context as SocketContext } from '../../context/SocketContext';
import {sendChatMessage} from '../../api/expoHallSocket';
import { Context as UserContext } from '../../context/UserContext';
import SiteData from '../../config'


const GeneralChat = ({ className,closeModal }) => {
  const { state: {tileChatMessages , tileChatParticipants } } = useContext(SocketContext);
  const { state: { user } = {}, setUser } = useContext(UserContext);

  const sendChatMessageGeneral = (message)=>{
    sendChatMessage(user,SiteData.generalChatId,message);
  };
  return (
    <div className={className}>
      <div className="closeIcon" onClick={closeModal}>
        <CloseIcon />
      </div>
      <ChatDiv>
        {/*<iframe src={"https://rumbletalk.com/client/chat.php?UIY8xXJR"} title="chat"></iframe>*/}
        <Chatbox messages={tileChatMessages} user={user} participants={tileChatParticipants} sendMessage={sendChatMessageGeneral}/>
      </ChatDiv>
    </div>
  );
};

export default styled(GeneralChat)`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  .closeIcon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;
  }

`;

const ChatDiv = styled.div`
  width: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;

  object {
    height: 100%;
    width: 100%;
    z-index: 200;
    color: white;
  }
`;
