import React  from 'react';
import styled from 'styled-components';
import {setColor} from '../../styles/styles';
import expoHallAPI from '../../api/expoHallAPI';


const TabDeleteForm = ({ className, tab, deleteTab }) => {

  const handleDeleteTab= async()=>{

    try{
     await expoHallAPI.delete(`/api/tabs/${tab.id}/`);
      deleteTab(tab)
    } catch(error){
      console.log('ERROR', error);
    }
  };
  return (
    <div className={className}>
      <h6>Are you sure you want to delete this tab?</h6>
      <button
        onClick={handleDeleteTab}
        className="request_register"
      >
        Delete
      </button>

    </div>
  );
};

export default styled(TabDeleteForm)`
  h6{
    text-align: center;
    color: ${setColor.mainGrey};
    margin-top: 3rem;
  }

  .request_register {
    background: ${setColor.secondaryDanger};
    width: fit-content;
    margin-right: 5%;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 3px;
    transition: all ease 0.3s;
    color: white;
    float: right;
    cursor: pointer;
  }

`;
