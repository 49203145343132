import React, { useContext } from 'react';
import Smallchatbox from '../components/Chatbox/Smallchatbox';
import { Context as UserContext } from '../context/UserContext';
import {sendChatMessage,retrieveMoreMessages,deleteChatMessage} from '../api/expoHallSocket';
import ExhibitorContext from '../context/exhibitorContext';
import styled from 'styled-components';
import { Context as AttendantContext } from '../context/AttendantContext';
import { Context as SocketContext } from '../context/SocketContext';
import SiteData from '../config';

const SliderPageRightPane = ({ className }) => {
  const { state, state: { user } = {}, setUser } = useContext(UserContext);
  const { state: { attendant = undefined } = {}, setAttendant } = useContext(
    AttendantContext
  );
  const {
    state: {
      tileChatMessages,
      tileChatParticipants,
      generalChatMessages,
      generalChatParticipants,
      loadingMoreMessages,
    },
    setTileChatMessages,
    setTileChatParticipants,
    setGeneralChatMessages,
    setGeneralChatParticipants,
    setLoadingMoreMessages,
  } = useContext(SocketContext);

  const sendGeneralChatMessage = (message) => {
    sendChatMessage(user,SiteData.generalChatId,message);
  };

  const retrieveMoreMessagesGeneral = (currentPage) => {
    retrieveMoreMessages(SiteData.generalChatId,currentPage)
    setLoadingMoreMessages(true);
  };

  const deleteMessage =(message) =>{
    deleteChatMessage(SiteData.generalChatId,message)
  };

  return (
    <div className={className}>
      {/* {console.log(object)} */}
      <Smallchatbox
        AttendantDashboard={false}
        messages={generalChatMessages}
        participants={generalChatParticipants}
        sendMessage={sendGeneralChatMessage}
        setLoadingMoreMessages={setLoadingMoreMessages}
        loadingMoreMessages={loadingMoreMessages}
        retrieveMoreMessages={retrieveMoreMessagesGeneral}
        deleteMessage={deleteMessage}
      />
    </div>
  );
};

export default styled(SliderPageRightPane)`
  height: 100%;
  color: black;
`;
