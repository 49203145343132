import React, { useState, useEffect, useRef, useContext} from 'react';
import { Context as AttendantContext } from '../../context/AttendantContext';
import expoHallAPI from '../../api/expoHallAPI';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { setColor } from '../../styles/styles';

import Button from '../globals/Button';
import UploaderButton from '../globals/UploaderButton';

import FormInput from '../globals/FormInput';
import { NotificationFactory } from '../globals/NotificationMessage';

const TileEditorForm = ({ className }) => {
  const { state, setAttendant } = useContext(AttendantContext);
  const [description, setDescription] = useState('');
  const [tabType, setTabType] = useState('PDF');
  const [tabName, setTabName] = useState('');
  const [priority, setPriority] = useState('');
  const [fileUploadChecker, setFileUploadChecker] = useState(false);
  const [blurb, setBlurb] = useState('');
  const [url, setURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const history = useHistory();

  const inputRef = useRef();


  const handleSubmit = async (event,ref) => {
    event.preventDefault();
    setLoading(true);
    let notification = {
      title: "Success",
      message: "Tab Created",
      type: 'success',
      insert: 'top',
      container: 'top-right',
      duration: 2000
    };
    try{
      await expoHallAPI.post(`/api/tabs/`, {
        tab_type: tabType,
        name: tabName,
        booth: 1,
        sub_header: description,
        url: url,
        priority,
      });
    } catch(error){

      console.log('ERROR', error);

    } finally {
      setLoading(false);
      NotificationFactory(notification)
    }

    try{
      const data = new FormData();
      if (ref.current.files[0]) {
        data.append('logo', ref.current.files[0]);
        // console.log('uploading');
        await expoHallAPI.put(`/api/tabs/${user.booth}`, data)
      }
    }catch(error){
      console.log('ERROR', error);
    }
  };
  const onFileChange = event =>{
    if(event.target.files[0]){
      setFileUploadChecker(true)
    }else{
      setFileUploadChecker(false)
    }
  };
  // console.log(!inputRef?.current?.files[0]?.name)

  return (
    <div className={className}>
      <h4>Create a Tab</h4>
      <form>
          <div className="form-input-group">
            <label htmlFor="tab-type" className="form-label">
              Select tab type (pdf or website)
              <span className="required">&nbsp;required</span>
            </label>
            <select
              id="tab-type"
              name="tab-type"
              value={tabType}
              onChange={(event) => setTabType(event.target.value)}
            >
              <option value="PDF">PDF</option>
              <option value="">Website</option>
            </select>
          </div>
          <div className="form-input-group">
            <label for="tab-name" className="form-label">
              Tab Name
              <span className="required">&nbsp;required</span>
            </label>
            <FormInput
              type="text"
              id="tab-name"
              placeholder="name"
              value={tabName}
              onChange={(event) => setTabName(event.target.value)}
            />
          </div>
          <div className="form-input-group">
            <label for="description" className="form-label">
              Sub Header
            </label>
            <textarea
              rows="2"
              cols="20"
              id="description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <div className="form-input-group">
            <label for="priority" className="form-label">
              Tab priority (order of appearance)
              <span className="required">&nbsp;required</span>
            </label>
            <FormInput
              type="number"
              id="priority"
              placeholder="pick a number"
              value={priority}
              onChange={(event) => {
                setPriority(Math.max(event.target.value, 1));
              }}
            />
          </div>
          
          {tabType === 'URL' ? (
            <div className="form-input-group">
              <label for="url" className="form-label">
                Website URL
                <span className="required">&nbsp;required</span>
              </label>
              <FormInput
                type="url"
                pattern="http://.*"
                id="url"
                placeholder="url"
                value={url}
                onChange={(event) => setURL(event.target.value)}
              />
            </div>
          ):
            <div className="form-input-group">
              <label htmlFor="data" className="form-label">
                Upload {tabType === 'PDF' ? 'PDF' : 'Picture'}
                <span className="required">&nbsp;required</span>
              </label>
              <input
                type="file"
                ref={inputRef}
                id="data"
                accept={tabType === 'PDF' ? 'application/pdf' : 'image/*'}
                onChange={onFileChange}
              />
            </div>
          }

          <Button
            type="submit"
            disabled={(tabType === 'URL' && !url)|| !tabType || !priority || !tabName || (tabType !== 'URL' && !fileUploadChecker)}
            onClick={(e)=>handleSubmit(e,inputRef)}
          >
            Submit
          </Button>
      </form>
    </div>
  );
};

export default styled(TileEditorForm)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  fieldset {
    outline: none;
    border: none;

    :disabled {
      input,
      textarea {
        border-color: transparent;
        color: rgba(100, 100, 100, 0.2);
      }
    }
  }

  .form-input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .form-label {
    margin: 1rem 0;
    color: ${setColor.primaryBlue};
    font-size: 1.6rem;
  }

  .required {
    font-style: italic;
    font-size: 1.4rem;
    color: rgb(186, 57, 45);
  }

  textarea {
    background-color: inherit;
    color: inherit;
    font-family: inherit;
    padding: 1rem 2rem;
    border-radius: 2rem;
    border: 2px solid;
    outline: none;
    border-color: grey;

    :placeholder-shown {
      border-color: grey;
    }

    :focus {
      border-color: ${setColor.primaryBlue};
    }
  }

  #tab-type {
    font-family: inherit;
    outline: none;
    border: none;
    background-color: inherit;
    font-size: 1.4rem;
    border-radius: 5px;
    color: white;

    option {
      background-color: ${setColor.mainGrey};
    }
  }
`;
