import styled, { css } from 'styled-components'

import { setColor } from '../../styles/styles'

const attendantMixin = css`
	border-color: ${props =>
		props.attendantMatch === true
			? 'green'
			: props.attendantMatch === false
			? 'red'
			: `${setColor.primaryBlue}`};
`
const borderColorMixin = css`
	${props =>
		props.passwordField
			? attendantMixin
			: `border-color: ${setColor.primaryBlue};`}
`

const AttendantInput = styled.input`
	background-color: inherit;
	font-family: inherit;
	outline: none;
	color: ${setColor.primaryBlue};
	border: 2px solid;
	${borderColorMixin}
	border-radius: 10rem;
	padding: 1rem 2rem;
	font-size: 2rem;
    position: relative;
	width: 30rem;

	::placeholder {
		color: grey;
		font-style: italic;
		font-weight: 300;
		opacity: .5;
	}

	:placeholder-shown {
		border-color: grey;
	}

	:focus {
        ${borderColorMixin}
	}

`

export default AttendantInput
